import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MainLayout from "../components/MainLayout";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CompletePayment() {
  const navigate = useNavigate();
  const [temperary_id, settemperary_id] = useState(null);
  const handleChange = (e) => {
    settemperary_id(e.target.value);
  };
  const handlePreview = async () => {
    if (temperary_id) {
      navigate("/preview", {
        state: temperary_id,
      });
    } else {
      alert("Please Enter Parcial Temparory Id");
    }
  };
  return (
    <MainLayout>
      <section className="applynowformwrapper pt-0 pb-2">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              <div className="bg-light p-4 rounded-3 mb-3 mt-4 border">
                <Row>
                  <Col md={12} className="border-bottom">
                    <h4 className="mt-2">Complete Payment of Partially Filled Form</h4>
                  </Col>
                  <Col md={12}>
                    <Form.Control
                      type="text"
                      value={temperary_id}
                      name="tran_id"
                      onChange={handleChange}
                      size="sm"
                      className="mb-3"
                      placeholder="Application ID"
                    />

                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={handlePreview}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}
