import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function History() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Vietnam: Overview and History</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								Today, Vietnam is home to over{" "}
								<a
									href="javascript:void(0)"
									target="_blank"
									rel="noopener noreferrer"
								>
									98 million people
								</a>
								, and the country welcomes over 18 million tourists each year.
								Vietnam offers stunning national parks, several impressive
								UNESCO World Heritage Sites, and megacities that regularly rank
								as some of the best tourism destinations in Asia. Vietnam should
								be on every traveler’s bucket list – the country’s delicious
								cuisine, beautiful natural landscapes, and rich history will not
								disappoint.
							</p>
							<h2>Key points in Vietnam’s history</h2>
							<p>
								A key part of Vietnamese history is the country’s long{" "}
								<a
									href="https://www.britannica.com/place/Vietnam/The-conquest-of-Vietnam-by-France#ref52737"
									target="_blank"
									rel="noopener noreferrer"
								>
									period under French colonial rule
								</a>
								. The French conquest of Vietnam began in 1858, and Vietnam
								officially became part of French Indochina in 1887. It was not
								until 1954 when local forces led by Ho Chi Minh defeated the
								French. Tourists will notice significant French influences on
								Vietnamese cuisine and architecture.
							</p>
							<p>
								Many people consider modern Vietnamese history as beginning in
								1973 when the{" "}
								<a
									href="https://www.history.com/this-day-in-history/u-s-withdraws-from-vietnam"
									target="_blank"
									rel="noopener noreferrer"
								>
									United States withdrew from Vietnam
								</a>{" "}
								and signed a cease-fire agreement. After the US ended the
								country’s Vietnam War, North Vietnam overtook South Vietnam and
								joined the country under Northern Vietnam’s communist
								leadership. Since{" "}
								<a
									href="https://www.worldbank.org/en/country/vietnam/overview"
									target="_blank"
									rel="noopener noreferrer"
								>
									widespread reforms in 1986
								</a>
								, Vietnam has embraced economic liberalization and privatized
								business, and the government has worked to protect human rights.
							</p>
							<p>
								In recent years, US heads of state have visited Vietnam,
								including President Bill Clinton’s visit in 2000 and President
								Barack Obama’s visit in 2016. Vietnam is now part of the{" "}
								<a href="javascript:void(0)">
									ASEAN intergovernmental organization
								</a>
								, and the country’s economy and tourism industry are growing
								rapidly. Vietnam is part of the “
								<a
									href="javascript:void(0)"
									target="_blank"
									rel="noopener noreferrer"
								>
									Next Eleven
								</a>
								” states, a group of countries that will likely be the 21st
								century’s largest economies.
							</p>
							<h2>Highlights for Vietnam tourism</h2>
							<p>
								When traveling to Vietnam, we recommend that you plan your trip
								well in advance. There is plenty to see and experience during a
								trip to Vietnam, and a well thought out itinerary will help you
								make the most of your trip. Also, be sure to take a look at
								typical weather patterns during the dates of your trip, and know
								that Vietnam has a variety of different climate types. You can
								learn more about how weather patterns vary across Vietnam in our
								article on the{" "}
								<a href="javascript:void(0)">
									best time to visit Vietnam by region
								</a>
								. Another useful article is our{" "}
								<a href="javascript:void(0)">
									Ultimate Guide to Vietnam Vacation Costs
								</a>
								. In Vietnam, backpackers can have an excellent trip for around
								$40 to $50 per day, and travelers looking for luxury vacations
								have a variety of options.
							</p>
							<p>
								Popular tourism destinations in Vietnam include the country’s
								beautiful beaches, particularly those in the Gulf of Thailand.
								If exploring Southeast Asia’s beaches is for you, check out our{" "}
								<a href="javascript:void(0)">
									Ultimate Guide to Vietnam Beaches
								</a>
								. Another popular tourist destination is{" "}
								<a href="javascript:void(0)">Halong Bay</a>. It is a UNESCO
								World Heritage Site, and tourists can find the perfect photo
								opportunity while on a boat among the stunning limestone islets.
								Be sure to check out Vietnam’s bustling cities, including{" "}
								<a href="javascript:void(0)">Ho Chi Minh City</a> and{" "}
								<a href="javascript:void(0)">Hanoi</a>. You will feel energized
								by the city’s activity, and you can find a busy coffee shop for
								a delicious glass of Vietnamese iced coffee at almost every
								corner.
							</p>
							<p>
								Of course, the above recommendations barely scratch the surface
								of what Vietnam has to offer. To learn more about popular travel
								locations in Vietnam, check out our collection of{" "}
								<a href="javascript:void(0)">Vietnam travel guides</a>. We also
								have an extensive{" "}
								<a href="javascript:void(0)">travel tip library</a> with
								articles on{" "}
								<a href="javascript:void(0)">etiquette in Vietnam</a> and on{" "}
								<a href="javascript:void(0)">
									what to pack for your trip to Vietnam
								</a>
								.
							</p>
							<h2>How to get a visa for Vietnam </h2>
							<p>
								To enter Vietnam, tourists from most countries will need to
								acquire a Vietnam visa. Vietnam does have a visa-on-arrival
								program. However, to obtain a visa on arrival, tourists should{" "}
								<a href="javascript:void(0)">apply for a Vietnam visa online</a>
								. Applying for a visa on arrival before traveling ensures that
								you can take care of any issues before your trip. Additionally,
								having all your paperwork in order before your trip speeds up
								the immigration process upon your arrival.
							</p>
							<p>
								Applying for your visa on arrival is easy. The first step is to
								fill out our{" "}
								<a href="javascript:void(0)">
									online application for a Vietnam visa
								</a>
								. After you complete your application, we will process your form
								and send you an official approval letter. This process typically
								takes two business days, and visa fees vary depending on each
								traveler’s passport. Next, you will need to print out your
								approval letter and take it with you on your trip. Additionally,
								you will need to bring two passport photos (4cm x 6cm).
							</p>
							<p>
								When you arrive in Vietnam, you will complete an entry form and
								present your approval letter and passport to the immigration
								officials. After paying an additional stamping fee, you will
								receive your visa stamp, and you will then be able to start your
								vacation in Vietnam! Know that you must fly into an airport that
								participates in Vietnam’s visa-on-arrival program. Participating
								airports include those in <a href="javascript:void(0)">Hanoi</a>
								, <a href="javascript:void(0)">Da Nang</a>,{" "}
								<a href="javascript:void(0)">Ho Chi Minh City</a>, and{" "}
								<a href="javascript:void(0)">Nha Trang</a>.
							</p>
							<p>
								If you have any questions about applying for a Vietnam visa, do
								not hesitate to{" "}
								<a href="javascript:void(0)">reach out to our team</a>. You can
								reach us by phone at (+84) 832 320 320 or email us at
								support@vietnamvisa.govt.gn. We are happy to help, and our goal
								is to make the Vietnam visa application process as easy as
								possible.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default History;
