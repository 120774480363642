/* eslint-disable no-lone-blocks */
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Row,
  Form,
  Table,
  Accordion,
  Button,
} from "react-bootstrap";
import { uploadFile, userService } from "../service";
import { useLocation, useNavigate } from "react-router-dom";
import { IoTrashSharp } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MainLayout from "../components/MainLayout";
import ScrollToTop from "../components/ScrollToTop";
import { nodeUrl } from "../config";
import Loader from "../components/Loader";
import axios from "axios";
import moment from "moment";

export default function ApplyOnline() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentDay = new Date().toISOString().split("T")[0];
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [requiredData, setRequiredData] = useState({
    visa_type: "",
    visa_priority: "",
    profile_image: "",
    passport_image: "",
    surname: "",
    given_name: "",
    date_of_birth: "",
    gender: "",
    nationality: "",
    religion: "",
    email: "",
    grant_evisa_valid_from: null,
    confirm_email: "",
    grant_evisa_valid_to: null,
    passport_type: "",
    passport_number: "",
    passport_issuing_authority: "",
    passport_issue_date: null,
    passport_expiry_date: null,
    purpose_of_visit: "",
    length_of_stay: "",
    date_of_entry: null,
    entry_through: "",
    exit_through: "",
    temporary_city: "",
    multiple_nationality: "No",
  });
  const [formData, setFormData] = useState({
    tran_id: 0,
    exit_through: "",
    // temporary_id: Math.floor(Math.random() * 100000 + 1),
    temporary_id: "0",
    //radio
    multiple_nationality: "No",
    multiple_nationality_details: "",
    used_other_passport: "",
    voilation_of_laws: "",
    entry_type: "Single entry",
    //
    date_limit: "Full date,month and year",
    place_of_birth: "",
    id_card_number: "",
    hold_other_passport: "No",
    other_passport_type: "",
    other_passport_number: "",
    other_passport_issuing_authority: "",
    other_passport_issue_date: "",
    other_passport_expiry_date: "",
    permanent_address: "",
    contact_address: "",
    emergency_full_name: "",
    current_residence_address: "",
    telephone_number: "",
    relationship: "",
    occupation: "",
    company_name: "",
    position: "",
    company_address: "",
    company_telephone: "",
    hosting_organisation: "",
    organisation_address: "",
    organisation_telephone: "",
    hosting_purpose: "",
    temporary_address: "",
    temporary_phone: "",
    visit_in_one_year: "",
    relatives_in_vietnam: "",
    intended_expenses: "",
    trip_expenses: "",
    trip_expenses_type: "",
    health_insurance: "",
    trip_hosting_telephone: "",
    trip_hosting_organisation: "",
    health_insurance_details: "",
    ip_address: "",
    visa_type: "",
    visa_priority: "",
    //array
    relativeDetails: [{}, {}, {}],
    otherPasswords: [{}, {}, {}],
    childDetail: [{}, {}],
    violationDetails: [{}, {}, {}],
    vietnamVisitDetails: [{}, {}, {}],
  });
  const [Country, setCountry] = useState([]);
  const [showTable, setShowTable] = useState({});
  const handleNoNumberKey = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\d/.test(keyValue)) {
      e.preventDefault();
    }
  };
  const handleNoAlphaKey = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/[a-zA-Z]/.test(keyValue)) {
      e.preventDefault();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setShowTable((prevShowTable) => ({
      ...prevShowTable,
      [name]: value === "Yes",
    }));
    setRequiredData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleChangeReligion = (event) => {
    const { name, value } = event.target;

    // Only allow alphabetic characters
    const newValue = value.replace(/[^a-zA-Z]/g, "");

    setShowTable((prevShowTable) => ({
      ...prevShowTable,
      [name]: newValue === "Yes",
    }));

    setRequiredData((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleChangeEmail = (event) => {
    const { name, value } = event.target;
    setShowTable((prevShowTable) => ({
      ...prevShowTable,
      [name]: value === "Yes",
    }));

    setRequiredData((prev) => ({
      ...prev,
      [name]: value.toLowerCase(),
    }));

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // if (name === "email" || "confirm_email") {
    //   const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     email: isValidEmail ? "" : "Invalid Gmail address",
    //     confirm_email: isValidEmail ? "" : "Invalid Gmail address",
    //   }));
    // } else {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     [name]: "",
    //   }));
    // }

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [name]: isValidEmail ? '' : 'Please enter a valid email address',
    // }));
    if (name === "confirm_email") {
      if (value !== requiredData.email) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirm_email: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirm_email: "Email not matched",
        }));
      }
    } else {
    }

    // Validate confirm_email only if email has already been entered
    if (name === "email") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValidEmail ? "" : "Please enter a valid email address",
      }));
    }
    // } else {
    //   setErrors((prevErrors) => ({ ...prevErrors, confirm_email: '' })); // Clear error if email is not yet entered
    // }
  };
  const updatedPasswords = [...formData.otherPasswords];

  const handleDeleteRow = (index) => {
    updatedPasswords[index] = {
      passport_no: "",
      full_name: "",
      date_of_birth: null,
      nationality: "",
    };
    setFormData({ ...formData, otherPasswords: updatedPasswords });
  };
  //delete violation

  const handleDeleteVioRow = (index) => {
    const updatedDetails = [...formData.violationDetails];
    updatedDetails[index] = {
      act_of_violation: "",
      date_of_violation: null,
      form_of_sanction: "",
      authority: "",
    };
    setFormData({ ...formData, violationDetails: updatedDetails });
  };

  //delete relative details

  const handleDeleteRelativeRow = (index) => {
    const updatedDetails = [...formData.relativeDetails];
    updatedDetails[index] = {
      full_name: "",
      date_of_birth: null,
      nationality: "",
      relationship: "",
      address: "",
    };
    setFormData({ ...formData, relativeDetails: updatedDetails });
  };

  //delete visit detail

  const handleDeleteVisitRow = (index) => {
    const updatedDetails = [...formData.vietnamVisitDetails];
    updatedDetails[index] = {
      from_date: null,
      to_date: null,
      purpose: "",
    };
    setFormData({ ...formData, vietnamVisitDetails: updatedDetails });
  };

  function parseDate(dateString) {
    const parsedDate = new Date(dateString);
    return isNaN(parsedDate) ? null : parsedDate;
  }

  function formatDateBirth(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const validateForm = () => {
    let error = {};
    let isValid = true;

    for (const key in requiredData) {
      if (String(requiredData[key]).trim() === "") {
        error[key] = "This field is required";
        isValid = false;
      }
    }

    setErrors(error);

    return isValid;
  };

  const firstEmptyFieldRef = useRef(null);

  const handleSubmit = async () => {
    if (validateForm() && requiredData.email === requiredData.confirm_email) {
      try {
        const obj = { ...formData, ...requiredData };
        const result = await userService.post(
          "applicationForm/VN_Application_Main_Insert",
          obj
        );
        if (result.data.valid) {
          navigate("/preview", {
            state:
              formData.tran_id === 0
                ? result.data.temporary_id
                : formData.temporary_id,
          });
        }
      } catch (err) {
        console.error(err);
      } finally {
        console.log("finally");
      }
    } else{
      alert('Please fill required fields')
    }
  };

  const submitApplyNowFormHandler = (e) => {
    e.preventDefault();
  };

  const countrylisttwo = async () => {
    try {
      await userService.get("applicationForm/VN_list_country").then((data) => {
        if (data.data.valid) {
          setCountry(data.data.data);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    countrylisttwo();
  }, []);

  const convertCountryCodeToName = (code) => {
    const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    return regionNames.of(code);
  };

  const getIpData = async () => {
    const res = await axios.get("https://api.country.is/?format=json");
    // const country = convertCountryCodeToName(res.data.country);
    setFormData({
      ...formData,
      ip_address: `${res.data.ip} - ${convertCountryCodeToName(res.data.country)}`,
    });
  };

  const uploadFiles = async (event) => {
    setShowLoader(true);
    try {
      if (event.target.files.length > 0) {
        const uploadData = await uploadFile(
          event.target.name,
          event.target.files[0]
        );
        if (uploadData.response.status === 200) {
          // Set the file path in requiredData
          setRequiredData({
            ...requiredData,
            [event.target.name]: uploadData.response.data.path,
          });

          // Clear the error message for the field
          setErrors((prevErrors) => ({
            ...prevErrors,
            [event.target.name]: "",
          }));
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setShowLoader(false);
    }
  };

  const uploadFilesChild = async (event) => {
    setShowLoader(true);
    try {
      if (event.target.files.length > 0) {
        const uploadData = await uploadFile(
          event.target.name,
          event.target.files[0]
        );
        if (uploadData.response.status === 200) {
          const array = [...formData.childDetail];
          const detail = array.length > 0 ? { ...array[0] } : {};
          detail.image = uploadData.response.data.path;
          array.splice(0, 1, detail);
          setFormData({
            ...formData,
            childDetail: array,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
    setShowLoader(false);
  };
  const uploadFilesChild2 = async (event) => {
    setShowLoader(true);
    try {
      if (event.target.files.length > 0) {
        const uploadData = await uploadFile(
          event.target.name,
          event.target.files[0]
        );
        if (uploadData.response.status === 200) {
          const array = [...formData.childDetail];
          const detail = array.length > 1 ? { ...array[1] } : {};
          detail.image = uploadData.response.data.path;
          array.splice(1, 1, detail);
          setFormData({
            ...formData,
            childDetail: array,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
    setShowLoader(false);
  };

  // useEffect(() => {
  //   getIpData();
  // }, []);

  const getPreviewApplication = async () => {
    try {
      const result = await userService.post(
        "applicationForm/VN_Application_full_preview",
        { temporary_id: location?.state }
      );

      let value = result.data.data;
      let tempData = { ...formData };
      for (let key in formData) {
        if (value.hasOwnProperty(key)) {
          tempData[key] = value[key];
        }
      }
      setFormData(tempData);
      setShowTable(tempData);
      let value2 = result.data.data;
      let tempData2 = { ...requiredData };
      for (let key in requiredData) {
        if (value2.hasOwnProperty(key)) {
          tempData2[key] = value2[key];
        }
      }

      setRequiredData(tempData2);
    } catch (err) {
      console.error(err);
    }
  };
  // const getPreviewApplication2 = async () => {
  //   try {
  //     const result = await userService.post(
  //       "applicationForm/VN_Application_full_preview",
  //       { tran_id: location?.state }
  //     );

  //     let value = result.data.data;
  //     let tempData = { ...formData };
  //     for (let key in formData) {
  //       if (value.hasOwnProperty(key)) {
  //         tempData[key] = value[key];
  //       }
  //     }
  //     setFormData(tempData);
  //     setShowTable(tempData);
  //     let value2 = result.data.data;
  //     let tempData2 = { ...requiredData };
  //     for (let key in requiredData) {
  //       if (value2.hasOwnProperty(key)) {
  //         tempData2[key] = value2[key];
  //       }
  //     }

  //     setRequiredData(tempData2);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  useEffect(() => {
    if (location?.state) {
      getPreviewApplication();
    } else {
      getIpData();
    }
  }, []);
  const handleDateChange = (date) => {
    if (date !== requiredData.passport_expiry_date) {
      setRequiredData({
        ...requiredData,
        passport_expiry_date: date,
      });
    }
  };
  return (
    <MainLayout>
      <ScrollToTop />
      <Loader visible={showLoader} />
      {/* <BannerLayout>Online Vietnamese Visa Application</BannerLayout> */}
      <section className="applynowformwrapper pt-0 pb-2">
        <Container>
          <Row>
            <Col md={12}>
              <Form onSubmit={submitApplyNowFormHandler}>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3">
                  <Row>
                    <Col md={12} className="border-bottom">
                      <h4 className="mt-2">
                        Online Vietnamese Visa Application
                      </h4>
                    </Col>
                    {/* <Col md={12}>
                      <h5>Foreigner's images</h5>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col md={4} className="mb-3">
                      <Form.Label className="mt-2">
                        Profile image <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="file-upload-container">
                        <label
                          htmlFor="profile-upload"
                          className="profile-upload-box  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative"
                        >
                          {!requiredData.profile_image ? (
                            <img
                              src={require("../assets/img/profile.jpeg")}
                              alt=""
                            />
                          ) : (
                            <img
                              src={nodeUrl + requiredData.profile_image}
                              style={{
                                width: "100%",
                                height: 200,
                                objectFit: "cover",
                              }}
                              alt=""
                            />
                          )}
                        </label>
                        <Form.Control
                          type="file"
                          id="profile-upload"
                          accept="image/*"
                          onChange={uploadFiles}
                          name="profile_image"
                        />

                        {errors?.profile_image && (
                          <p className="errorText">{errors?.profile_image}</p>
                        )}
                      </div>
                    </Col>
                    {""}
                    <Col md={4} className="mb-3">
                      <Form.Label className="mt-2">
                        Passport Image <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="file-upload-container">
                        <label
                          htmlFor="passport-upload"
                          className="profile-upload-box rounded-2 d-flex align-items-center position-relative w-100"
                          style={{
                            height: "200px",
                            overflow: "auto",
                          }}
                        >
                          {!requiredData.passport_image ? (
                            <img
                              src={require("../assets/img/passport.jpg")}
                              alt=""
                              className="border rounded-2"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                            />
                          ) : (
                            <img
                              src={nodeUrl + requiredData.passport_image}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain",
                              }}
                              alt=""
                            />
                          )}
                        </label>
                        <Form.Control
                          type="file"
                          id="passport-upload"
                          accept="image/*"
                          onChange={uploadFiles}
                          name="passport_image"
                        />
                        {errors?.passport_image && (
                          <p className="errorText">{errors?.passport_image}</p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-3 mb-3"> */}
                  <Row className="mt-5">
                    <Col xs={6}>
                      <Row>
                        <Col xs={4}>
                          <Form.Label className="mt-2 fw-bold">
                            Visa Type <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={requiredData.visa_type}
                            name="visa_type"
                            onChange={handleChange}
                          >
                            <option value="">-- Select type --</option>
                            <option value="Business">Business</option>
                            <option value="Tourist">Tourist</option>
                          </Form.Select>
                          {errors?.visa_type && (
                            <p className="errorText">{errors?.visa_type}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        <Col xs={4}>
                          <Form.Label className="mt-2 fw-bold">
                            Visa Priority <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={requiredData.visa_priority}
                            name="visa_priority"
                            onChange={handleChange}
                          >
                            <option value="">-- Select priority --</option>
                            <option value="Normal">Normal</option>
                            <option value="Urgent">Urgent</option>
                          </Form.Select>
                          {errors?.visa_priority && (
                            <p className="errorText">{errors?.visa_priority}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <h5>Personal Information</h5>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Surname <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={requiredData.surname}
                            name="surname"
                            onChange={handleChange}
                            onKeyDown={handleNoNumberKey}
                            size="sm"
                          />
                          {errors?.surname && (
                            <p className="errorText">{errors?.surname}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Given name <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={requiredData.given_name}
                            name="given_name"
                            onChange={handleChange}
                            onKeyDown={handleNoNumberKey}
                            size="sm"
                          />
                          {errors?.given_name && (
                            <p className="errorText">{errors?.given_name}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Date of birth <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <DatePicker
                            value={
                              requiredData?.date_of_birth
                                ? moment(requiredData?.date_of_birth).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""
                            }
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="input-field w-100 form-control form-control-sm"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "date_of_birth",
                                  value: moment(date).format("MM-DD-YYYY"),
                                },
                              })
                            }
                            maxDate={new Date()}
                          />

                          {errors?.date_of_birth && (
                            <p className="errorText">{errors?.date_of_birth}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="Full date,month and year"
                            name="date_limit"
                            id="FULL_DATE_MONTH_AND_YEAR"
                            checked={
                              formData.date_limit === "Full date,month and year"
                            }
                            onChange={handleChange}
                            value={"Full date,month and year"}
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="Only year is known"
                            name="date_limit"
                            id="ONLY_YEAR_IS_KNOWN"
                            onChange={handleChange}
                            value={"Only year is known"}
                            checked={
                              formData.date_limit === "Only year is known"
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Place of birth</Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={formData.place_of_birth}
                            name="place_of_birth"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Sex <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Row>
                            <Col xs={6}>
                              <Form.Check
                                type="radio"
                                label="Male"
                                name="gender"
                                id="MALE"
                                onChange={handleChange}
                                value={"Male"}
                                checked={requiredData.gender === "Male"}
                              />
                            </Col>
                            <Col xs={6}>
                              <Form.Check
                                type="radio"
                                label="Female"
                                name="gender"
                                id="FEMALE"
                                onChange={handleChange}
                                value={"Female"}
                                checked={requiredData.gender === "Female"}
                              />
                            </Col>
                          </Row>
                          {errors?.gender && (
                            <p className="errorText">{errors?.gender}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2 inputdiv star">
                      <Row>
                        <Col xs={4}>
                          <Form.Label
                            className="input-label"
                            htmlFor="nationality"
                          >
                            Country of Nationality{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <select
                            className="input-field reset form-control form-control-sm"
                            onChange={handleChange}
                            name="nationality"
                            id="nationality"
                            value={requiredData.nationality}
                          >
                            <option key={0} defaultValue="selected" value="">
                              --Country of Nationality--
                            </option>
                            {Country.map((item) => {
                              return (
                                <option
                                  key={item.country_code}
                                  value={item.country_name}
                                >
                                  {item.country_name}
                                </option>
                              );
                            })}
                          </select>
                          {errors?.nationality && (
                            <p className="errorText">{errors?.nationality}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Religion <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={requiredData.religion}
                            name="religion"
                            onChange={handleChangeReligion}
                            size="sm"
                          />
                          {errors?.religion && (
                            <p className="errorText">{errors?.religion}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>ID Card number</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.id_card_number}
                            name="id_card_number"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Email <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="email"
                            value={requiredData.email || ""}
                            name="email"
                            onChange={handleChangeEmail}
                            size="sm"
                            required
                          />
                          {errors?.email && (
                            <p className="errorText">{errors?.email}</p>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={4}>
                          <Form.Label>
                            Re-enter email{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="email"
                            value={requiredData.confirm_email}
                            name="confirm_email"
                            onChange={handleChangeEmail}
                            size="sm"
                          />
                          {requiredData.email !== requiredData.confirm_email && (
                            <p className="errorText">Email not matched</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 mb-3"> */}
                  <Row>
                    <Col md={6} className="mb-2">
                      <Form.Label className="fst-italic text-primary">
                        Have you ever used any other passports to enter into
                        VietNam?
                      </Form.Label>
                      <Row>
                        <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="No"
                            checked={!showTable.used_other_passport}
                            name="used_other_passport"
                            id="EVER_ENTERED_NO"
                            value="No"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            checked={showTable.used_other_passport}
                            name="used_other_passport"
                            id="EVER_ENTERED_YES"
                            value="Yes"
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                    {showTable.used_other_passport && (
                      <Col md={12}>
                        <Table borderless bordered striped>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Passport No</th>
                              <th>Full name</th>
                              <th>Date of birth</th>
                              <th>Nationality</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.otherPasswords.map((detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    size="sm"
                                    value={detail.passport_no || ""}
                                    onChange={(e) => {
                                      const array = [
                                        ...formData.otherPasswords,
                                      ];
                                      array[index] = {
                                        ...array[index],
                                        passport_no: e.target.value,
                                      };
                                      setFormData({
                                        ...formData,
                                        otherPasswords: array,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    size="sm"
                                    value={detail.full_name || ""}
                                    type="text"
                                    onChange={(e) => {
                                      const array = [
                                        ...formData.otherPasswords,
                                      ];
                                      array[index] = {
                                        ...array[index],
                                        full_name: e.target.value,
                                      };
                                      setFormData({
                                        ...formData,
                                        otherPasswords: array,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    value={detail.date_of_birth}
                                    onChange={(date) => {
                                      const updatedDetails = [
                                        ...formData.otherPasswords,
                                      ];
                                      updatedDetails[index].date_of_birth = date
                                        ? formatDateBirth(date, "dd/mm/yyyy")
                                        : "";
                                      setFormData({
                                        ...formData,
                                        otherPasswords: updatedDetails,
                                      });
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="input-field w-100 form-control form-control-sm"
                                    dateFormat="dd-MM-yyyy"
                                    maxDate={new Date()}
                                    name="date_of_birth"
                                  />
                                </td>
                                <td>
                                  <Form.Select
                                    size="sm"
                                    name="nationality"
                                    value={detail.nationality || ""}
                                    onChange={(e) => {
                                      const array = [
                                        ...formData.otherPasswords,
                                      ];
                                      array[index] = {
                                        ...array[index],
                                        nationality: e.target.value,
                                      };
                                      setFormData({
                                        ...formData,
                                        otherPasswords: array,
                                      });
                                    }}
                                  >
                                    <option value="">Option</option>
                                    {Country.map((item) => {
                                      return (
                                        <option
                                          key={item.country_code}
                                          value={item.country_name}
                                        >
                                          {item.country_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </td>
                                <td>
                                  <IoTrashSharp
                                    color="red"
                                    onClick={() => handleDeleteRow(index)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Label className="fst-italic text-primary">
                        Do you have multiple nationalities?
                      </Form.Label>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="No"
                            value="No"
                            checked={requiredData.multiple_nationality === "No"}
                            name="multiple_nationality"
                            onChange={handleChange}
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            id="multiple_nationality"
                            name="multiple_nationality"
                            onChange={handleChange}
                            value="Yes"
                            checked={
                              requiredData.multiple_nationality === "Yes"
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    {showTable.multiple_nationality && (
                      <Col md={6}>
                        <Form.Control
                          type="text"
                          size="sm"
                          name="multiple_nationality_details"
                          value={formData.multiple_nationality_details}
                          onChange={handleChange}
                        />
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label className="fst-italic text-primary">
                        Violation of the Vietnamese laws/regulations (if any)
                      </Form.Label>
                      <Row>
                        <Col xs={6}>
                          <Form.Check
                            onChange={handleChange}
                            value={"No"}
                            type="radio"
                            label="No"
                            checked={!showTable.voilation_of_laws}
                            name="voilation_of_laws"
                            id="VIOLATION_VIET_LAW_NO"
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Check
                            onChange={handleChange}
                            checked={showTable.voilation_of_laws}
                            value={"Yes"}
                            type="radio"
                            label="Yes"
                            name="voilation_of_laws"
                            id="VIOLATION_VIET_LAW_YES"
                          />
                        </Col>
                      </Row>
                    </Col>
                    {showTable.voilation_of_laws && (
                      <Col md={12} className="mt-3">
                        <Table borderless bordered striped>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Act of violation</th>
                              <th>Time of violation</th>
                              <th>Form of sanction</th>
                              <th>Authority imposed sanction</th>
                              <th>Remove</th>
                            </tr>
                          </thead>

                          <tbody>
                            {formData.violationDetails.map((detail, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    size="sm"
                                    value={detail.act_of_violation || ""}
                                    onChange={(e) => {
                                      const array = [
                                        ...formData.violationDetails,
                                      ];
                                      array[index] = {
                                        ...array[index],
                                        act_of_violation: e.target.value,
                                      };
                                      setFormData({
                                        ...formData,
                                        violationDetails: array,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <DatePicker
                                    selected={
                                      detail.date_of_violation
                                        ? new Date(detail.date_of_violation)
                                        : null
                                    }
                                    onChange={(date) => {
                                      const updatedDetails = [
                                        ...formData.violationDetails,
                                      ];
                                      updatedDetails[index].date_of_violation =
                                        date
                                          ? formatDateBirth(date, "dd/MM/yyyy")
                                          : "";
                                      setFormData({
                                        ...formData,
                                        violationDetails: updatedDetails,
                                      });
                                    }}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    className="input-field w-100 form-control form-control-sm"
                                    dateFormat="dd-MM-yyyy"
                                    maxDate={new Date()}
                                    name="date_of_violation"
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    size="sm"
                                    value={detail.form_of_sanction || ""}
                                    onChange={(e) => {
                                      const array = [
                                        ...formData.violationDetails,
                                      ];
                                      array[index] = {
                                        ...array[index],
                                        form_of_sanction: e.target.value,
                                      };
                                      setFormData({
                                        ...formData,
                                        violationDetails: array,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    type="text"
                                    size="sm"
                                    value={detail.authority || ""}
                                    onChange={(e) => {
                                      const array = [
                                        ...formData.violationDetails,
                                      ];
                                      array[index] = {
                                        ...array[index],
                                        authority: e.target.value,
                                      };
                                      setFormData({
                                        ...formData,
                                        violationDetails: array,
                                      });
                                    }}
                                  />
                                </td>
                                <td>
                                  <IoTrashSharp
                                    color="red"
                                    onClick={() => handleDeleteVioRow(index)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    )}
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3"> */}
                  <Row>
                    <Col md={12}>
                      <h5>Requested Information</h5>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Label className="fst-italic text-primary">
                        e-Visa request information
                      </Form.Label>
                      <Row>
                        <Col xs={6}>
                          <Form.Check
                            onChange={handleChange}
                            value="Single entry"
                            type="radio"
                            label="Single - entry"
                            name="entry_type"
                            checked={formData.entry_type === "Single entry"}
                            id="REQUEST_INFORMATION_SINGLE"
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Check
                            onChange={handleChange}
                            value="Multiple entry"
                            type="radio"
                            label="Multiple - entry"
                            name="entry_type"
                            id="REQUEST_INFORMATION_MULTIPLE"
                            checked={formData.entry_type === "Multiple entry"}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Grant Evisa valid from{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            selected={
                              requiredData?.grant_evisa_valid_from
                                ? new Date(requiredData?.grant_evisa_valid_from)
                                : null
                            }
                            maxDate={new Date()}
                            className="input-field form-control form-control-sm"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "grant_evisa_valid_from",
                                  value: date
                                    ? moment(date).format("MM-DD-YYYY")
                                    : "",
                                },
                              })
                            }
                            value={
                              requiredData?.grant_evisa_valid_from
                                ? moment(
                                    requiredData?.grant_evisa_valid_from
                                  ).format("DD-MM-YYYY")
                                : ""
                            }
                          />
                          {errors?.grant_evisa_valid_from && (
                            <p className="errorText">
                              {errors?.grant_evisa_valid_from}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            To
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            minDate={new Date()}
                            selected={
                              requiredData.grant_evisa_valid_to
                                ? new Date(requiredData.grant_evisa_valid_to)
                                : null
                            }
                            className="input-field form-control form-control-sm"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "grant_evisa_valid_to",
                                  value: date
                                    ? moment(date).format("MM-DD-YYYY")
                                    : "",
                                },
                              })
                            }
                            value={
                              requiredData?.grant_evisa_valid_to
                                ? moment(
                                    requiredData?.grant_evisa_valid_to
                                  ).format("DD-MM-YYYY")
                                : ""
                            }
                          />
                          {errors?.grant_evisa_valid_to && (
                            <p className="errorText">
                              {errors?.grant_evisa_valid_to}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3"> */}
                  <Row>
                    <Col md={12}>
                      <h5>Passport Information</h5>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Type <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            name="passport_type"
                            onChange={handleChange}
                            value={requiredData.passport_type}
                          >
                            <option value="">-- Choose passport type --</option>
                            <option value="Diplomatice Passport">
                              Diplomatice Passport
                            </option>
                            <option value="Official Passport">
                              Official Passport
                            </option>
                            <option value="Ordinary Passport">
                              Ordinary Passport
                            </option>
                            <option value="Others">Others</option>
                          </Form.Select>
                          {errors?.passport_type && (
                            <p className="errorText">{errors?.passport_type}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Passport number{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={requiredData.passport_number}
                            name="passport_number"
                            onChange={handleChange}
                            size="sm"
                          />
                          {errors?.passport_number && (
                            <p className="errorText">
                              {errors?.passport_number}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Issuing Authority/Place of issue{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={requiredData.passport_issuing_authority}
                            name="passport_issuing_authority"
                            onChange={handleChange}
                            size="sm"
                          />
                          {errors?.passport_issuing_authority && (
                            <p className="errorText">
                              {errors?.passport_issuing_authority}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Date of issue
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            selected={
                              requiredData?.passport_issue_date
                                ? new Date(requiredData.passport_issue_date)
                                : null
                            }
                            maxDate={new Date()}
                            className="input-field form-control form-control-sm"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "passport_issue_date",
                                  value: date
                                    ? moment(date).format("MM-DD-YYYY")
                                    : "",
                                },
                              })
                            }
                            value={
                              requiredData?.passport_issue_date
                                ? moment(
                                    requiredData?.passport_issue_date
                                  ).format("DD-MM-YYYY")
                                : ""
                            }
                          />
                          {errors?.passport_issue_date && (
                            <p className="errorText">
                              {errors?.passport_issue_date}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {/*bug */}

                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Expiry date
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            minDate={new Date()}
                            // selected={requiredData?.passport_expiry_date}x
                            onChange={handleDateChange}
                            value={
                              requiredData?.passport_expiry_date
                                ? moment(
                                    requiredData?.passport_expiry_date
                                  ).format("DD-MM-YYYY")
                                : null
                            }
                            className="input-field form-control form-control-sm"
                          />

                          {errors?.passport_expiry_date && (
                            <p className="errorText">
                              {errors?.passport_expiry_date}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    {/* bug */}

                    <Col md={6} className="mb-2">
                      <Form.Label className="fst-italic text-primary">
                        Do you hold any other valid passports?
                      </Form.Label>
                      <Row>
                        <Col xs={6}>
                          <Form.Check
                            onChange={handleChange}
                            value={"No"}
                            type="radio"
                            label="No"
                            checked={!showTable.hold_other_passport}
                            name="hold_other_passport"
                            id="DO_YOU_hold_other_passport_NO"
                          />
                        </Col>
                        <Col xs={6}>
                          <Form.Check
                            onChange={handleChange}
                            value={"Yes"}
                            checked={showTable.hold_other_passport}
                            type="radio"
                            label="Yes"
                            name="hold_other_passport"
                            id="DO_YOU_hold_other_passport_YES"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {showTable.hold_other_passport && (
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={6} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Type</Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Select
                                  size="sm"
                                  name="other_passport_type"
                                  onChange={handleChange}
                                  value={formData.other_passport_type}
                                >
                                  <option>Choose passport type</option>
                                  <option value="Diplomatice Passport">
                                    Diplomatice Passport
                                  </option>
                                  <option value="Official Passport">
                                    Official Passport
                                  </option>
                                  <option value="Ordinary Passport">
                                    Ordinary Passport
                                  </option>
                                  <option value="Others">Others</option>
                                </Form.Select>
                                {errors?.other_passport_type && (
                                  <p className="errorText">
                                    {errors?.other_passport_type}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Passport number </Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  type="text"
                                  value={formData.other_passport_number}
                                  name="other_passport_number"
                                  onChange={handleChange}
                                  size="sm"
                                  // required
                                />
                                {errors?.other_passport_number && (
                                  <p className="errorText">
                                    {errors?.other_passport_number}
                                  </p>
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>
                                  Issuing Authority/Place of issue{" "}
                                </Form.Label>
                              </Col>
                              <Col xs={8}>
                                <Form.Control
                                  type="text"
                                  value={
                                    formData.other_passport_issuing_authority
                                  }
                                  name="other_passport_issuing_authority"
                                  onChange={handleChange}
                                  size="sm"
                                  // required
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Date of issue</Form.Label>
                              </Col>
                              <Col xs={8}>
                                <DatePicker
                                  showYearDropdown
                                  showMonthDropdown
                                  dropdownMode="select"
                                  selected={
                                    formData?.other_passport_issue_date
                                      ? new Date(
                                          formData?.other_passport_issue_date
                                        )
                                      : null
                                  }
                                  maxDate={new Date()}
                                  className="input-field form-control form-control-sm"
                                  onChange={(date) =>
                                    handleChange({
                                      target: {
                                        name: "other_passport_issue_date",
                                        value:
                                          moment(date).format("MM-DD-YYYY"),
                                      },
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Expiry date</Form.Label>
                              </Col>
                              <Col xs={8}>
                                <DatePicker
                                  showYearDropdown
                                  showMonthDropdown
                                  dropdownMode="select"
                                  minDate={new Date()}
                                  selected={
                                    formData?.other_passport_expiry_date
                                      ? new Date(
                                          formData?.other_passport_expiry_date
                                        )
                                      : null
                                  }
                                  className="input-field form-control form-control-sm"
                                  onChange={(date) =>
                                    handleChange({
                                      target: {
                                        name: "other_passport_expiry_date",
                                        value:
                                          moment(date).format("MM-DD-YYYY"),
                                      },
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3"> */}
                  <Row>
                    <Col md={12}>
                      <h5>Contact Information</h5>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={2}>
                      <Form.Label>Permanent residential address</Form.Label>
                    </Col>
                    <Col xs={10}>
                      {" "}
                      <Form.Control
                        type="text"
                        value={formData.permanent_address}
                        name="permanent_address"
                        onChange={handleChange}
                        size="sm"
                      />
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col xs={2}>
                      <Form.Label>Contact address</Form.Label>
                    </Col>
                    <Col xs={10}>
                      {" "}
                      <Form.Control
                        type="text"
                        value={formData.contact_address}
                        name="contact_address"
                        onChange={handleChange}
                        size="sm"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="mb-2">
                      <Form.Label className="fst-italic text-primary">
                        Emergency Contacts
                      </Form.Label>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Full Name</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.emergency_full_name}
                            name="emergency_full_name"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Current residential address</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.current_residence_address}
                            name="current_residence_address"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Telephone number</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="number"
                            value={formData.telephone_number}
                            name="telephone_number"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Relationship</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.relationship}
                            name="relationship"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3"> */}
                  <Row>
                    <Col md={12}>
                      <h5>Occupation</h5>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Occupation</Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={formData.occupation}
                            name="occupation"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-2">
                      <Form.Label className="fst-italic text-primary">
                        Occupation Information
                      </Form.Label>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Name of Company/Agency/School</Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={formData.company_name}
                            name="company_name"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Position/Course of study</Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={formData.position}
                            name="position"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Address</Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="text"
                            value={formData.company_address}
                            name="company_address"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Telephone number</Form.Label>
                        </Col>
                        <Col xs={8}>
                          {" "}
                          <Form.Control
                            type="number"
                            value={formData.company_telephone}
                            name="company_telephone"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3"> */}
                  <Row>
                    <Col md={12}>
                      <h5>Information about the trip</h5>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Purpose of visit{" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={requiredData.purpose_of_visit}
                            onChange={handleChange}
                            name="purpose_of_visit"
                          >
                            <option value="">
                              -- Select Purpose of Visit --
                            </option>
                            <option value="M.I.C.E Tourist ( Meeting ,Incentives , Conferences &amp; Exhibitions/Events)">
                              {" "}
                              M.I.C.E Tourist ( Meeting ,Incentives ,
                              Conferences &amp; Exhibitions/Events)
                            </option>
                            <option value="Medical treatment including Ayurvedic (herbal)">
                              {" "}
                              Medical treatment including Ayurvedic (herbal)
                            </option>
                            <option value="Participate in Art,Music , and Dance Events">
                              {" "}
                              Participate in Art,Music , and Dance Events
                            </option>
                            <option value="Participate in Pilgrimages">
                              Participate in Pilgrimages
                            </option>
                            <option value="Participate in Sport Events">
                              {" "}
                              Participate in Sport Events
                            </option>
                            <option value="Participate in Weddings">
                              Participate in Weddings
                            </option>
                            <option value="Sightseeing in Holidaying">
                              Sightseeing in Holidaying
                            </option>
                            <option value="Visiting friends and relatives">
                              Visiting friends and relatives
                            </option>
                          </Form.Select>
                          {errors?.purpose_of_visit && (
                            <p className="errorText">
                              {errors?.purpose_of_visit}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-2">
                      <Form.Label className="fst-italic text-primary">
                        Agency/Organization/Individual that the applicant plans
                        to contact when enter into Vietnam
                      </Form.Label>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Name of hosting organisation</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.hosting_organisation}
                            name="hosting_organisation"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Address</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.organisation_address}
                            name="organisation_address"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Telephone number</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="number"
                            value={formData.organisation_telephone}
                            name="organisation_telephone"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Purpose</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.hosting_purpose}
                            name="hosting_purpose"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} className="mb-2">
                      <Form.Label className="fst-italic text-primary">
                        Information about the trip
                      </Form.Label>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Intended length of stay in Vietnam (number of days){" "}
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="tel"
                            value={requiredData.length_of_stay}
                            name="length_of_stay"
                            onChange={handleChange}
                            size="sm"
                          />

                          {errors?.length_of_stay && (
                            <p className="errorText">
                              {errors?.length_of_stay}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Intended date of entry
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <DatePicker
                            showYearDropdown
                            showMonthDropdown
                            dropdownMode="select"
                            selected={
                              requiredData?.date_of_entry
                                ? new Date(requiredData?.date_of_entry)
                                : null
                            }
                            minDate={new Date()}
                            className="input-field form-control-sm form-control"
                            onChange={(date) =>
                              handleChange({
                                target: {
                                  name: "date_of_entry",
                                  value: moment(date).format("MM-DD-YYYY"),
                                },
                              })
                            }
                          />
                          {errors?.date_of_entry && (
                            <p className="errorText">{errors?.date_of_entry}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Allowed to entry through checkpoint
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={requiredData.entry_through}
                            onChange={handleChange}
                            name="entry_through"
                          >
                            <option value="">-- Select Entry Gate --</option>
                            <option value="Tan Son Nhat Int Airport (Ho Chi Minh City)">
                              Tan Son Nhat Int Airport (Ho Chi Minh City)
                            </option>
                            <option value="Phu Quoc Int Airport (Kien Giang)">
                              Phu Quoc Int Airport (Kien Giang)
                            </option>
                            <option value="Phu Bai Int Airport (Hue)">
                              Phu Bai Int Airport (Hue)
                            </option>
                            <option value="Noi Bai Int Airport (Ha Noi)">
                              Noi Bai Int Airport (Ha Noi)
                            </option>
                            <option value="Van Don Int Airport (Quang Ninh)">
                              Van Don Int Airport (Quang Ninh)
                            </option>
                            <option value="Da Nang International Airport">
                              Da Nang International Airport
                            </option>
                            <option value="Can Tho International Airport">
                              Can Tho International Airport
                            </option>
                            <option value="Cam Ranh Int Airport (Khanh Hoa)">
                              Cam Ranh Int Airport (Khanh Hoa)
                            </option>
                            <option value="Cat Bi Int Airport (Hai Phong)">
                              Cat Bi Int Airport (Hai Phong)
                            </option>
                            <option value="Lien Khuong Int Airport (Lam Dong)">
                              Lien Khuong Int Airport (Lam Dong)
                            </option>
                            <option value="KLC">Lao Cai Landport</option>
                            <option value="KXM">Xa Mat Landport</option>
                            <option value="KTR">Tay Trang Landport</option>
                            <option value="KTB">Tinh Bien Landport</option>
                            <option value="KST">Song Tien Landport</option>
                            <option value="KNM">Na Meo Landport</option>
                            <option value="KNC">Nam Can Landport</option>
                            <option value="KMC">Mong Cai Landport</option>
                            <option value="KMB">Moc Bai Landport</option>
                            <option value="KLL">La Lay Landport</option>
                            <option value="KLB">Lao Bao Landport</option>
                            <option value="KHT">Ha Tien Landport</option>
                            <option value="KHN">Huu Nghi Landport</option>
                            <option value="KBY">Bo Y Landport</option>
                            <option value="KCL">Cha Lo Landport</option>
                            <option value="KCT">Cau Treo Landport</option>
                            <option value="CHP">Hai Phong Seaport</option>
                            <option value="CNS">Nghi Son Seaport</option>
                            <option value="CNT">Nha Trang Seaport</option>
                            <option value="CQN">Quy Nhon Seaport</option>
                            <option value="CCM">Chan May Seaport</option>
                            <option value="CCP">Cam Pha Seaport</option>
                            <option value="CSG">
                              Ho Chi Minh City Seaport
                            </option>
                            <option value="CDO">Duong Dong Seaport</option>
                            <option value="CDQ">Dung Quat Seaport</option>
                            <option value="CHG">Hon Gai Seaport</option>
                            <option value="CVT">Vung Tau Seaport</option>
                            <option value="CVA">Vung Ang Seaport</option>
                            <option value="CDN">Da Nang Seaport</option>
                          </Form.Select>
                          {errors?.entry_through && (
                            <p className="errorText">{errors?.entry_through}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Exit through checkpoint
                            <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={requiredData.exit_through}
                            name="exit_through"
                            onChange={handleChange}
                          >
                            <option value="">-- Select Exit Gate --</option>
                            <option value="Tan Son Nhat Int Airport (Ho Chi Minh City)">
                              Tan Son Nhat Int Airport (Ho Chi Minh City)
                            </option>
                            <option value="Phu Quoc Int Airport (Kien Giang)">
                              Phu Quoc Int Airport (Kien Giang)
                            </option>
                            <option value="Phu Bai Int Airport (Hue)">
                              Phu Bai Int Airport (Hue)
                            </option>
                            <option value="Noi Bai Int Airport (Ha Noi)">
                              Noi Bai Int Airport (Ha Noi)
                            </option>
                            <option value="Van Don Int Airport (Quang Ninh)">
                              Van Don Int Airport (Quang Ninh)
                            </option>
                            <option value="Da Nang International Airport">
                              Da Nang International Airport
                            </option>
                            <option value="Can Tho International Airport">
                              Can Tho International Airport
                            </option>
                            <option value="Cam Ranh Int Airport (Khanh Hoa)">
                              Cam Ranh Int Airport (Khanh Hoa)
                            </option>
                            <option value="Cat Bi Int Airport (Hai Phong)">
                              Cat Bi Int Airport (Hai Phong)
                            </option>
                            <option value="Lien Khuong Int Airport (Lam Dong)">
                              Lien Khuong Int Airport (Lam Dong)
                            </option>
                            <option value="Lao Cai Landport">
                              Lao Cai Landport
                            </option>
                            <option value="Xa Mat Landport">
                              Xa Mat Landport
                            </option>
                            <option value="Tay Trang Landport">
                              Tay Trang Landport
                            </option>
                            <option value="Tinh Bien Landport">
                              Tinh Bien Landport
                            </option>
                            <option value="Song Tien Landport">
                              Song Tien Landport
                            </option>
                            <option value="Na Meo Landport">
                              Na Meo Landport
                            </option>
                            <option value="Nam Can Landport">
                              Nam Can Landport
                            </option>
                            <option value="Mong Cai Landport">
                              Mong Cai Landport
                            </option>
                            <option value="Moc Bai Landport">
                              Moc Bai Landport
                            </option>
                            <option value="La Lay Landport">
                              La Lay Landport
                            </option>
                            <option value="Lao Bao Landport">
                              Lao Bao Landport
                            </option>
                            <option value="Ha Tien Landport">
                              Ha Tien Landport
                            </option>
                            <option value="Huu Nghi Landport">
                              Huu Nghi Landport
                            </option>
                            <option value="Bo Y Landport">Bo Y Landport</option>
                            <option value="Cha Lo Landport">
                              Cha Lo Landport
                            </option>
                            <option value="Cau Treo Landport">
                              Cau Treo Landport
                            </option>
                            <option value="Hai Phong Seaport">
                              Hai Phong Seaport
                            </option>
                            <option value="Nghi Son Seaport">
                              Nghi Son Seaport
                            </option>
                            <option value="Nha Trang Seaport">
                              Nha Trang Seaport
                            </option>
                            <option value="Quy Nhon Seaport">
                              Quy Nhon Seaport
                            </option>
                            <option value="Chan May Seaport">
                              Chan May Seaport
                            </option>
                            <option value="Cam Pha Seaport">
                              Cam Pha Seaport
                            </option>
                            <option value="Ho Chi Minh City Seaport">
                              Ho Chi Minh City Seaport
                            </option>
                            <option value="Duong Dong Seaport">
                              Duong Dong Seaport
                            </option>
                            <option value="Dung Quat Seaport">
                              Dung Quat Seaport
                            </option>
                            <option value="Hon Gai Seaport">
                              Hon Gai Seaport
                            </option>
                            <option value="Vung Tau Seaport">
                              Vung Tau Seaport
                            </option>
                            <option value="Vung Ang Seaport">
                              Vung Ang Seaport
                            </option>
                            <option value="Da Nang Seaport">
                              Da Nang Seaport
                            </option>
                          </Form.Select>
                          {errors?.exit_through && (
                            <p className="errorText">{errors?.exit_through}</p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Intended temporary residential address in Vietnam
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="text"
                            value={formData.temporary_address}
                            name="temporary_address"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            City/Province <span className="text-danger">*</span>
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={requiredData.temporary_city}
                            onChange={handleChange}
                            name="temporary_city"
                          >
                            <option value="">Choose City/Province</option>
                            <option value="AN GIANG">AN GIANG</option>
                            <option value="BA RIA - VUNG TAU">
                              BA RIA - VUNG TAU
                            </option>
                            <option value="BAC GIANG">BAC GIANG</option>
                            <option value="BAC KAN">BAC KAN</option>
                            <option value="BAC LIEU">BAC LIEU</option>
                            <option value="BAC NINH">BAC NINH</option>
                            <option value="BEN TRE">BEN TRE</option>
                            <option value="BINH DINH">BINH DINH</option>
                            <option value="BINH DUONG">BINH DUONG</option>
                            <option value="BINH PHUOC">BINH PHUOC</option>
                            <option value="BINH THUAN">BINH THUAN</option>
                            <option value="CA MAU">CA MAU</option>
                            <option value="CAN THO">CAN THO</option>
                            <option value="CAO BANG">CAO BANG</option>
                            <option value="DA NANG City">DA NANG City</option>
                            <option value="DAK LAK">DAK LAK</option>
                            <option value="DAK NONG">DAK NONG</option>
                            <option value="DIEN BIEN">DIEN BIEN</option>
                            <option value="DONG NAI">DONG NAI</option>
                            <option value="DONG THAP">DONG THAP</option>
                            <option value="GIA LAI">GIA LAI</option>
                            <option value="HA GIANG">HA GIANG</option>
                            <option value="HA NAM">HA NAM</option>
                            <option value="HA NOI City">HA NOI City</option>
                            <option value="HA TINH">HA TINH</option>
                            <option value="HAI DUONG">HAI DUONG</option>
                            <option value="HAI PHONG">HAI PHONG</option>
                            <option value="HAU GIANG">HAU GIANG</option>
                            <option value="HO CHI MINH City">
                              HO CHI MINH City
                            </option>
                            <option value="HOA BINH">HOA BINH</option>
                            <option value="HUNG YEN">HUNG YEN</option>
                            <option value="KHANH HOA">KHANH HOA</option>
                            <option value="KIEN GIANG">KIEN GIANG</option>
                            <option value="KON TUM">KON TUM</option>
                            <option value="LAI CHAU">LAI CHAU</option>
                            <option value="LAM DONG">LAM DONG</option>
                            <option value="LANG SON">LANG SON</option>
                            <option value="LAO CAI">LAO CAI</option>
                            <option value="LONG AN">LONG AN</option>
                            <option value="NAM DINH">NAM DINH</option>
                            <option value="NGHE AN">NGHE AN</option>
                            <option value="NINH BINH">NINH BINH</option>
                            <option value="NINH THUAN">NINH THUAN</option>
                            <option value="PHU THO">PHU THO</option>
                            <option value="PHU YEN">PHU YEN</option>
                            <option value="QUANG BINH">QUANG BINH</option>
                            <option value="QUANG NAM">QUANG NAM</option>
                            <option value="QUANG NGAI">QUANG NGAI</option>
                            <option value="QUANG NINH">QUANG NINH</option>
                            <option value="QUANG TRI">QUANG TRI</option>
                            <option value="SOC TRANG">SOC TRANG</option>
                            <option value="SON LA">SON LA</option>
                            <option value="TAY NINH">TAY NINH</option>
                            <option value="THAI BINH">THAI BINH</option>
                            <option value="THAI NGUYEN">THAI NGUYEN</option>
                            <option value="THANH HOA">THANH HOA</option>
                            <option value="THUA THIEN -HUE">
                              THUA THIEN -HUE
                            </option>
                            <option value="TIEN GIANG">TIEN GIANG</option>
                            <option value="TRA VINH">TRA VINH</option>
                            <option value="TUYEN QUANG">TUYEN QUANG</option>
                            <option value="VINH LONG">VINH LONG</option>
                            <option value="VINH PHUC">VINH PHUC</option>
                            <option value="YEN BAI">YEN BAI</option>
                          </Form.Select>
                          {errors?.temporary_city && (
                            <p className="errorText">
                              {errors?.temporary_city}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Phone number (in Vietnam)</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="number"
                            value={formData.temporary_phone}
                            name="temporary_phone"
                            onChange={handleChange}
                            size="sm"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Row>
                      <Col md={6} className="mb-2">
                        <Form.Label className="fst-italic text-primary">
                          Have you been to Vietnam in the last 01 year?
                        </Form.Label>
                        <Row>
                          <Col xs={6}>
                            <Form.Check
                              onChange={handleChange}
                              value={"No"}
                              type="radio"
                              label="No"
                              checked={!showTable.visit_in_one_year}
                              name="visit_in_one_year"
                              id="EVER_VISITED_VIET_IN_10_YEARS_NO"
                            />
                          </Col>
                          <Col xs={6}>
                            <Form.Check
                              onChange={handleChange}
                              value={"Yes"}
                              checked={showTable.visit_in_one_year}
                              type="radio"
                              label="Yes"
                              name="visit_in_one_year"
                              id="EVER_VISITED_VIET_IN_10_YEARS_YES"
                            />
                          </Col>
                        </Row>
                      </Col>
                      {showTable.visit_in_one_year && (
                        <Col md={12}>
                          <Table borderless bordered striped>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Purpose</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.vietnamVisitDetails.map(
                                (detail, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <DatePicker
                                        selected={
                                          detail.from_date
                                            ? new Date(detail.from_date)
                                            : null
                                        }
                                        onChange={(date) => {
                                          const updatedDetails = [
                                            ...formData.vietnamVisitDetails,
                                          ];
                                          updatedDetails[index].from_date = date
                                            ? formatDateBirth(
                                                date,
                                                "dd/MM/yyyy"
                                              )
                                            : "";
                                          setFormData({
                                            ...formData,
                                            vietnamVisitDetails: updatedDetails,
                                          });
                                        }}
                                        maxDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="DD/MM/YYYY"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        size="sm"
                                        name="from_date"
                                        className="form-control form-control-sm"
                                      />
                                    </td>
                                    <td>
                                      <DatePicker
                                        selected={
                                          detail.to_date
                                            ? new Date(detail.to_date)
                                            : null
                                        }
                                        onChange={(date) => {
                                          const updatedDetails = [
                                            ...formData.vietnamVisitDetails,
                                          ];
                                          updatedDetails[index].to_date = date
                                            ? formatDateBirth(
                                                date,
                                                "dd/MM/yyyy"
                                              )
                                            : "";
                                          setFormData({
                                            ...formData,
                                            vietnamVisitDetails: updatedDetails,
                                          });
                                        }}
                                        // minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="DD/MM/YYYY"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        size="sm"
                                        name="to_date"
                                        className="form-control form-control-sm"
                                      />
                                    </td>
                                    <td>
                                      <Form.Control
                                        type="text"
                                        size="sm"
                                        value={detail.purpose || ""}
                                        onChange={(e) => {
                                          const updatedDetails = [
                                            ...formData.vietnamVisitDetails,
                                          ];
                                          updatedDetails[index].purpose =
                                            e.target.value;
                                          setFormData({
                                            ...formData,
                                            vietnamVisitDetails: updatedDetails,
                                          });
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <IoTrashSharp
                                        color="red"
                                        onClick={() =>
                                          handleDeleteVisitRow(index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col md={6} className="mb-2">
                        <Form.Label className="fst-italic text-primary">
                          Do you have relatives who currently reside in Vietnam?
                        </Form.Label>
                        <Row>
                          <Col xs={6}>
                            <Form.Check
                              onChange={handleChange}
                              value={"No"}
                              type="radio"
                              label="No"
                              checked={!showTable.relatives_in_vietnam}
                              name="relatives_in_vietnam"
                              id="ANY_RELATED_IN_VIET_NO"
                            />
                          </Col>
                          <Col xs={6}>
                            <Form.Check
                              onChange={handleChange}
                              value={"Yes"}
                              type="radio"
                              checked={showTable.relatives_in_vietnam}
                              label="Yes"
                              name="relatives_in_vietnam"
                              id="ANY_RELATED_IN_VIET_YES"
                            />
                          </Col>
                        </Row>
                      </Col>
                      {showTable.relatives_in_vietnam && (
                        <Col md={12}>
                          <Table borderless bordered striped>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Full name</th>
                                <th>Date of Birth</th>
                                <th>Nationality</th>
                                <th>Relationship</th>
                                <th>Residential address</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {formData.relativeDetails.map((detail, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      value={detail.full_name || ""}
                                      onChange={(e) => {
                                        const array = [
                                          ...formData.relativeDetails,
                                        ];
                                        array[index] = {
                                          ...array[index],
                                          full_name: e.target.value,
                                        };
                                        setFormData({
                                          ...formData,
                                          relativeDetails: array,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <DatePicker
                                      selected={
                                        detail.from_date
                                          ? new Date(detail.from_date)
                                          : null
                                      }
                                      onChange={(date) => {
                                        const updatedDetails = [
                                          ...formData.relativeDetails,
                                        ];
                                        updatedDetails[index].from_date = date
                                          ? formatDateBirth(date, "dd/MM/yyyy")
                                          : "";
                                        setFormData({
                                          ...formData,
                                          relativeDetails: updatedDetails,
                                        });
                                      }}
                                      maxDate={new Date()}
                                      dateFormat="dd/MM/yyyy"
                                      placeholderText="DD/MM/YYYY"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      size="sm"
                                      name="from_date"
                                      className="form-control form-control-sm"
                                    />
                                  </td>
                                  <td>
                                    <Form.Select
                                      size="sm"
                                      name="nationality"
                                      value={detail.nationality || ""}
                                      onChange={(e) => {
                                        const array = [
                                          ...formData.relativeDetails,
                                        ];
                                        array[index] = {
                                          ...array[index],
                                          nationality: e.target.value,
                                        };
                                        setFormData({
                                          ...formData,
                                          relativeDetails: array,
                                        });
                                      }}
                                    >
                                      <option value="">Option</option>
                                      {Country.map((item) => {
                                        return (
                                          <option
                                            key={item.country_code}
                                            value={item.country_name}
                                          >
                                            {item.country_name}
                                          </option>
                                        );
                                      })}
                                    </Form.Select>
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      value={detail.relationship || ""}
                                      onChange={(e) => {
                                        const array = [
                                          ...formData.relativeDetails,
                                        ];
                                        array[index] = {
                                          ...array[index],
                                          relationship: e.target.value,
                                        };
                                        setFormData({
                                          ...formData,
                                          relativeDetails: array,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="text"
                                      size="sm"
                                      value={detail.address || ""}
                                      onChange={(e) => {
                                        const array = [
                                          ...formData.relativeDetails,
                                        ];
                                        array[index] = {
                                          ...array[index],
                                          address: e.target.value,
                                        };
                                        setFormData({
                                          ...formData,
                                          relativeDetails: array,
                                        });
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <IoTrashSharp
                                      color="red"
                                      onClick={() =>
                                        handleDeleteRelativeRow(index)
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      )}
                    </Row>
                  </Row>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 pb-0 mb-3"> */}
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header
                        className="m-0 border-0 p-0"
                        style={{ backgroundColor: "#ddd" }}
                      >
                        <h5 className="m-0 text-dark lh-1">
                          Under 14 years old accompanying child(ren) included in
                          your passport (IF ANY)
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body className="p-0 bg-light pt-3">
                        <Row>
                          <Col md={12}>
                            <Table borderless bordered striped>
                              <thead>
                                <tr>
                                  <th>
                                    Full name (First name Middle name Last name)
                                  </th>
                                  <th style={{ width: "200px" }}>Sex</th>
                                  <th style={{ width: "250px" }}>
                                    Date of birth (DD/MM/YYYY)
                                  </th>
                                  <th style={{ width: "120px" }}>
                                    Portrait photography
                                  </th>
                                  <th>Remove</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <Row>
                                      <Col xs={1}>1</Col>
                                      <Col xs={11}>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          value={
                                            formData.childDetail.length > 0
                                              ? formData.childDetail[0]
                                                  .full_name
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const array = [
                                              ...formData.childDetail,
                                            ];
                                            const detail =
                                              array.length > 0
                                                ? { ...array[0] }
                                                : {};
                                            detail.full_name = e.target.value;
                                            array.splice(0, 1, detail);
                                            setFormData({
                                              ...formData,
                                              childDetail: array,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </td>
                                  <td>
                                    <Form.Select
                                      size="sm"
                                      name="passport_type"
                                      value={
                                        formData.childDetail.length > 0
                                          ? formData.childDetail[0].gender
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const array = [...formData.childDetail];
                                        const detail =
                                          array.length > 0
                                            ? { ...array[0] }
                                            : {};
                                        detail.gender = e.target.value;
                                        array.splice(0, 1, detail);
                                        setFormData({
                                          ...formData,
                                          childDetail: array,
                                        });
                                      }}
                                    >
                                      <option value="">Option</option>
                                      <option value="Male">Male</option>

                                      <option value="Female">Female</option>
                                    </Form.Select>
                                  </td>
                                  <td>
                                    <DatePicker
                                      selected={parseDate(
                                        formData.childDetail.length > 0
                                          ? formData.childDetail[0]
                                              .date_of_birth
                                          : null
                                      )}
                                      onChange={(date) => {
                                        const array = [...formData.childDetail];
                                        const detail =
                                          array.length > 0
                                            ? { ...array[0] }
                                            : {};
                                        detail.date_of_birth = date
                                          ? formatDateBirth(date)
                                          : "";
                                        array.splice(0, 1, detail);
                                        setFormData({
                                          ...formData,
                                          childDetail: array,
                                        });
                                      }}
                                      maxDate={new Date()}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="dd-MM-yyyy"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      size="sm"
                                      name="date_of_birth"
                                      className="form-control form-control-sm"
                                    />
                                  </td>
                                  <td>
                                    <div className="file-upload-container">
                                      <label
                                        htmlFor="child-upload"
                                        className="profile-upload-box-children-accompany  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative"
                                      >
                                        {!formData.childDetail.length > 0 ? (
                                          <img
                                            src={require("../assets/img/passport.jpg")}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={
                                              nodeUrl +
                                              formData.childDetail[0].image
                                            }
                                            style={{
                                              width: "100%",
                                              height: 200,
                                              objectFit: "contain",
                                            }}
                                            alt=""
                                          />
                                        )}
                                      </label>
                                      <Form.Control
                                        type="file"
                                        id="child-upload"
                                        name="child_upload"
                                        accept="image/*"
                                        onChange={uploadFilesChild}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <IoTrashSharp color="red" />
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Row>
                                      <Col xs={1}>2</Col>
                                      <Col xs={11}>
                                        <Form.Control
                                          type="text"
                                          size="sm"
                                          value={
                                            formData.childDetail.length > 1
                                              ? formData.childDetail[1]
                                                  .full_name
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const array = [
                                              ...formData.childDetail,
                                            ];
                                            const detail =
                                              array.length > 1
                                                ? { ...array[1] }
                                                : {};
                                            detail.full_name = e.target.value;
                                            array.splice(1, 1, detail);
                                            setFormData({
                                              ...formData,
                                              childDetail: array,
                                            });
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </td>
                                  <td>
                                    <Form.Select
                                      size="sm"
                                      name="passport_type"
                                      value={
                                        formData.childDetail.length > 1
                                          ? formData.childDetail[1].gender
                                          : ""
                                      }
                                      onChange={(e) => {
                                        const array = [...formData.childDetail];
                                        const detail =
                                          array.length > 1
                                            ? { ...array[1] }
                                            : {};
                                        detail.gender = e.target.value;
                                        array.splice(1, 1, detail);
                                        setFormData({
                                          ...formData,
                                          childDetail: array,
                                        });
                                      }}
                                    >
                                      <option value="">Option</option>
                                      <option value="Male">Male</option>

                                      <option value="Female">Female</option>
                                    </Form.Select>
                                  </td>
                                  <td>
                                    <DatePicker
                                      selected={parseDate(
                                        formData.childDetail.length > 1
                                          ? formData.childDetail[1]
                                              .date_of_birth
                                          : null
                                      )}
                                      onChange={(date) => {
                                        const array = [...formData.childDetail];
                                        const detail =
                                          array.length > 1
                                            ? { ...array[1] }
                                            : {};
                                        detail.date_of_birth = date
                                          ? formatDateBirth(date)
                                          : "";
                                        array.splice(1, 1, detail);
                                        setFormData({
                                          ...formData,
                                          childDetail: array,
                                        });
                                      }}
                                      maxDate={new Date()} // Set maximum date to current day
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="dd-MM-yyyy"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      size="sm"
                                      name="date_of_birth"
                                      className="form-control form-control-sm"
                                    />
                                  </td>
                                  <td>
                                    <div className="file-upload-container">
                                      <label
                                        htmlFor="child2-upload"
                                        className="profile-upload-box-children-accompany  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative"
                                      >
                                        {!formData.childDetail.length > 1 ? (
                                          <img
                                            src={require("../assets/img/passport.jpg")}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={
                                              formData.childDetail.length > 1
                                                ? nodeUrl +
                                                  formData.childDetail[1].image
                                                : ""
                                            }
                                            style={{
                                              width: "100%",
                                              height: 200,
                                              objectFit: "cover",
                                            }}
                                            alt=""
                                          />
                                        )}
                                      </label>
                                      <Form.Control
                                        type="file"
                                        id="child2-upload"
                                        name="child_upload"
                                        accept="image/*"
                                        onChange={uploadFilesChild2}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <IoTrashSharp color="red" />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/* </div>
                <div className="bg-light p-3 rounded-3 pt-0 mb-3"> */}
                  <Row>
                    <Col md={12}>
                      <h5>Trip's expense, insurance</h5>
                    </Col>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>Intended expenses (in USD)e</Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Control
                            type="number"
                            value={formData.intended_expenses}
                            name="intended_expenses"
                            onChange={handleChange}
                            size="sm"
                            className="text-end fw-bold"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Who will cover the trip’s expenses of the applicant
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={formData.trip_expenses}
                            name="trip_expenses"
                            onChange={(event) => {
                              const { value } = event.target;
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                trip_expenses: value,
                              }));
                            }}
                          >
                            <option value="">-- Select-- </option>
                            <option value="The applicant">The applicant</option>
                            <option value="Other agency/organization/individual">
                              Other agency/organization/individual
                            </option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Col>
                    {formData.trip_expenses && (
                      <Col md={6} className="mb-2">
                        <Row>
                          <Col xs={4}>
                            <Form.Label>Type</Form.Label>
                          </Col>

                          <Col xs={8}>
                            <Form.Select
                              size="sm"
                              value={formData.trip_expenses_type}
                              name="trip_expenses_type"
                              onChange={(event) => {
                                const { value } = event.target;
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  trip_expenses_type: value,
                                }));
                              }}
                            >
                              <option value="">Select</option>
                              <option value="cash">Cash</option>
                              <option value="credit">Credit card</option>
                              <option value="cheque">
                                Traveller's cheques
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </Col>
                    )}

                    {formData.trip_expenses ===
                      "Other agency/organization/individual" && (
                      <>
                        <Col md={6} className="mb-2">
                          <Row>
                            <Col xs={4}>
                              <Form.Label>
                                Name of hosting organisation
                              </Form.Label>
                            </Col>
                            <Col xs={8}>
                              <Form.Control
                                type="text"
                                value={formData.trip_hosting_organisation}
                                name="trip_hosting_organisation"
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    trip_hosting_organisation: value,
                                  }));
                                }}
                                size="sm"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} className="mb-2">
                          <Row>
                            <Col xs={4}>
                              <Form.Label>Address</Form.Label>
                            </Col>
                            <Col xs={8}>
                              <Form.Control
                                type="text"
                                value={formData.trip_hosting_address}
                                name="trip_hosting_address"
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    trip_hosting_address: value,
                                  }));
                                }}
                                size="sm"
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} className="mb-2">
                          <Row>
                            <Col xs={4}>
                              <Form.Label>Telephone number</Form.Label>
                            </Col>
                            <Col xs={8}>
                              <Form.Control
                                type="number"
                                value={formData.trip_hosting_telephone}
                                name="trip_hosting_telephone"
                                onChange={(event) => {
                                  const { value } = event.target;
                                  setFormData((prevFormData) => ({
                                    ...prevFormData,
                                    trip_hosting_telephone: value,
                                  }));
                                }}
                                size="sm"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row>
                    <Col md={6} className="mb-2">
                      <Row>
                        <Col xs={4}>
                          <Form.Label>
                            Does the applicant have health insurance arranged
                            for their stay in Viet Nam?
                          </Form.Label>
                        </Col>
                        <Col xs={8}>
                          <Form.Select
                            size="sm"
                            value={formData.health_insurance}
                            onChange={(event) => {
                              const { value } = event.target;
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                health_insurance: value,
                              }));
                            }}
                            name="health_insurance"
                          >
                            <option value="">-- Select --</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Form.Select>
                        </Col>
                      </Row>
                    </Col>
                    {formData.health_insurance === "Yes" && (
                      <Col md={6} className="mb-2">
                        <Row>
                          <Col xs={4}>
                            <Form.Label>Detail</Form.Label>
                          </Col>
                          <Col xs={8}>
                            <Form.Control
                              type="text"
                              value={formData.health_insurance_details}
                              name="health_insurance_details"
                              onChange={handleChange}
                              size="sm"
                            />
                          </Col>
                        </Row>
                      </Col>
                    )}
                  </Row>
                  {/* <Row>
                    <Col md={12} className="mb-2 d-flex justify-content-center">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="i_assure"
                        />
                        <label
                          className="form-check-label fs-6"
                          htmlFor="i_assure"
                        >
                          I assure that I have truthfully declared all relevant
                          details.
                        </label>
                      </div>
                    </Col>
                  </Row> */}
                </div>
                <div>
                  <Row>
                    <Col className="mb-2">
                      <Button
                        className="submit_btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Continue
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}
