import config, { nodeUrl } from "./config";
import axios from "axios";

export const userService = {
  post,
  get,
  uploadImage,
};
function post(apiEndpoint, payload) {
  return axios
    .post(config.nodeUrl + apiEndpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status === 401) {
      } else {
      }
      return err;
    });
}

function get(apiEndpoint, domain) {
  return axios
    .get(config.nodeUrl + apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}

function uploadImage(apiEndpoint, payload) {
  return axios
    .post(config.nodeUrl + apiEndpoint, payload, {
      "Content-Type": "multipart/form-data",
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response !== undefined && err.response.status === 401) {
        // window.open('/','_self');
      } else {
        //alert('operation not able to perform');
      }
      return err;
    });
}

export const uploadFile = async (url, file) => {
  
  try {
    const formData = new FormData();
    formData.append("file_path", file);
    const response = await axios({
      method: "post",
      url: nodeUrl+"applicationForm/" + url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status !== 200) {
      console.log(response.statusText);
    } else {
      return { response: response.data };
    }
  } catch (err) {
    console.error(err);
  }
};
