import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function VietnamVisaTestimonials() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Vietnam Visa Testimonials</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-sm-8 col-sm-offset-2">
										<div class="testimonials text-center">
											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for your excellent and quick support.
															With best regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Erik Hoving</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much for your excellent services.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Thuan</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Hello. Excellent, thank you! All the best.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Melissa</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you so much. I am looking forward to
															visiting your beautiful country.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Peter</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															I received the approval letter. Thank you so much
															for being so accomodating to my request and had it
															done in less than 2 working days. I’m impressed. I
															will definitely recommend your service to all my
															friends.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Gyong Cheul Yoon – New Zealand</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Let me just say your service and your payment
															system is second to none. It is very simple and
															easy to follow. Wish you all the best and thank
															you for your help.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Jemin Yoon – New Zealand</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Hi Vietnam Visa Team. I just wanted to write and
															thank you with your help getting my visa
															authorization letter. You offer a fabulous service
															and I will tell all my friends…
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Gaurav Jain</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you. I received it! Thanks for the excellent
															and prompt service! You are great!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- David Philpott – Australia</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Yes this is my first day back. The trip was
															excellent and your people did an excellent job. I
															will highly recommend your company to others.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Stephen Dale Douglas – United Kingdom</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Hi, we have just been back from vietnam-hanoi.
															Found the visa processexcellent and was granted a
															1month multiple very easily. Thank you forall the
															help.cheers and regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Kai Hughes – United Kingdom</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Excellent! Thank you so much for your Quick Reply.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Shohreh Eslami – United Kingdom</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Approval Letter well received, please acknowledge
															our great appreciations for your excellent
															service.You can count on us for a good publicity
															of your website. Wishing you all the best,
															respectfully.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Trevor Iseard – United Kingdom</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															The service was quick and efficient and whenever I
															emailed you with a question, which was more than
															once, someone responded within a very short time.I
															can now look forward to my trip to Vietnam relaxed
															in the knowledge that I should have no problems
															entering at Ho Chi Mihn airport.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Thomas Morgan Drake – United Kingdom</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thx a lot for the excellent &amp; fast service
															(Now I am able/I can visit my, Vietnamese, spouse
															&amp; daughter.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Samuel Elliott</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															I have used your service twice so far (previously
															in August of 2010) and have found it to be an
															excellent value and easy to use. Now I can also
															add very fast and excellent customer service to
															that as well. Thanks again for your fast reply and
															taking the time to answer my questions. Have a
															wonderful day.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Scarlett Rose Bowden</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															I just wanted to confirm that everything went vey
															well and according to your very very good and
															detailed descriptions on your homepage and Mails.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Lesley Jayne McDonald</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for the efficient processing of my visa
															application. This is truly one of the most
															reliable services that I have encountered and has
															made the process of applying for a visa simple. I
															will certainly apply with your company again next
															time I go to Vietnam.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Lucinda Elizabeth Smyth</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															I have successfully received my visa letter and am
															now on my way! I can not thank you enough for your
															help. You have been wonderful!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Thushani Kumarasinghe</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for all your assistance. This was a
															quick and painless process! I will be sure to
															recommend you to my clients.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Timothy David Lovatt – United Kingdom</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															The most speedy and efficient service that you
															have personally provided. Thank you also for the
															advice on what to do next in the process, and I am
															sure that both my wife and I will enjoy our visit
															to your country.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Lucy Marsh</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Very fast and professional service, I could use my
															credit card on a safe payment website without the
															need of creating a Paypal account or any other
															kind of money transfer service. Will definitely
															recommend to my friends and colleagues!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Amelia Marsh</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much for your quick service. I was
															able to book the flight tonight and am very much
															looking forward to visiting Vietnam.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Hannah Wade</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Just wanted to say thank you for your help this
															past weekend in sorting out my Vietnam Visa. I
															know you went out of your way – I really
															appreciate it and I have recommended your service
															to my friends who are also travelling to Vietnam
															in the near future.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Thomas Marks</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much for your help and patience
															with me, I now know what I have to do, you have
															been extremely helpful better than I expected, I
															am quite sure that I will go to VN next year and I
															will definitely book with your web site. Thanking
															you again for your excellent service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- David Alexander Sy</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															That’s excellent visa service. Thank you very
															much.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Ka Lai Wong</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Good afternoon Vietnam Visa Team, thank you for
															the quick and excellent service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Pieter van der Stelt</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															This is an excellent service – in fact it is the
															best online service I have seen. Typical
															Vietnamese pragmatism and efficiency.
															Congratulations.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Jim</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Your instructions and information is excellent.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Hans</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Thank you! As always excellent service.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Rebecca</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Hi! Thanks for your excellent service and
															immediate response. Thank you once again!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Merita</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thanx a lot!!! I will recommend your service to my
															friends! Excellent job! Cam on nieu!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Elena Kevrel</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thanks again. You have been more than excellent in
															the service you have provided with me and have
															eased my anxiety that I might not get the
															application in time. I really do appreciate it.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Thiessen Blair</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Thank you very much! This is excellent news.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Richard Ledain</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent, thanks very much!</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Jacqueline</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Excellent and thanks again. I am looking forward
															to visiting Vietnam!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- George</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much for your excellent service!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Robert Joseph Blum</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Ah, that is excellent news. Thanks so much for
															your help.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Jack Marks</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															I would like to express my deepest thanks for your
															excellent and fast service. I am sure that you
															will be the first choice if a similar service is
															needed in future.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Musaed Nasser J AlAwad,</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you! I appreciate your excellent
															communication and delivery of visa on time as
															promised. We are very pleased!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Aaron</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you again for your help and excellent work.
															You guys are great. Everything worked out great.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Robert Maltz</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much. Your service is excellent!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Leon Santos</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Great thank you for your excellent service!</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Daniel Harry Gelfer</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Hi, just want to let you know that I have got the
															approval letter. Thanks for your excellent
															services. I no doubt will recommend my friends
															using your service. Have a nice day still.:-)
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Tuan Nguyen</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you once again for your excellent service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Ranjit Dutta</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent, thanks very much.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Ciaran Cierans</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Good morning, thank you very much for the
															excellent service. Kind regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Gillian</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Many thanks for your help – this is really an
															excellent service by the way! I have now paid for
															the Fast Track and will arrive at HCMC at 10.55am
															on SQ172. Thanks.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Alex</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Hello again, thank you once again for helping us
															with our visa. We made it into the country so
															thank you. Hope you enjoy the rest of your day.
															Kind regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Kirsty Feist</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Good Day, thank you for your fast and excellent
															service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Basil Baby</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear team, I would like to thank you for the
															excellent service of issuing the approval letter
															at your earliest.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Nitya Violet Creado</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thanks for the speedy processing of the Visa.
															Excellent Service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Ganesan Baskara Iyer</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Excellent – I love Vietnamese Professionalism
															;-))) Thank you!!!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Michael Menke</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															This is the second time I have used your services
															and I highly recommend to everybody going to
															Vietnam . Thank you for provided such excellent
															service!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Kevin Svend Miller</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for your help. By the way, my trip to
															Vietnam was excellent! What a wonderful country to
															visit.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Ingrid</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much for your quick reply. Wishes
															of an excellent day!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Daniel Lourneço</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much. You have provided excellent
															service and I am very pleased. Best regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Colleen</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for the visa letter and the other
															important information. You provide an excellent
															service. Kind regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Keith White</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear Vietnamese Visa Team. Thank you for this most
															excellent service. I will be recommending you to
															friends. I will email you my flight details after
															i book the flight for late tomorrow afternoon.
															Thanks again for helping me make my visit to
															vietnam an enjoyable one. Warm regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Tom Moran</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Firstly thank you for your excellent service you
															have provided for on line visa.I really very much
															appreciate.
															<br />
															with best Regard.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Mian Mohammad Farooq</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear colleagues, you and the person at the airport
															received me had done an excellent job. Much
															appreciate your support and assistance. It makes
															my life easier. Thank you very much. Best Regards
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Calvin Li</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Excellent thank you very much. You made my day!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Darren</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent. Thank you.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Bruno Georges</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear Vietnam Visa Team, thank you for your
															excellent service!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Mascha</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear Visa Team, thank you for your prompt reply.
															Your proposal sounds good, thank you.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Bruce Thompson</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear Team, thanks for your excellent support in
															providing me the visa. Regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Santoshi</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Thank you for your excellent service.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Dean Forbes</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Thank you for your excellent service!</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Ethan Baron</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Thank you. Excellent service :-))))</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Aminur Rahman</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for your excellent service, and kind
															regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Mrs Georgia Penelope Jane Jillings</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Many thanks for all your excellent assistance.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- David Hewitson</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent! Thank you.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Vikas Pershad</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you very much – this is excellent. Best
															regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Geoff</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent, thank you!</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Antonio Coto Gutierrez</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear Visa team. Thank you for excellent service
															during my visa obtaining procedure in HCM. Lep
															pozdrav / Best regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Tomaž M. Jamnik</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															We have arranged our Vietnam visa through your
															company for one month single entry beginning on 2
															January 2016, and thank you for your excellent
															service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Michael Singer, Los Angeles, California</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															This is noted, many thanks for your fast response
															and excellent service!!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Rahma Bel-Bachir</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Thank you very much. Excellent service!</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Edu Aggenbach from South Africa</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thank you for your excellent service. Always
															appreciated. See you in Hanoi!
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Colleen</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent. Very quick service..thank you.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- David</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Hello. Thank you very much for your help and
															cooperation. I appreciate your understanding and
															the discounted price you have provided. We are so
															excited to visit Vietnam. You provide an excellent
															service.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Taylor Beth</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>Excellent, many thanks.</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Diana</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															To whom it may concern, thank you very much for
															the excellent and speedy service regarding my
															visa.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Louise</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Excellent! Thank you very much and nice to speak
															with you on the phone today. Regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Heidi Diane Fowler</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Dear Vietnam Visa Team, I was at the airport in
															Singapore last night for a flight to HCMC and
															realised my VN visa had expired. I found you
															through a google search and you fixed the problem
															in 66 minutes. On a Sunday evening. On a public
															holiday weekend.
															<strong>That is an excellent service. </strong>
															Thank you very much – greatly appreciated Best
															regards – and I hope you are getting to enjoy a
															day off today! Cheers,
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Haig Conolly</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															The Vietnam Visa Team is an excellent company
															providing wonderful service. I have used you in
															the past and will continue to use you in the
															future. I will ask all my friends to use you, as
															well. Your happy customer,
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Vern Alg</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															Thanks a lot for a good service! Best regards.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Christer Petersson</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															You have done a good job, Vietnam Visa Team. Thank
															you so much for supporting me with the approval
															letter online and fast track at the airport so
															fast.
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Shim Patrick Kilduff</i>
														</span>
													</div>
												</div>
											</div>

											<div class="testimonial clearfix">
												<div class="text clearfix">
													<div class="center">
														<p>
															I am in Vietnam now. My visa is good. Please keep
															your good &amp; efficient work.{" "}
														</p>
														<span className="fw-bold mb-4 d-inline-block">
															<i>- Benjamin Pierce Everitt</i>
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default VietnamVisaTestimonials;
