import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./MainLayout.module.css";
import { Link } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import Select from "react-select";
import { MdOutlineFacebook } from "react-icons/md";
import { AiFillTwitterCircle, AiFillInstagram } from "react-icons/ai";

const currentDate = new Date();

function Footer() {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      const response = await fetch("https://restcountries.com/v2/all");
      const data = await response.json();
      const countryOptions = data.map((country) => ({
        value: country.alpha2Code,
        label: country.name,
      }));
      setCountries(countryOptions);
    };
    fetchCountries();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  return (
    <>
      <section className="apply_instant">
        <Container>
          <Row className="align-items-center">
            <Col lg={8} md={9} className="text-center text-md-start">
              <h2>Apply for Online Vietnamese Visa Application</h2>
            </Col>
            <Col lg={4} md={3} className="text-md-end text-center">
              <Link to="/apply-online" className="button_global">
                Apply Now
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <footer>
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={10} md={12}>
              <Row>
                <Col lg={9} md={12}>
                  <Row>
                    <Col lg={8} md={9}>
                      <Row>
                        <Col md={6}>
                          <h4>Information Center</h4>
                          <ul className={classes.list}>
                            <li>
                              <Link to="/australian-citizens">
                                Australian Citizens
                              </Link>
                            </li>
                            <li>
                              <Link to="/british-citizens">
                                British Citizens
                              </Link>
                            </li>
                            <li>
                              <Link to="/german-citizens">German Citizens</Link>
                            </li>
                            <li>
                              <Link to="/united-states-citizens">
                                United States Citizens
                              </Link>
                            </li>
                            <li>
                              <Link to="/french-citizens">French Citizens</Link>
                            </li>
                            <li>
                              <Link to="/new-zealand-citizens">
                                New Zealand Citizens
                              </Link>
                            </li>
                            <li>
                              <Link to="/canadian-citizens">
                                Canadian Citizens
                              </Link>
                            </li>
                          </ul>
                        </Col>
                        <Col md={6}>
                          <h4>VISA Info</h4>
                          <ul className={classes.list}>
                            <li>
                              <Link to="/how-to-apply">
                                Vietnam Visa Requirements and Application
                                Process – How to Apply
                              </Link>
                            </li>
                            <li>
                              <Link to="/visa-fees">Visa Fees</Link>
                            </li>
                            <li>
                              <Link to="/apply-online">
                                Online Vietnamese Visa Application
                              </Link>
                            </li>
                            <li>
                              <Link to="/apply-online-new">
                                Online Vietnamese Visa Application
                              </Link>
                            </li>
                            <li>
                              <Link to="/government-policies">
                                Policies and Procedures
                              </Link>
                            </li>
                            <li>
                              <Link to="/embassy">Vietnam Embassies</Link>
                            </li>
                            <li>
                              <Link to="/visa-on-arrival">
                                Vietnamese Visas On Arrival
                              </Link>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={11} md={12}>
                          <div className={classes.disclaimer}>
                            <strong>Disclaimers:</strong> vietnamvisa-vn.org is e-commercial/non-government website. We provide visa approval letter service which is officially approved by Immigration Department.
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={3}>
                      <h4>Information</h4>
                      <ul className={classes.list}>
                        <li>
                          <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/vietnam-visa-testimonials">
                            Vietnam Visa Testimonials
                          </Link>
                        </li>
                        <li>
                          <Link to="/history">History</Link>
                        </li>
                        <li>
                          <Link to="/payment-guidelines">Payment Guidelines</Link>
                        </li>                      
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="/terms-and-conditions">Terms and Conditions</Link>
                        </li>
                        <li>
                          <Link to="/travel-tips">Visa Tips</Link>
                        </li>
                        <li>
                          <Link to="/sitemap">Sitemap</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col lg={3} md={12}>
                  <Row>
                    <Col lg={12} md={8}>
                      <h4>Have a question?</h4>
                      <ul className={`${classes.list} ${classes.contacts}`}>
                        <li>
                          <a href="tel:(+84) 832 320 320" title="Call us">
                            <BiSolidPhoneCall />
                          </a>
                          <div>
                            <b>
                              <a href="tel:(+84) 832 320 320" title="Call us">
                                (+84) 832 320 320
                              </a>
                            </b>
                            <span>8.00 AM-21.00 PM, GMT +7</span>
                          </div>
                        </li>
                        <li>
                          <a href="mailto:info@Vietnamevisa.org">
                            <IoIosMail />
                          </a>
                          <div>
                            <b>
                              <a href="mailto:info@Vietnamevisa.org">
                                info@Vietnamevisa.org
                              </a>
                            </b>
                            <span>Vietnam Visa Support Email</span>
                          </div>
                        </li>
                        <li>
                          <a
                            href="https://www.google.com/maps/place/Vietnam+Visa/@21.0490708,105.7511889,17z/data=!3m1!4b1!4m6!3m5!1s0x3134558f220ae211:0x98d18ece686fdfd3!8m2!3d21.0490658!4d105.7537638!16s%2Fg%2F11fjxz0bcb?entry=ttu"
                            target="_blank"
                          >
                            <ImLocation />
                          </a>
                          <div>
                            <span>
                              BDA Building, Lo E50, Khu 3Ha, Phuc Dien, Bac Tu
                              Liem, Hanoi, Vietnam
                            </span>
                          </div>
                        </li>
                      </ul>
                    </Col>
                    <Col lg={12} md={4} className="mt-lg-5 mt-md-0">
                      <div className={classes.select_country}>
                        <h4 className="mb-3">
                          Check if you need a Visa to Vietnam
                        </h4>
                        <Select
                          id="countrySelect"
                          options={countries}
                          value={selectedCountry}
                          onChange={handleSelectChange}
                          placeholder="Select..."
                          menuPlacement="top"
                        />
                        {/* 
											{selectedCountry && (
												<p>Selected country: {selectedCountry.label}</p>
											)} */}
                      </div>
                      <ul className={classes.socialLinks}>
                        <li>
                          <Link to="/" target="_blank">
                            <MdOutlineFacebook />
                          </Link>
                        </li>
                        <li>
                          <Link to="/" target="_blank">
                            <AiFillTwitterCircle />
                          </Link>
                        </li>
                        <li>
                          <Link to="/" target="_blank">
                            <AiFillInstagram />
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={12}
                  style={{
                    borderTop: "solid 1px rgba(94, 115, 135, 0.5)",
                  }}
                  className="mt-5 pt-5"
                >
                  <Row className="align-items-center">
                    <Col lg={6} md={6}>
                      <div className={classes.card_wrap}>
                        <ul className={classes.cards}>
                          <li>
                            <img
                              src={require("../assets/img/cards/1.jpg")}
                              alt="visa"
                            />
                          </li>
                          <li>
                            <img
                              src={require("../assets/img/cards/2.jpg")}
                              alt="master card"
                            />
                          </li>
                          <li>
                            <img
                              src={require("../assets/img/cards/3.jpg")}
                              alt="american express"
                            />
                          </li>
                          <li>
                            <img
                              src={require("../assets/img/cards/4.jpg")}
                              alt="jcb"
                            />
                          </li>
                          <li>
                            <img
                              src={require("../assets/img/cards/5.jpg")}
                              alt="one pay"
                            />
                          </li>
                          <li>
                            <img
                              src={require("../assets/img/cards/6.jpg")}
                              alt="dmca"
                            />
                          </li>
                          <li>
                            <img
                              src={require("../assets/img/cards/7.jpg")}
                              alt="combo secure"
                            />
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6} md={6} className="text-md-end text-center">
                      © {currentDate.getDay()} Vietnam Visa.  adasdAll Rights Reserved
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
