import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";
import InnerLayout from "../components/InnerLayout";

function VisaFees() {
  return (
    <MainLayout>
      <div
        className=" p-md-5 border"
        style={{ backgroundColor: "#2e65a8", marginTop: "-10px" }}
      >
        <ScrollToTop />
        <section className="p-0">
          <Container className="bg-white p-4 mb-0 rounded-3 pt-0">
            <Row className="p-2 pt-4">
              <Col md={12}>
                <div className="homebanner mt-2"></div>
              </Col>
              <Col md={4} className="mt-3">
                <Link to="/apply-online" className="labelbuttons first">
                  <h4 className="m-0">
                    Apply Vientam Visa <br />
                    Get Tourist eVisa in One Step{" "}
                  </h4>
                </Link>
              </Col>
              <Col md={4} className="mt-3">
                <Link to="/partial-application" className="labelbuttons second">
                  <h4 className="m-0">
                    Amend or Complete Partialy <br /> Filled Form
                  </h4>
                </Link>
              </Col>
              <Col md={4} className="mt-3">
                <Link to="/make-payment" className="labelbuttons third">
                  <h4 className="m-0">Make payment</h4>
                  <p>Complete the payment of partial application</p>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <InnerLayout>
                <Row>
                  <Col md={12}>
                    <h2>Vietnam eVisa Fees</h2>
                    <h4>Fee structure:-</h4>
                    <p>
                      The total fee payable to our website vietnamvisa-vn.org
                      comprises of two parts :
                    </p>
                    <h2>Terms and Conditions Related to Fee payment:-</h2>
                    <ol>
                      <li>
                        <strong>Vietnam eVisa 01 Month Single Entry:-</strong>{" "}
                        We charge an overall fee of USD 89 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                      <li>
                        <strong>Vietnam eVisa 01 Month Multiple Entry:-</strong>{" "}
                        We charge an overall fee of USD 99 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                      <li>
                        <strong>Vietnam eVisa 03 Month Single Entry:-</strong>{" "}
                        We charge an overall fee of USD 109 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                      <li>
                        <strong>Vietnam eVisa 03 Month Multiple Entry:-</strong>{" "}
                        We charge an overall fee of USD 119 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                    </ol>
                    <h2>Expedite Service Fee:-</h2>
                    <ol>
                      <li>
                        <strong>Vietnam eVisa 01 Month Single Entry:-</strong>{" "}
                        We charge an overall fee of USD 149 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                      <li>
                        <strong>Vietnam eVisa 01 Month Multiple Entry:-</strong>{" "}
                        We charge an overall fee of USD 159 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                      <li>
                        <strong>Vietnam eVisa 03 Month Single Entry:-</strong>{" "}
                        We charge an overall fee of USD 169 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                      <li>
                        <strong>Vietnam eVisa 03 Month Multiple Entry:-</strong>{" "}
                        We charge an overall fee of USD 179 (Fees vary based on
                        nationality) for a 30 Days Single Entry Visa This
                        includes the visa fee which is charged by the Government
                        of Vietnam. Exchange rate applicable per passenger
                        excluding interchange charge for credit/debit cards.
                      </li>
                    </ol>
                    <h2>Vietnam Govt. Fee</h2>
                    <p>
                      The Govt. The fee for Vietnam e-visa is USD 25, USD 50,
                      depending on the Visa type and nationality of the
                      applicant. There is no fee from the Govt. of Vietnam for a
                      few nationalities. We advise you to check the government
                      fee on the official website xuatnhapcanh.gov.vn and then
                      pay on our website. Once the application is paid and
                      processed, the fee is 100% non-refundable.
                    </p>
                  </Col>
                </Row>
              </InnerLayout>
            </Row>
          </Container>
        </section>
      </div>
    </MainLayout>
  );
}

export default VisaFees;
