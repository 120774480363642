import React from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import classes from "../components/MainLayout.module.css";
import MainLayout from "../components/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";

const PreviewForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Container>
        <div className="bg-light p-4  rounded-3 pt-0 mb-3">
          <Row>
            <Col md={12}>
              <h5>Overview</h5>
            </Col>
            <Col md={12}>
              <div className={classes.overview}>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td>Visa Type:</td>
                      <td>
                        <strong>{location?.state?.type_of_visa}</strong>
                      </td>
                      <td>Date Arrival:</td>
                      <td>
                        <strong>{location?.state?.date_Of_Arrival}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td>Processing Time:</td>
                      <td>
                        <strong>{location?.state?.process_time}</strong>
                      </td>
                      <td>Port Of Arrival:</td>
                      <td>
                        <strong>{location?.state?.port_of_Arrival}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col md={12}>
              <h5>Applicants</h5>
            </Col>
            <Col md={12}>
              <Table bordered>
                <tbody>
                  <tr>
                    <td>{location?.state?.full_Name}</td>
                    <td>{location?.state?.passport_Number}</td>
                    <td>{location?.state?.gender}</td>
                    <td>{location?.state?.date_of_Birth}</td>
                    <td>{location?.state?.nationality}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <h5>Contact Information</h5>
              <Table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      <strong>{location?.state?.customer_name}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>
                      <strong>{location?.state?.confirm_email}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>
                      <strong>{location?.state?.customer_iPhone}</strong>
                    </td>

                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={6}>
              <h5>Extra Service</h5>
              <Table>
                <tbody>
                  <tr>
                    <td>Car Pickup :</td>
                    <td>
                      {/* <strong>No</strong> */}
                      <strong>
                        {!location?.state?.car_pick_up ? "Yes" : "No"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Airport Fast-Track :</td>
                    <td>
                      {/* <strong>Yes</strong> */}
                      <strong>
                        {!location?.state?.airport_fast_track ? "Yes" : "No"}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Travel Insurance :</td>
                    <td>
                      {/* <strong>No</strong> */}
                      <strong>
                        {!location?.state?.travel_insurance ? "Yes" : "No"}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col md={12} className="mt-4">
              <Form.Check
                label="I has read and agree with terms & conditionand privacy polices"
                name="read-terms"
                type="checkbox"
              />
            </Col>
            <Row>
              <Col className="mt-3">
                <Button
                  size="sm"
                  className="border rounded-2 bg-success p-2 me-2"
                  onClick={() => {
                    navigate("/apply-online", {
                      state: location.state,
                    });
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="sm" className="border rounded-2 bg-danger p-2">
                  Save & Pay
                </Button>
              </Col>
            </Row>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
};

export default PreviewForm;
