import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";

function TravelTips() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Vietnam Travel Tips</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={6}>
							<div className="bg-light p-3 rounded-2">
								<img
									src={require("../assets/img/guide.jpg")}
									alt=""
									className="w-100"
								/>
								<h4>
									Why a Hang Va Cave Tour Needs to Make Your Bucket List This
									Year
								</h4>
								<p>
									Nestled deep in the recesses of beautiful Phong Nha-ke Bang
									National Park in Vietnam, the Hang Va cave system promises
									intrepid travelers the adventure of a lifetime. The UNESCO
									World Heritage site of Phong Nha is already home to incredible
									trekking experiences, unforgettable vistas, friendly locals,
									and activities to suit every traveler in your group.
								</p>
								<Link to="/" className="button_global">
									Read more
								</Link>
							</div>
						</Col>
						<Col md={6}>
							<div className="bg-light p-3 rounded-2">
								<img
									src={require("../assets/img/guide.jpg")}
									alt=""
									className="w-100"
								/>
								<h4>
									Why a Hang Va Cave Tour Needs to Make Your Bucket List This
									Year
								</h4>
								<p>
									Nestled deep in the recesses of beautiful Phong Nha-ke Bang
									National Park in Vietnam, the Hang Va cave system promises
									intrepid travelers the adventure of a lifetime. The UNESCO
									World Heritage site of Phong Nha is already home to incredible
									trekking experiences, unforgettable vistas, friendly locals,
									and activities to suit every traveler in your group.
								</p>
								<Link to="/" className="button_global">
									Read more
								</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default TravelTips;
