import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function BannerLayout({ children }) {
	return (
		<section className="banner_wrapper">
			<Container>
				<Row className="justify-content-center">
					<Col lg={8} md={10}>
						<h1>{children}</h1>
					</Col>
				</Row>
			</Container>
		</section>
	);
}

export default BannerLayout;
