import React, { useState } from "react";
import MainLayout from "../components/MainLayout";
import {
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import { FaShieldHeart, FaCar } from "react-icons/fa6";
import ScrollToTop from "../components/ScrollToTop";

function ApplicationDetails() {
  const [step, setStep] = React.useState(0);
  const [formData, setFormData] = React.useState({});

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <MainLayout>
      <ScrollToTop />
      <section>
        <Container>
          <Row>
            <Col md={12} className="online_apply_main_wrapper">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} className="mb-4">
                    <div className="steps_bar">
                      <ul>
                        <li>
                          <span>1</span>Applicant Info
                        </li>
                        <li>
                          <span>2</span>Trip Detail
                        </li>
                        <li>
                          <span>3</span>Review
                        </li>
                        <li>
                          <span>4</span>Payment
                        </li>
                      </ul>
                      <ProgressBar color="#2f4662" now={(step / 3) * 100} />
                    </div>
                  </Col>
                  <Col md={step !== 2 ? 8 : 12}>
                    {step === 0 && (
                      <div className="bg-light p-4  rounded-3 pt-0">
                        <Row>
                          <Col md={12}>
                            <h5>Arrival Information</h5>
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Visa Type</Form.Label>
                            <Form.Select aria-label="visa type">
                              <option value="1 Month Single Entry">
                                1 Month Single Entry
                              </option>
                              <option value="1 Month Multiple Entry">
                                1 Month Multiple Entry
                              </option>
                              <option value="3 Month Single Entry">
                                3 Month Single Entry
                              </option>
                              <option value="3 Month Multiple Entry">
                                3 Month Multiple Entry
                              </option>
                            </Form.Select>
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Processing Time</Form.Label>
                            <Form.Select aria-label="processing time">
                              <option value="Normal 5-10 Working Days">
                                Normal 5-10 Working Days
                              </option>
                              <option value="Urgent 02 Working Days">
                                Urgent 02 Working Days
                              </option>
                              <option value="Super Urgent 1 Working Days">
                                Super Urgent 1 Working Days
                              </option>
                            </Form.Select>
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Date Of Arrival</Form.Label>
                            <Form.Control type="date" />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Date Of Exit</Form.Label>
                            <Form.Control type="date" />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Port Of Arrival</Form.Label>
                            <Form.Select aria-label="visa type">
                              <option value="1">Airport 1</option>
                              <option value="2">Airport 2</option>
                              <option value="3">Airport 3</option>
                              <option value="4">Airport 4</option>
                            </Form.Select>
                          </Col>
                          <Col md={12}>
                            <h5>Applicant Information</h5>
                          </Col>
                          <Col md={12}>
                            <div className="bg-white p-4 rounded-3 border">
                              <Row>
                                <Col md={6} className="mb-3">
                                  <Form.Label>Full Name</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Enter email"
                                  />
                                </Col>
                                <Col md={6} className="mb-3">
                                  <Form.Label>Gender</Form.Label>
                                  <Form.Select aria-label="gender">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                  </Form.Select>
                                </Col>
                                <Col md={6} className="mb-3">
                                  <Form.Label>Date of Birth</Form.Label>
                                  <Form.Control
                                    type="date"
                                    placeholder="Enter email"
                                  />
                                </Col>
                                <Col md={6} className="mb-3">
                                  <Form.Label>Passport Number</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter email"
                                  />
                                </Col>
                                <Col md={6} className="mb-3">
                                  <Form.Label>Nationality</Form.Label>
                                  <Form.Select aria-label="nationality">
                                    <option value="">country one</option>
                                    <option value="">country two</option>
                                    <option value="">country three</option>
                                  </Form.Select>
                                </Col>
                                <Col md={12}>
                                  <Row>
                                    <Col md={6} className="mb-3">
                                      <Form.Label>Face Image</Form.Label>
                                      <div className="file-upload-container">
                                        <label
                                          htmlFor="profile-upload"
                                          className="profile-upload-box  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative"
                                        >
                                          {selectedFile ? (
                                            <img
                                              src={URL.createObjectURL(
                                                selectedFile
                                              )}
                                              alt="Selected File"
                                              className="preview-image"
                                            />
                                          ) : (
                                            <div>
                                              {/* <BsCloudUpload className="upload-icon" /> */}
                                              <p>Click to select a file</p>
                                            </div>
                                          )}
                                        </label>
                                        <Form.Control
                                          type="file"
                                          id="profile-upload"
                                          accept="image/*"
                                          onChange={handleFileChange}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                      <Form.Label>Passport Image</Form.Label>
                                      <div className="file-upload-container">
                                        <label
                                          htmlFor="profile-upload"
                                          className="profile-upload-box  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative w-100"
                                        >
                                          {selectedFile ? (
                                            <img
                                              src={URL.createObjectURL(
                                                selectedFile
                                              )}
                                              alt="Selected File"
                                              className="preview-image"
                                            />
                                          ) : (
                                            <div>
                                              {/* <BsCloudUpload className="upload-icon" /> */}
                                              <p>Click to select a file</p>
                                            </div>
                                          )}
                                        </label>
                                        <Form.Control
                                          type="file"
                                          id="profile-upload"
                                          accept="image/*"
                                          onChange={handleFileChange}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col md={12}>
                            <h5>Contact Information</h5>
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter email"
                            />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Customer iPhone</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter email"
                            />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Customer Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                            />
                          </Col>
                          <Col md={6} className="mb-3">
                            <Form.Label>Confirm Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    {step === 1 && (
                      <div className="bg-light p-4  rounded-3 pt-0">
                        <Row>
                          <Col md={12}>
                            <h5>Trip Details</h5>
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            Permanent Residence Address
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            <Form.Control type="text" />
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            Hotel Booking Status
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            <div className="d-flex gap-3">
                              <Form.Check
                                type="radio"
                                label="Booked"
                                name="hotel-booking"
                              />
                              <Form.Check
                                type="radio"
                                label="Not Booked"
                                name="hotel-booking"
                                checked
                              />
                            </div>
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            Round-Trip Flight Status
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            <div className="d-flex gap-3">
                              <Form.Check
                                type="radio"
                                label="Purchased"
                                name="round-trip"
                              />
                              <Form.Check
                                type="radio"
                                label="Not Purchased"
                                name="round-trip"
                                checked
                              />
                            </div>
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            Travel Planning
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            <div className="d-flex gap-3">
                              <Form.Check
                                type="radio"
                                label="Self-Planned"
                                name="travel-planning"
                              />
                              <Form.Check
                                type="radio"
                                label="Travel Agency"
                                name="travel-planning"
                                checked
                              />
                            </div>
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            Agency Name
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            <Form.Control type="text" />
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            Travel Insurance Coverage
                          </Col>
                          <Col xs={12} sm={6} className="mb-4">
                            <div className="d-flex gap-3">
                              <Form.Check
                                type="radio"
                                label="Self-Planned"
                                name="travel-insurance-coverage"
                              />
                              <Form.Check
                                type="radio"
                                label="Travel Agency"
                                name="travel-insurance-coverage"
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="bg-white rounded-3 border p-3">
                              As mandated by the Vietnam Ministry of Health, all
                              travelers to Vietnam must carry travel insurance
                              that covers a minimum of $10,000 for Covid-19
                              related medical treatment within the country.
                            </div>
                          </Col>
                          <Col md={12}>
                            <h5>Extra Service</h5>
                          </Col>
                          <Col md={12}>
                            <div className="d-flex gap-4 mb-3">
                              <div>
                                <Form.Check
                                  label=""
                                  name="travel-insurance"
                                  type="checkbox"
                                />
                              </div>
                              <div>
                                <FaShieldHeart color="#f37735" size={40} />
                              </div>
                              <div>
                                <h6 className="m-0">Travel Insurance</h6>
                                <p className="m-0">
                                  Secure your trip with coverage for unexpected
                                  medical events, including Covid-19, and
                                  baggage issues, up to $10,000. Recommended for
                                  travelers to Vietnam, in line with Ministry of
                                  Health guidelines.
                                </p>
                              </div>
                            </div>
                            <div className="d-flex gap-4 mb-3">
                              <div>
                                <Form.Check
                                  label=""
                                  name="travel-insurance"
                                  type="checkbox"
                                />
                              </div>
                              <div>
                                <FaCar color="#f37735" size={40} />
                              </div>
                              <div>
                                <h6 className="m-0">Car Pick-up</h6>
                                <p className="m-0">
                                  Reduce airport taxi queue waiting time & avoid
                                  non-English speaking driver. Our team will
                                  welcome you at arrival hall with your name
                                  sign, pick you up and transfer to your hotel.
                                </p>
                              </div>
                            </div>
                            <div className="d-flex gap-4">
                              <div>
                                <Form.Check
                                  label=""
                                  name="travel-insurance"
                                  type="checkbox"
                                />
                              </div>
                              <div>
                                <FaCar color="#f37735" size={40} />
                              </div>
                              <div>
                                <h6 className="m-0">Airport Fast-track</h6>
                                <p className="m-0">
                                  This service will expedite the entry procedure
                                  at a Vietnam airport, including skip the line
                                  & getting through Customs and having your
                                  passport stamped.
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    {step === 2 && (
                      <div className="bg-light p-4  rounded-3 pt-0">
                        <Row>
                          <Col md={12}>
                            <h5>Overview</h5>
                          </Col>
                          <Col md={12}>
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td>Visa Type:</td>
                                  <td>
                                    <strong>1 Month Single Entry</strong>
                                  </td>
                                  <td>Date Arrival:</td>
                                  <td>
                                    <strong>2023-11-30</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Processing Time:</td>
                                  <td>
                                    <strong>01 Working Day</strong>
                                  </td>
                                  <td>Port Of Arrival:</td>
                                  <td>
                                    <strong>
                                      Van Don Int Airport (Quang Ninh)
                                    </strong>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={12}>
                            <h5>Applicants</h5>
                          </Col>
                          <Col md={12}>
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td>Test</td>
                                  <td>123456789</td>
                                  <td>Male</td>
                                  <td>2023-12-6</td>
                                  <td>Delhi</td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={6}>
                            <h5>Contact Information</h5>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>Name</td>
                                  <td>
                                    <strong>Mohit</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Email</td>
                                  <td>
                                    <strong>test@mail.com</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Phone</td>
                                  <td>
                                    <strong>7845124587</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={6}>
                            <h5>Extra Service</h5>
                            <Table>
                              <tbody>
                                <tr>
                                  <td>Car Pickup :</td>
                                  <td>
                                    <strong>No</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Airport Fast-Track :</td>
                                  <td>
                                    <strong>Yes</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Travel Insurance :</td>
                                  <td>
                                    <strong>No</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                          <Col md={12} className="mt-4">
                            <Form.Check
                              label="I has read and agree with terms & conditionand privacy polices"
                              name="read-terms"
                              type="checkbox"
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                    <div className="d-flex justify-content-between gap-5 mt-3">
                      {step > 0 && step !== 2 && (
                        <Button
                          variant="secondary"
                          className="step_btn prev"
                          onClick={handlePrevious}
                        >
                          Previous
                        </Button>
                      )}
                      {step < 3 ? (
                        <Button
                          variant="primary"
                          className="step_btn"
                          onClick={handleNext}
                        >
                          Next
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="step_btn"
                          type="submit"
                        >
                          Confirm & Pay
                        </Button>
                      )}
                    </div>
                  </Col>
                  {step !== 2 && (
                    <Col md={4}>
                      <div className="bg-light p-4  pt-0 rounded-3 summary_box">
                        <h5 className="d-inline-block">Summary</h5>
                        <Table>
                          <tbody>
                            <tr>
                              <td>Number of Visas:</td>
                              <td className="text-end">12</td>
                            </tr>
                            <tr>
                              <td>Visa Type:</td>
                              <td className="text-end">
                                01 Month Single Entry
                              </td>
                            </tr>
                            <tr>
                              <td>Processing Time :</td>
                              <td className="text-end">10 Working Days</td>
                            </tr>
                            <tr>
                              <td>
                                <div
                                  style={{ color: "#f37735" }}
                                  className="fs-4"
                                >
                                  Total
                                </div>
                              </td>
                              <td>
                                <div
                                  style={{ color: "#f37735" }}
                                  className="fs-4 fw-bold text-end"
                                >
                                  10,000
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  )}
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}

export default ApplicationDetails;
