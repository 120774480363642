import React from "react";

import { userService } from "../service";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";

const PayPalGateway = ({ payment, callback }) => {
  return (
    <PayPalScriptProvider
      options={{
        // "client-id":
        //   "AfE5j9bsIoO24fOsVITa3S3RBKrgHFZRowWYQMFTaI1YoqrpZMNehOE5hcQ1KD9ju6kdLPZf8eR1wDea",
        "client-id":
          "Ad3s--Iqvofg6fKolXj9GL-ePVteftO3GvSf_dq7TvNqFcFcG08FXl43oOibnXrky63VcTxk66seDOGm",
      }}
    >
      <PayPalButtons
        createOrder={async (data, actions) => {
          return await actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: 100,
                  // value: Number(payment.amount),
                },
                description: "Cambodia Application ID" + 34323,
                // description: "Cambodia Application ID" + payment.temporary_id,
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          // return payment;
          return await actions.order.capture().then(async (details) => {
            let param = {
              order_id: details.id,
              status: null,
              payment_id: details.purchase_units[0].id,
              order_status:
                details.status === "VOIDED" ||
                details.status === "PAYER_ACTION_REQUIRED"
                  ? "Failed"
                  : "Confirmed",
              tran_id: null,
              temporary_id: payment.temporary_id,
              pay_id: details.payer.payer_id,
              state: null,
              cart: null,
              payment_method: "paypal",
              payment_status: details.status,
              amount: details.purchase_units[0].amount.value,
              currency: details.purchase_units[0].amount.currency_code,
              description: null,
              invoice_number: null,
              payer_email: payment.email,
              payer_name: payment.surname,
              create_time: null,
              ep_tran_id: null,
              passport_no: payment.passport_no,
              ip_address: payment.ip_address,
              country_nationality: payment.country_nationality,
              intended_date_of_entry: payment.intended_date_of_entry,
              visa_type: payment.visa_type,
            };
            let response = await userService.post(
              "/payment/updatePayment",
              param
            );
            if (response.valid) {
              callback(param);
            }
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalGateway;
