import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Verified from "../../src/assets/img/verified.jpg";
import Secure from "../../src/assets/img/secure.jpg";

export default function InnerLayout({ children }) {
  return (
    <>
      <Container className="bg-white p-4 mb-0 pb-0">
        <Row>
          <Col md={3}>
            <aside className="homeSideBar">
              <h3>Visa Information</h3>
              <ul>
                <li>
                  <Link to="/visa-fees">Visa Fees</Link>
                </li>
                <li>
                  <Link to="/travel-guides">Travel Guides</Link>
                </li>
                <li>
                  <Link to="/government-policies">Policies & Procedures</Link>
                </li>
                <li>
                  <Link to="/embassy">Embassy</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy & Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions</Link>
                </li>
                <li>
                    <Link to="/payment-guidelines">Payment Guidlines</Link>
                </li>
                <li>
                    <Link to="/apply-online">Apply Online</Link>
                </li>
              </ul>
              <h3></h3>
            </aside>
            <aside className="imagePanel">
              <img
                src={Verified}
                alt="verified"
                className="w-100 border"
              />
            </aside>
            <aside className="imagePanel">
              <img
                src={Secure}
                alt="secure"
                className="w-100  border"
              />
            </aside>
            <aside className="customerPanel">
              <h3>Customer Care</h3>
              <ul>
                <li>
                  <Link to="mailto:info@vietnamvisa-vn.org">info@vietnamvisa-vn.org</Link>
                </li>
                {/* <li>
                  <Link to="/">1234567890</Link>
                </li> */}
              </ul>
            </aside>
          </Col>
          <Col md={9}>{children}</Col>
        </Row>
      </Container>
    </>
  );
}
