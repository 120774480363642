import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function PoliciesProcedures() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Policies and Procedures</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								International travel agencies have the right to request that a
								competent state agency (the Vietnam Immigration Department, or
								VID) approve the entry, exit, or transit of tourists as stated
								in Article 13 of Decree No. 92/2007/ND-CP. This decree details
								the implementation of a number of the Articles of the Laws on
								Tourism, including regulations regarding the rights and
								obligations of international tourist agencies. International
								travel agencies, therefore, can legally request the VID’s
								assistance in approving visa Approval Letters for their tourist
								clients.
							</p>
							<p>
								<strong>
									Article 13.&nbsp;Rights and obligations of international
									travel enterprises
								</strong>
							</p>
							<p>
								1. An international travel enterprise has the following rights:
							</p>
							<p>
								a) To enjoy business autonomy and take responsibility for
								tourism business activities;
							</p>
							<p>
								b) To request a competent state agency to approve the entry,
								exit or transit of tourists;
							</p>
							<p>
								c) To have their lawful tourism business activities protected by
								the State;
							</p>
							<p>
								d) To participate in tourism promotion activities; join in
								professional associations;
							</p>
							<p>e) Other rights provided for by law.</p>
							<hr />
							<p>
								2. An international travel enterprise has the following
								obligations:
							</p>
							<p>
								a) To set up, register and conduct its business in accordance
								with law;
							</p>
							<p>
								b) To employ only tourist guides who possess international
								tourists guide’s cards to guide foreign tourists and observe
								regulations on the employment of laborers;
							</p>
							<p>
								c) To notify the provincial-level state agency charge of tourism
								of the replacement of its travel business administrator within
								30 days after such replacement;
							</p>
							<p>
								d) To monitor and make full and accurate statistics on the
								number of tourists to whom it has provided tourism services;
							</p>
							<p>
								e) To assure the conditions for, an interests of, tourists in
								accordance with the concluded agreements;
							</p>
							<p>f) Other obligations prescribed by law.</p>
							<p>
								For full English Translation Version, please see here:&nbsp;
								<a
									href="http://asemconnectvietnam.gov.vn/lawdetail.aspx?lawid=1579"
									target="_blank"
									rel="noopener noreferrer"
								>
									Decree No. 92/2007/ND-CP
								</a>
								.
							</p>
							<hr />
							<p>
								<strong>Vietnam visa exemption</strong>
							</p>
							<p>Vietnam Visa exemption is granted to:</p>
							<p>
								1. Citizens of Cambodia, Thailand, Malaysia, Singapore,
								Indonesia and Laos for visits of 30 days at the maximum.
							</p>
							<p>
								2. Citizens of Philippines for visits of 21 days at the maximum.
							</p>
							<p>
								3. Citizens of Japan, South Korea, Sweden, Norway, Denmark,
								Russia and Finland for visits of 15 days at the maximum.
							</p>
							<p>4. Citizens of Brunei for visits of 14 days at the maximum.</p>
							<p>
								5. Citizens of France holding valid diplomatic or official
								passports for visits of 90 days at the maximum or several visits
								of 6 months at the maximum.
							</p>
							<p>
								6. Citizens of Chile holding valid diplomatic or official
								passports for visits of 90 days at the maximum.
							</p>
							<p>
								7. APEC Business Travel Card (ABTC) Holders from Asia-Pacific
								Economic Cooperation (APEC) member economies for visits of 60
								days at the maximum.
							</p>
							<p>
								8. For those who travel to Phu Quoc Island – Vietnam :
								Foreigners and Vietnamese nationals bearing foreign passports
								who enter Vietnam through an international border gate and then
								travel to Phu Quoc Island and stay in Phu Quoc less than 15 days
								will also be granted Vietnam visa exemption. Passports must be
								valid for at least 45 days. After arriving in Phu Quoc Island,
								if visitors want to travel to other localities or stay in the
								island for more than 15 days, the immigration department will be
								responsible for issuing visas right on the spot.
							</p>
							<hr />
							<p>
								<strong>National Ordinance No. 24/2000/PL-UBTVQH10</strong>
							</p>
							<p>
								Related to entry, exit, and residence of foreigners in Vietnam,
								the Standing Committee of Vietnam National Assembly issued a
								National Ordinance No. 24/2000/PL-UBTVQH10 on 28 April, 2000.
								The ordinance includes 7 chapters and 26 articles stating
								specifically who is responsible for what in terms of Vietnam
								visa issuance, and who can get a visa to enter Vietnam and for
								how long.
							</p>
							<p>
								Of the 26 articles, the following selected articles will help
								you understand how and why a travel agency can legally assist
								you in getting a visa Approval Letter.
							</p>
							<p>
								<strong>Article 2.</strong>
							</p>
							<ol start="1">
								<li>
									Vietnamese agencies and organizations, Vietnam – based foreign
									agencies and organizations as well as international
									organizations, Vietnamese citizens and foreigners lawfully
									residing in Vietnam may invite foreigners into Vietnam.
								</li>
								<li>
									Agencies, organizations and individuals that invite foreigners
									into Vietnam shall have to ensure the compliance with
									foreigners’ entry purposes, ensure financial matters and their
									cooperation with the State bodies to settle arising problems
									for foreigners.
								</li>
							</ol>
							<p>
								<strong>Article 5.</strong>
							</p>
							<ol start="1">
								<li>
									Agencies, organizations and individuals that invite foreigners
									into Vietnam shall send their written requests to the entry
									and exit management agency of the Ministry of Public Security
									or the consular office of the Ministry for Foreign Affairs.
								</li>
								<li>
									A written request shall be replied within no more than 5
									working days after the receipt of the request.
								</li>
							</ol>
							<p>
								<strong>Article 6.</strong>
							</p>
							<ol start="1">
								<li>
									&nbsp;Foreigners applying for entry shall be granted visas at
									Vietnam’s international border gates in the following cases:
								</li>
							</ol>
							<ul>
								<li>
									&nbsp;They enter for funerals of their relatives, for visits
									to their seriously ill relatives;
								</li>
								<li>
									&nbsp;They depart from countries where Vietnamese diplomatic
									missions and/or consulates are not available;
								</li>
								<li>
									&nbsp;They enter for visits under programs organized by
									international tour enterprises of Vietnam;
								</li>
								<li>
									&nbsp;They enter to provide urgent technical support for
									programs, projects; to give first-aid to seriously ill
									persons, accident victims; to provide rescue for victims of
									natural disasters and epidemics in Vietnam;
								</li>
								<li>&nbsp;For other urgent reasons.</li>
							</ul>
							<p>
								2.&nbsp;&nbsp; The exit and entry management agency of the
								Ministry of Public Security shall issue visas as prescribed in
								Clause 1 of this Article.
							</p>
							<p>
								For the full version of the Ordinance, please download
								here:&nbsp;{" "}
								<a
									href="http://www.scribd.com/doc/28968095/Ordinance-24-2000-Pl-Ubtvqh-10"
									target="_blank"
									rel="noopener noreferrer"
								>
									<strong>National Ordinance No. 24/2000/PL-UBTVQH10</strong>
								</a>
								<strong>.</strong>
							</p>
							<hr />
							<p>
								<strong>Vietnam Public Holidays in 2019</strong>
							</p>
							<p>
								Here is a list of Vietnamese national public holidays in 2019.
								Kindly note that on these special days, the Vietnam Immigration
								Department closes, and no Vietnam visa Approval Letters are
								issued until the next normal business day (business days are
								Monday through Friday only and exclude Saturday and Sunday).
								Special holiday/weekend visa fee must be applied if visa
								approval letters are requested during these holiday/weekend
								periods.
							</p>
							<p>JAN 01 Western New Year’s Day</p>
							<p>FEB&nbsp;20 to FEB 27&nbsp;Vietnamese Lunar New Year</p>
							<p>APR 15 Hung Kings Temple Festival</p>
							<p>APR 30 Reunification Day</p>
							<p>MAY 01 International Labor Day</p>
							<p>
								AUG 19 and AUG 20 Foundation Day of Vietnam Public Security
								System
							</p>
							<p>
								SEP 02 and SEP 03 National Independence Day (Marks Vietnam’s
								declaration of independence in 1945)
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default PoliciesProcedures;
