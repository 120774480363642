import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function BritishCitizens() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Vietnamese Visas for British Citizens</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								From 15 Aug 2023, the government of Vietnam decided to extend
								the duration of stays in Vietnam&nbsp;
								<strong>from 15 to 45 days</strong>&nbsp;for citizens of 13
								countries who are unilaterally exempted from visas by Vietnam.
							</p>
							<p>
								These countries are&nbsp;
								<strong>
									Germany, France, Italy, Spain, the U.K., Northern Ireland,
									Russia, Japan, South Korea, Denmark, Sweden, Norway, Finland
									and Belarus.&nbsp;
								</strong>
								Citizens of these countries are allowed to stay for 45 days from
								the time of entry, regardless of passport type and entry
								purpose.
							</p>
							<hr />
							<p>
								For trips less than 15 days, citizens of the United Kingdom can
								enter Vietnam without a visa. Upon their arrival, visa
								requirements state that a British traveler must have their UK
								passport on hand, and their passport must be valid for six
								months after their arrival date.
							</p>
							<p>
								For trips greater than 15 days, UK citizens will have to apply
								for a Vietnam visa. For many visa types, UK citizens can obtain
								a visa on arrival. However, they must first apply online for
								their visa on arrival to ensure a smooth entry into Vietnam.
							</p>
							<p>
								<strong>Applying for a Vietnam visa on arrival online</strong>
							</p>
							<p>
								British citizen visa requirements for Vietnam state that UK
								citizens who wish to stay for more than 15 days must obtain a
								visa. To apply online for a visa on arrival, UK travelers need
								to complete the following steps:
							</p>
							<ul>
								<li>
									Visit our{" "}
									<a href="javascript:void(0)">
										online Vietnam visa application for British Citizens
									</a>
									.
								</li>
								<li>
									Submit the online application form for your visa on arrival.
								</li>
								<li>
									After arriving at one of Vietnam’s visa on arrival airports,
									get your Vietnam visa stamped at the immigration counter.
								</li>
							</ul>
							<p>
								British travelers must arrive by air to receive a visa on
								arrival. Vietnam International airports that participate in the
								visa on arrival program include Ha Noi, Ho Chi Minh City, Nha
								Trang, and Da Nang.
							</p>
							<p>
								<strong>
									How to help ensure a successful Vietnam visa application{" "}
								</strong>
							</p>
							<p>
								When completing their online application for a Vietnam visa,
								British citizens can take several steps to help ensure that
								their application is successful. To help avoid visa denials,
								British citizens should follow the checklist below:
							</p>
							<ul>
								<li>
									Travelers must enter their name exactly how it appears on
									their passport. The order of each traveler’s first and last
									name does not matter. Any order is fine.
								</li>
								<li>
									Travelers must list the nationality of their passport that
									they use to enter Vietnam as the nationality on their
									application form.
								</li>
								<li>
									Travelers must enter their date of birth in the DD/MM/YY
									format.
								</li>
								<li>
									Travelers must enter their passport number exactly as it
									appears in their passport. Errors with the passport number
									frequently cause delays.
								</li>
								<li>
									Travelers must bring passport photos with them for their
									arrival in Vietnam.
								</li>
							</ul>
							<p>
								Also, on the visa on the arrival application form, UK travelers
								will need to submit their intended arrival and departure gates.
								Travelers should be aware of the impact of their submitted
								arrival and departure dates. While they can enter Vietnam after
								their listed arrival date, they must leave the country by their
								listed departure date. Also, the Vietnam visa cost UK citizens
								must pay varies with the type of visa the traveler needs.
							</p>
							<p>
								<strong>
									Travel ideas for UK citizens vacationing in Vietnam
								</strong>
							</p>
							<p>
								British travelers to Vietnam will quickly realize that there are
								many more places to see in Vietnam than they can get to in just
								15 days. To help plan a trip to Vietnam, check out our guides on
								popular Vietnam travel destinations, including{" "}
								<a href="javascript:void(0)">
									Ho Chi Minh City
								</a>{" "}
								and{" "}
								<a href="javascript:void(0)">
									Halong Bay
								</a>
								. Other popular Vietnam travel destinations include{" "}
								<a href="javascript:void(0)">
									Hoi An
								</a>{" "}
								and{" "}
								<a href="javascript:void(0)">
									Hoa Lu in Ninh Binh
								</a>
								.
							</p>
							<p>
								Also, traveling to Vietnam can be either inexpensive or
								extremely costly, depending on how a person wishes to travel. To
								get a better idea about the cost of a trip to Vietnam, check out
								our guide on{" "}
								<a href="javascript:void(0)">
									Vietnam vacation costs
								</a>
								. Budget travelers should check out our guide on the{" "}
								<a href="javascript:void(0)">
									seven best places to backpack in Vietnam.
								</a>
							</p>
							<p>
								<strong>
									Take your first step in applying for a Vietnam visa as a
									British citizen
								</strong>
							</p>
							<p>
								To apply for a visa, get started with our online{" "}
								<a href="javascript:void(0)">
									application form
								</a>
								. For further assistance, please contact us via email at
								info@Vietnamevisa.org or call our hotline at +84 832 320
								320 with any questions on applying for a Vietnam visa online. We
								look forward to having you in Vietnam!
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default BritishCitizens;
