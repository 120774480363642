import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function Embassy() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Vietnamese Embassy Information</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								Over the last decade, relations between Vietnam and all other
								countries in the world have developed quickly in many
								areas—especially in tourism. The relationship between Vietnam
								and all other countries in Asia, America, Africa, Australia, and
								Europe are considered to be of strategic importance to Vietnam.
								With the goal of establishing the best environment for
								foreigners to visit and do business in Vietnam, a Vietnam
								Embassy has been opened in almost all foreign countries. The
								procedure to apply for a Vietnam visa for travelers is actually
								fast and convenient.
							</p>
							<p>
								At a Vietnam Embassy, the visa procedure will take around five
								days to process. Visa fees will depend on the type of visa for
								which you are applying. Travelers can get the visa processed at
								any Vietnam Embassy in any country in order to enter Vietnam for
								tourism, business, or to visit relatives.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default Embassy;
