import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function VisasOnArrival() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Vietnamese Visas On Arrival</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								Vietnam Visa on Arrival is an online, alternative way to obtain
								a visa to visit Vietnam.
							</p>
							<p>
								After completing the online application form and paying the
								processing fee, you will receive, via email, your Approval
								Letter from between one hour to two business days, depending on
								the status of visa processing you have selected.
							</p>
							<p>
								Copies of your Approval Letter will also be forwarded on your
								behalf to Vietnam Immigration checkpoints at each of Vietnam’s
								three international airports in Hanoi, Ho Chi Minh City, or Da
								Nang (online visa approval only applies to air travelers who
								arrive at one of Vietnam’s international airports; travelers
								arriving by land or sea must apply for a visa through a Vietnam
								Embassy).
							</p>
							<p>
								When you arrive in Vietnam, the Immigration officers will have
								your documents ready and will be able to issue you your entry
								visa quickly.
							</p>
							<p>
								By applying at Vietnam Visa now, you will surely be granted
								entry into Vietnam with your Approval Letter.
							</p>
							<p>Steps to Obtaining a Business Visa</p>
							<ul>
								<li>
									List your name exactly as it is written in your passport. Name
									order is not important, so you can list your surname or given
									name first on the application.
								</li>
								<li>List your date of birth as DD/MM/YYYY</li>
								<li>
									List your nationality or passport information (citizens of
									some countries may make limited visits to Vietnam without a
									passport). Your passport must be valid for at least six months
									following your listed arrival date.
								</li>
								<li>
									List your passport number. Your passport must be valid for at
									least six months following your listed arrival date.
								</li>
								<li>
									Name your arrival date. You may enter the country after the
									listed arrival date but not before.
								</li>
							</ul>
							<p>
								<strong>Photos</strong>
								<br />
								Passport photos are required at Vietnam airports in order to
								obtain an entry visa. You can use our secure online form to
								submit your application, or find downloadable forms on this
								site.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default VisasOnArrival;
