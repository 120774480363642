import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import { FaWpforms, FaPlaneDeparture } from "react-icons/fa6";
import { ImCreditCard } from "react-icons/im";
import { IoPrintOutline } from "react-icons/io5";
import ScrollToTop from "../components/ScrollToTop";

function HowToApply() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>
				Vietnam Visa Requirements and Application Process – How to Apply
			</BannerLayout>
			<section className="p-0">
				<Container className="bg-white p-4">
					<Row>
						<Col md={12}>
							<p>
								Visiting Vietnam is an excellent option for people looking for a
								new experience. From sunny beaches to delicious cuisine and
								vibrant cultural attractions, Vietnam is a rewarding
								destination.
							</p>
							<p>
								Before traveling, it is important to know the Vietnam visa
								requirements. These steps will help you navigate the process.
							</p>
							<h2>Do I Need a Visa for Vietnam?</h2>
							<p>
								Most visitors will need a visa to enter Vietnam. People in many
								African countries and parts of Asia are{" "}
								<a href="/vietnam-visa/visa-fees/">
									exempt from Vietnam visa requirements
								</a>
								. Citizens of North American and European countries will need to
								obtain a visa.
							</p>
							<p>
								Why? Visas are required by most countries for citizens of other
								countries to enter. Some governments require visitors to obtain
								a visa prior to arriving, while others issue visas to people
								upon arrival. Vietnam visa requirements are actually a
								combination of these two approaches. Visitors must apply in
								advance for a visa approval letter, and then obtain the official
								visa stamp in their passports upon arrival in the country.
								Fortunately, the application for a visa approval letter can be
								completed and submitted online.
							</p>
							<h2>How to Do a Vietnam Visa Application Online</h2>
							<p>
								To make the process easier for you, Vietnam Visa is here to help
								you with the process of obtaining a visa for travel to Vietnam.
								Our online application is quick and easy, as well as fully
								supported by our knowledgeable staff. We recommend you choose to
								get a visa on arrival, as picking up your visa at all Vietnamese
								international airports is simple and convenient. Just follow the
								online process beforehand, and you will be ready to get your
								visa stamp in Vietnam.
							</p>
							<p>
								Here’s how to apply for a Vietnam Visa online in 4 easy steps:
							</p>
							<h2>
								Here’s how to apply for a Vietnam Visa online in 4 easy steps:
							</h2>
						</Col>
					</Row>
					<Row>
						<Col xs={6} md={3} className="text-center mt-4">
							<FaWpforms size={50} color="#0082dc"/>
							<h4>Step 1</h4>
							<p>Get started by filling out the secure online form</p>
						</Col>
						<Col xs={6} md={3} className="text-center mt-4">
							<ImCreditCard size={50} color="#0082dc"/>
							<h4>Step 2</h4>
							<p>Confirm and pay</p>
						</Col>
						<Col xs={6} md={3} className="text-center mt-4">
							<IoPrintOutline size={50} color="#0082dc"/>
							<h4>Step 3</h4>
							<p>Receive and print the Approval Letter</p>
						</Col>
						<Col xs={6} md={3} className="text-center mt-4">
							<FaPlaneDeparture size={50} color="#0082dc"/>
							<h4>Step 4</h4>
							<p>
								Get your visa stamp upon arrival at one of three Vietnam
								international airports
							</p>
						</Col>
					</Row>
					<Row>
						<Col md={12}>
							<h4>Step 1</h4>
							<p>
								Get started by filling out the{" "}
								<a href="/apply-online/">secure online form</a>. This step is
								necessary for obtaining an approval letter, which you will need
								to show upon arrival in Vietnam to get your visa stamp.
							</p>
							<p>
								Fill in the application using the correct information regarding
								your name, passport number, date of birth, nationality, gender,
								and arrival date. We accept most major credit cards: Visa,
								MasterCard, and American Express.
							</p>
							<h4>Step 2</h4>
							<p>
								Confirm and pay.{" "}
								<a href="/vietnam-visa/visa-fees/">Vietnam visa fees</a> are
								quite reasonable. For the visa approval letter, you pay a fee
								online, $17 and up, depending on the type of visa and how many
								you are requesting. You will also pay a fee upon arrival for the
								visa stamp. This will cost an additional $25 for a single entry
								visa and $50 for multiple entries. The stamping fee is paid in
								cash in either Vietnamese dong or US dollars, so be prepared to
								have the correct fee amount before you depart for Vietnam.
							</p>
							<p>
								Make sure your passport has plenty of blank pages for visa
								stamps before you leave. Also, be sure that your passport will
								have at least six months of validity remaining at the date of
								your arrival in Vietnam. If your passport is nearing its
								expiration date, get it renewed well before you leave.
							</p>
							<h4>Step 3</h4>
							<p>
								Receive and print the approval letter. As soon as you submit
								your application and payment, we will start processing your visa
								request. Once you have submitted your online application for a
								visa approval letter, you will typically receive the approval
								letter via email within 2 business days. It is very important
								that you print the letter, in color if possible, and bring it
								with you. It is a good idea to keep your visa approval letter
								with your passport, so you know where it is and can conveniently
								find it when you arrive at the airport in Vietnam.
							</p>
							<p>
								You will also need to prepare and bring at least two (4cm x 6cm)
								passport photos.
							</p>
							<h4>Step 4</h4>
							<p>
								Get your visa stamp upon arrival at one of four Vietnam
								international airports. Travelers arriving at airports in Hanoi,
								Da Nang, Ho Chi Minh City or Nha Trang, are required to complete
								an Entry/Exit form (available at the airports or by previous
								download from our website). You will then present your approval
								letter, passport, photos, and cash payment for the stamping fee
								in order to receive the visa stamp in your passport.
							</p>
							<p>
								Just follow the signs and instructions at the airport, and you
								will be ready for a rewarding visit.
							</p>
							<h2>We Are Here to Help You Prepare for a Great Visit</h2>
							<p>
								For further assistance, you can contact us at
								info@Vietnamevisa.org or through our hotline at (+84) 832
								320 320
							</p>
							<p>We guarantee our services, which include:</p>
							<ul>
								<li>Prompt support via email or phone</li>
								<li>Secure 128/256 bit SSL encryption</li>
								<li>Guaranteed 48-hour processing</li>
								<li>Special fee from $17 – no credit card surcharges</li>
								<li>Money-back guarantee for declined applications</li>
							</ul>
							<p>
								Travelers can also apply for visas to visit Vietnam through
								their local Vietnam Embassies. For further details on applying
								through an embassy, please visit Vietnam Embassies worldwide.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default HowToApply;
