import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function FAQs() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>
				How to Get a Visa for Vietnam: Frequently Asked Questions
			</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								Travellers often have many questions regarding their upcoming
								trips. Where will you stay? What are your plans upon arrival?
								People who are traveling outside of their home countries may
								also ask, “What about a visa?”
							</p>
							<p>
								Visas are often a necessary part of travelling internationally.
								The necessary steps to obtain a visa vary from country to
								country and depend on the traveller’s passport country. In the
								post below, we answer frequently asked questions regarding
								getting a visa to enter Vietnam. With the right guidance and
								planning, travellers can navigate the Vietnam visa process with
								ease.
							</p>
							<h3>
								<strong>&nbsp;1.&nbsp;</strong>
								<strong>What is a visa on arrival?</strong>
							</h3>
							<p>
								A{" "}
								<a href="javascript:void(0)">
									visa on arrival (VOA)
								</a>{" "}
								is a legal document approved by the Vietnam Department of
								Immigration. Some of the benefits of a VOA include:
							</p>
							<ul>
								<li>
									<strong>Legitimacy</strong>: The Vietnam Department of
									Immigration is the authority that grants and approves each
									VOA.
								</li>
								<li>
									<strong>Convenience</strong>: VOAs involve an easy online
									application and do not require you to send in your passport or
									passport photos before you travel. Also, VOA applications
									typically do not face delays.
								</li>
								<li>
									<strong>Cost-effectiveness</strong>: VOAs are often the lowest
									cost method for obtaining a Vietnam visa. This benefit is
									particularly important for budget travellers who wish to
									travel inexpensively.
								</li>
								<li>
									<strong>Speed</strong>: A traveller can complete a visa on
									arrival application within two working days. Often, if there
									is an emergency, the Department of Immigration may be able to
									grant a Vietnam visa within two to three hours. If you need an
									emergency visa on arrival, reach out to our team directly to
									discuss your emergency visa needs.
								</li>
							</ul>
							<h3>
								<strong>2. Are visas on arrival legitimate?</strong>
							</h3>
							<p>
								Yes, visas on arrival are legitimate. A Vietnam visa on arrival
								is a legitimate way to receive a visa to visit Vietnam, and in
								fact, visas on arrival are one of the most common ways that
								travellers to Vietnam source their visas. In addition to the
								legitimacy of VOAs, many people consider visas on arrival to be
								the most convenient, fastest, and affordable ways to obtain a
								Vietnam visa. In many cases, travellers can get entry visas as
								low as US $17 per visa, depending on a traveller’s length of
								stay and desired number of entries. The Vietnam Department of
								Immigration grants all types of visas on arrival. Travellers can
								then pick up their approved visas on arrival after landing at
								one of Vietnam’s international airports.
							</p>
							<h3>
								<strong>3.What is the visa approval letter?</strong>
							</h3>
							<p>
								The Vietnam Department of Immigration grants visa approval
								letters, which allow travellers to enter and exit Vietnam for a
								given period of time. With a visa approval letter, travellers
								can pick up your visa on arrival at one of the several
								international airports in Vietnam. These international airports
								include:
							</p>
							<ul>
								<li>Hanoi</li>
								<li>Da Nang</li>
								<li>Ho Chi Minh City</li>
								<li>Nha Trang</li>
							</ul>
							<p>
								At the airport, the entry authority will verify the details of
								the visa approval letter based on your passport and travel
								documents. As long as you have entered the correct information
								on your visa application, you should have no problems gaining
								entry into Vietnam with your visa approval letter. Once the
								immigration officer reviews your documents, the officer will
								stamp your Vietnam visa in your passport.
							</p>
							<h3>
								<strong>
									4.How long does it take to get the visa approval letter?
								</strong>
							</h3>
							<p>
								The Vietnam Department of Immigration processes visa approval
								letters and emails them to travellers typically within two
								business days of the traveller’s application. Processing days do
								not include Saturday, Sunday, and major national and
								international holidays. In some cases, travellers may be able to
								receive an expedited visa approval letter for an extra service
								fee. How much is a Vietnam visa on arrival with expedited
								processing? Expedited visa on arrival processing options
								include:
							</p>
							<ul>
								<li>
									Processing within four to eight hours for an additional $20
									per applicant.
								</li>
								<li>
									Processing within one business day for an additional $10 per
									applicant.
								</li>
								<li>
									Urgent processing on national or international holidays. For
									urgent processing, please call us or contact us via email for
									details and assistance
								</li>
							</ul>
							<p>
								Please take note that the above urgent processing times do not
								apply between 12:00 PM Friday and 9:00 AM Monday, local time in
								Vietnam. Please consider the time difference between Vietnam and
								your application location. However, note that visa desks at
								Vietnam’s international airports are open 24/7. As long as you
								have your visa approval letter, you will still be able to get
								your Vietnam visa on a weekend arrival.
							</p>
							<h3>
								<strong>5. How do I receive my visa approval letter?</strong>
							</h3>
							<p>
								Receiving your visa approval letter is easy and convenient. We
								send travellers a scanned file of their visa approval letters
								via email. So, please be sure to provide us with your correct
								email address. You will need the latest version of Adobe Acrobat
								Reader in order to open the file. Travellers will then need to
								print their visa approval letters and present their printed
								copies upon arriving into Vietnam.
							</p>
							<h3>
								<strong>
									6. Are there any exceptions for entry into Vietnam with a visa
									on arrival?
								</strong>
							</h3>
							<p>
								A visa on arrival is only valid for people who will arrive in
								Vietnam at one of Vietnam’s international airports. Travelers
								entering Vietnam by land or sea must visit a Vietnam Embassy to
								get their visa stamp. Vietnam immigration officials will accept
								visa approval letters at the following international airports in
								Vietnam:
							</p>
							<ul>
								<li>Noi Bai International Airport (Hanoi)</li>
								<li>Tan Son Nhat International Airport (Ho Chi Minh City)</li>
								<li>Danang Airport (Da Nang)</li>
								<li>Cam Ranh Airport (Nha Trang)</li>
							</ul>
							<p>
								There is a 15-day free visa exemption for some passport holders.
								The Vietnam Department of Immigraition offers this exemption to
								passport holders from the United Kingdom, France, Spain, Italy,
								and Germany. Travelers from those countries can travel to
								Vietnam without a visa for 15 days. Passport holders from Japan,
								South Korea, Philippines, and Chile can also enjoy visa exempt
								stay. The visa-exempt stay duration for travellers from those
								countries is between 14 to 90 days. Travellers who wish to
								obtain long-term Vietnam visas will need to apply for a visa on
								arrival or a visa from their country’s Vietnam embassy.
							</p>
							<h3>
								<strong>7. Do you require a scan of my passport?</strong>
							</h3>
							<p>
								We do not require passport scans for online visa applications.
								However, it is often a good idea for travellers to carry a copy
								of their passports with them during their travels. If travellers
								lose their passports, a passport copy may come in handy.
							</p>
							<h3>
								<strong>
									8. Do I need to provide a photo for my Vietnam visa? If so,
									what size photo do I need?
								</strong>
							</h3>
							<p>
								Yes, travellers need to provide photos for their visas on
								arrival. Travellers must bring at least two standard passport
								photos. The required photo size is 4cm x 6cm. Please note that
								if you do not have time to prepare these photos before your
								departure, you can have the photo vendors take your picture at
								the airport in Vietnam for about US $2 per photo. Of course,
								travellers will also need to provide photo ID upon arrival to
								Vietnam.
							</p>
							<h3>
								<strong>9. What if my passport has expired?</strong>
							</h3>
							<p>
								To apply for a visa to visit Vietnam, your passport must be
								valid for at least six months following your intended date of
								arrival. Therefore, if your passport is about to expire, please
								renew it before applying for a visa to Vietnam in order to avoid
								unwanted issues at the airport.
							</p>
							<h3>
								<strong>
									10. Do I need to provide the exact date of my arrival on my
									visa application? What if I am unsure when I will arrive?
								</strong>
							</h3>
							<p>
								Travellers do need to provide a date of arrival. The arrival
								date you provide should be the earliest you plan to arrive. If
								your plans change and you arrive after the arrival date you
								provided, that is fine. However, you can not arrive before the
								listed arrival date. Also, the departure date you provide on
								your application is the last day that you can stay in Vietnam.
								If you wish to extend your stay, you must apply for a visa
								extension. For example, if you apply for a one-month visa and
								submit your arrival date as January 1<sup>st</sup>, 2021, your
								visa approval letter will be valid from January 1<sup>st</sup>{" "}
								to February 1<sup>st</sup>, 2021. You can enter the country at
								any time during January, but you must leave on or before
								February 1<sup>st</sup>.
							</p>
							<h3>
								<strong>11. What is the stamping fee?</strong>
							</h3>
							<p>
								The visa stamping fee is the fee that travellers need to pay at
								a Vietnam airport to get the official Vietnam visa stamped in
								their passports. The stamping fee is $25 USD for a single-entry
								visa between one to three months, and the stamping fee is $50
								USD for a multiple-entry visa between one to three months. Note
								that travellers must pay the stamping fee in cash using either
								Vietnam dong or U.S. dollars. The immigration officials at the
								airport do not accept credit cards, and it may be difficult to
								find an ATM.
							</p>
							<h3>
								<strong>
									12. Can I travel without a visa approval letter and still get
									a visa when I arrive in Vietnam?
								</strong>
							</h3>
							<p>
								No, you cannot. In order to board the plane, you must show your
								visa approval letter to the airline representatives. We strongly
								recommend that you apply for your Vietnam visa before you leave
								for Vietnam in order to avoid a delay at the airport or a need
								to change your flight.<strong>&nbsp;</strong>
							</p>
							<h3>
								<strong>
									13. What if I change my flight and arrive at another airport
									in Vietnam?
								</strong>
							</h3>
							<p>
								A Vietnam visa on arrival works at any of Vietnam’s four
								international airports, including:
							</p>
							<ul>
								<li>Tan Son Nhat (Ho Chi Minh City)</li>
								<li>Noi Bai International Airport (Hanoi)</li>
								<li>Danang Airport (Da Nang)</li>
								<li>Cam Ranh Airport (Nha Trang)</li>
							</ul>
							<p>
								If you change your flight to any of the above airports, you
								should not expect any problems obtaining your visa. However,
								note that your date of entry in Vietnam needs to be after the
								arrival date and before the departure date that you listed on
								your visa on arrival application form.
							</p>
							<p>
								We want the process of obtaining your Vietnam visa to go
								smoothly. For additional assistance, please contact our team at
								Vietnam Visa. You can reach us by email at{" "}
								<a href="mailto:info@Vietnamevisa.org">
									info@Vietnamevisa.org
								</a>
								, or you can call us on our hotline at (+84) 832-320-320.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default FAQs;
