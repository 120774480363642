import { Button, Col, Container, Form, Row } from "react-bootstrap";
import BannerLayout from "../components/BannerLayout";
import MainLayout from "../components/MainLayout";
import ScrollToTop from "../components/ScrollToTop";
import { useState } from "react";

export default function Contacts() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: [e.target.value] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <MainLayout>
        <ScrollToTop />
        <BannerLayout>Contact Us</BannerLayout>
        <section>
          <Container>
            <Row>
              <Col md={8}>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={12} className="mb-4">
                      <Form.Group>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder="Enter Name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                        {/* <Form.Text className="text-muted">
													<span className="text-danger">
														Please Enter Your Name
													</span>
												</Form.Text> */}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-4">
                      <Form.Group>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder="Enter Email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                        {/* <Form.Text className="text-muted ">
													<span className="text-danger">
														Please Enter Your Email
													</span>
												</Form.Text> */}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-4">
                      <Form.Group>
                        <Form.Control
                          size="md"
                          as="textarea"
                          placeholder="Add Message"
                          style={{ height: "120px" }}
                          value={formData.message}
                          onChange={handleChange}
                        />
                        {/* <Form.Text className="text-muted">
													<span className="text-danger">
														Enter your Message
													</span>
												</Form.Text> */}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="button_global border-0"
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={4}>
                <aside className="bg-white rounded-2 text-center border p-4 shadow-sm">
                  <h4
                    className="p-3 mb-3 m-0 rounded-2 text-white"
                    style={{ backgroundColor: "#0082dc" }}
                  >
                    {" "}
                    Vietnam Visa
                  </h4>
                  <ul className="list-none text-start p-0 d-flex flex-column gap-3">
                    <li>
                      <div>
                        <b>
                          <a href="tel:(+84) 832 320 320" title="Call us">
                            (+84) 832 320 320
                          </a>
                        </b>
                        <br />
                        <span>8.00 AM-21.00 PM, GMT +7</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <b>
                          <a href="mailto:info@Vietnamevisa.org">
                            info@Vietnamevisa.org
                          </a>
                        </b>
                      </div>
                    </li>
                    <li>
                      <p>
                        BDA Building, Lo E50 Khu 3Ha, Phuc Dien, Bac Tu Liem,
                        Hanoi, Vietnam
                      </p>
                    </li>
                  </ul>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14892.959152179325!2d105.784222!3d21.063083!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31345528d3ec753d%3A0xdf00132a25f8776!2sPhuong%20Dong%20General%20Hospital!5e0!3m2!1sen!2sus!4v1700813138803!5m2!1sen!2sus"
                    width="100%"
                    title="googleaddress"
                    height="200"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    className="lh-0"
                  ></iframe>
                </aside>
              </Col>
            </Row>
          </Container>
        </section>
      </MainLayout>
    </>
  );
}
