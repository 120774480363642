import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import PagesLayout from "../components/PagesLayout";

function TermsAndConditions() {
  return (
    <PagesLayout>
      <Row>
        <Col md={12}>
          <h2>Terms and Conditions</h2>
          <p>
            Welcome to eVisa Vietnam. By accessing or using our website, you
            agree to comply with and be bound by the following terms and
            conditions. Please review them carefully before using our services.
          </p>
          <h4>1. Acceptance of Terms</h4>
          <p>
            By accessing our website, you acknowledge that you have read,
            understood, and agree to be bound by these Terms and Conditions. If
            you do not agree to these terms, you should not use our website.
          </p>
          <h4>2. Services</h4>
          <p>
            eVisa Vietnam provides online visa application services for
            individuals seeking to travel to Vietnam. Our services include but
            are not limited to providing information, submitting visa
            applications, and facilitating communication with relevant
            authorities.
          </p>
          <h4>3. Eligibility</h4>
          <p>
            To use our services, you must be at least 18 years old and possess
            the legal authority to enter into a binding agreement. By using our
            website, you represent and warrant that you meet these eligibility
            requirements.
          </p>
          <h4>4. User Responsibilities</h4>
          <p>
            You agree to provide accurate, current, and complete information
            during the registration and application process. You are responsible
            for maintaining the confidentiality of your account information and
            for all activities that occur under your account.
          </p>
          <h4>5. Fees and Payments</h4>
          <p>
            All fees for our services are clearly listed on our website.
            Payments must be made in full at the time of application submission.
            We accept various payment methods, including credit cards and other
            online payment systems. All payments are non-refundable unless
            otherwise stated.
          </p>
          <h4>6. Processing Time</h4>
          <p>
            Processing times for visa applications are estimates and are not
            guaranteed. eVisa Vietnam is not responsible for delays caused by
            third-party entities, including government authorities.
          </p>
          <h4>7. Accuracy of Information</h4>
          <p>
            While we strive to provide accurate and up-to-date information, we
            do not warrant the accuracy, completeness, or reliability of any
            information on our website. Users are advised to verify information
            independently.
          </p>
          <h4>8. Intellectual Property</h4>
          <p>
            All content on our website, including text, graphics, logos, and
            software, is the property of eVisa Vietnam or its content suppliers
            and is protected by intellectual property laws. Unauthorized use of
            any content is strictly prohibited.
          </p>
          <h4>9. Limitation of Liability</h4>
          <p>
            eVisa Vietnam is not liable for any direct, indirect, incidental, or
            consequential damages arising out of or in connection with your use
            of our website or services. This includes, but is not limited to,
            damages for loss of profits, data, or other intangibles.
          </p>
          <h4>10. Indemnification</h4>
          <p>
            You agree to indemnify and hold eVisa Vietnam, its affiliates, and
            their respective officers, directors, employees, and agents harmless
            from any claims, liabilities, damages, losses, and expenses,
            including legal fees, arising out of or in connection with your use
            of our website or services.
          </p>
          <h4>11. Termination</h4>
          <p>
            We reserve the right to terminate or suspend your access to our
            website and services, without notice, for conduct that we believe
            violates these Terms and Conditions or is harmful to other users of
            the website, us, or third parties.
          </p>
          <h4>12. Governing Law</h4>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of Vietnam. Any disputes arising out of or
            in connection with these terms shall be subject to the exclusive
            jurisdiction of the courts of Vietnam.
          </p>
          <h4>13. Changes to Terms and Conditions</h4>
          <p>
            We reserve the right to modify these Terms and Conditions at any
            time. Changes will be effective immediately upon posting on our
            website. Your continued use of our website and services after any
            changes constitutes your acceptance of the new terms.
          </p>
          <h4>14. Links</h4>
          <p>
            This website contains links to third-party websites. These links are
            provided exclusively for information purposes and to assist in
            locating other Internet resources. We are not responsible for the
            content, accuracy of information, expressed opinions, or statements,
            of any third-party sites linked to us or any links contained in a
            linked site.
          </p>
        </Col>
      </Row>
    </PagesLayout>
  );
}

export default TermsAndConditions;
