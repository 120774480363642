import { Col, Row } from "react-bootstrap";
import PagesLayout from "../components/PagesLayout";

export default function AboutUs() {
  return (
    <PagesLayout>
      <Row>
        <Col md={12}>
          <h2>About Us</h2>
          <p class="fs-5">
            <strong>Welcome to Vietnam Visa</strong>, your premier destination
            for hassle-free and efficient visa application services for Vietnam.
            Please note that we are a commercial website and are not affiliated
            with the Embassy, Consulate, High Commission, or any{" "}
            <strong>Government Department of Vietnam</strong>.<br />
            <br />
            At Vietnam Visa, we offer comprehensive support and consultation for
            your <strong>Vietnam Visa applications</strong>. Our experienced
            team will guide you through the entire process and ensure that all
            required documents are in order. By choosing our services, you can
            expect regular updates on the status of your
            <strong> eVisa Vietnam </strong>application and conveniently receive
            the eVisa result from us.
            <br />
            <br />
            To apply for an e-Visa through our processing, there are two types
            of fees involved. Firstly, a service fee will be charged by us for
            our professional assistance and dedicated support throughout the
            application process. Secondly, the Vietnam Government Fee, which is
            a mandatory fee set by the government, will also be applicable. It
            is important to note that our service fee may be higher than the
            fees charged if you choose to apply directly through the{" "}
            <strong>Official Vietnam Government Website</strong>. However, if
            you prefer a non-guided service and wish to apply independently, you
            have the option to visit the{" "}
            <strong>Vietnam Government website</strong>. They provide the
            necessary information and application forms for you to apply
            directly. This may be a suitable choice for individuals who are
            comfortable navigating the visa application process on their own.
            <br />
            <br />
            We strive to provide a convenient and reliable service to make your
            Vietnam visa application as smooth as possible. Whether you choose
            to apply through us or opt for an alternative method, our goal is to
            assist you in obtaining your Vietnam Visa efficiently and with ease.
            <br />
            <br />
            Please feel free to reach out to our support team if you have any
            questions or require further assistance. We are here to ensure that
            your journey to Vietnam is stress-free and enjoyable.
          </p>
        </Col>
      </Row>
    </PagesLayout>
  );
}
