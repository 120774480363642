import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import classes from "./MainLayout.module.css";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import { useScroll } from "framer-motion";

function Header() {
  const { scrollY } = useScroll();
  const [scrollPos, setScrollPos] = useState({
    y: 0,
    prevY: -1,
    directionDown: true,
  });

  useEffect(() => {
    let lastScrollTop = 0;
    scrollY.onChange((pos) => {
      if (pos > lastScrollTop) {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: true,
        });
      } else {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: false,
        });
      }

      if (pos === 0) {
        setScrollPos({ ...scrollPos, directionDown: true });
      }
      lastScrollTop = pos;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <header
      className={`${scrollPos.y > 5 ? " sticky-header" : ""}${
        scrollPos.directionDown === false ? " header-appear" : ""
      }`}
    >
      <div className={`${classes.topBar} top`}>
        <Container>
          <Row className="align-items-center">
            <Col className={classes.logo} md={4} xs={8}>
              <h2 className={`m-0`}>Vietnam Visa</h2>
              <p className={`m-0`}>The Best Way To Obtain a Vietnam Visa</p>
            </Col>
            <Col md={8} xs={4}>
              <ul className={classes.contacts}>
                {/* <li>
									<a href="tel:(+84) 832 320 320" title="Call us">
										<BiSolidPhoneCall />
									</a>
									<div>
										<b>
											<a href="tel:(+84) 832 320 320" title="Call us">
												(+84) 832 320 320
											</a>
										</b>
										<span>8.00 AM-21.00 PM, GMT +7</span>
									</div>
								</li> */}
                <li>
                  <a href="mailto:info@vietnamvisa-vn.org">
                    <IoIosMail />
                  </a>
                  <div>
                    <b>
                      <a href="mailto:info@vietnamvisa-vn.org">
                        info@vietnamvisa-vn.org
                      </a>
                    </b>
                    <span>Vietnam Visa Support Email</span>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={classes.bottomBar}>
        <Container>
          <Navbar expand="lg">
            <div className={classes.mobile_applynowbtn}>
              <Link to="/apply-online">Apply Now</Link>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
            </div>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={`w-100`}>
                <Nav.Item>
                  <NavLink to="/">Home</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/how-to-apply">How to Apply</NavLink>
                </Nav.Item>
                <Nav.Item className="d-md-none d-block">
                  <NavLink to="/apply-online">Apply Online</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/visa-fees">Visa Fees</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/about-us">About Us</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/faqs">FAQ's</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/government-policies">
                    Policies & Procedures
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/embassy">Embassy</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/visa-on-arrival">Visas on Arrival</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/travel-tips">Travel Tips</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink to="/apply-online" className={classes.applynow__btn}>
                    Apply Now
                  </NavLink>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </header>
  );
}

export default Header;
