import axios from "axios";
import { userService } from "../service";

export const payment = {
  displayRazorpay,
};
async function displayRazorpay(params, callback) {
  const data = await axios({
    method: "post",
    url: `${process.env.REACT_APP_BASE_URL}payment/razorpay`, //${config.baseUrl}RazorPay/RazorPayPaymentTicket
    data: params,
  });
  const options = {
    key: process.env.REACT_APP_PAYMENT_API_KEY, //process.env.PRODUCTION_KEY,
    currency: data.data.currency, //data.currency
    amount: parseInt(data.data.amount),
    order_id: data.data.id,
    name: "Vietnam",
    // description: params.temporary_id,
    description: 2,
    image: "",
    handler: async function (response) {
      let param;
      if (
        typeof response.razorpay_payment_id == "undefined" ||
        response.razorpay_payment_id < 1
      ) {
        param = {
          order_id: data.id,
          status: null,
          payment_id: response.error.metadata.payment_id,
          order_status: "Failed",
          tran_id: null,
          temporary_id: params.temporary_id,
          pay_id: response.error.metadata.payment_id,
          payment_method: "razorpay",
          payment_status: response.error.reason,
          amount: parseInt(data.data.amount / 100),
          currency: data.currency,
          payer_email: params.email,
          payer_name: params.surname,
          passport_no: params.passport_no,
          ip_address: data.data.ip_address,
          country_nationality: data.data.country_nationality,
          intended_date_of_entry: data.data.intended_date_of_entry,
          visa_type: data.data.visa_type,
        };
      } else {
        param = {
          passport_no: params.passport_no,
          order_id: data.id,
          payment_id: response.razorpay_payment_id,
          order_status: "Confirmed",
          temporary_id: params.temporary_id,
          pay_id: response.razorpay_payment_id,
          payment_method: "razorpay",
          payment_status: "success",
          amount: parseInt(data.data.amount / 100),
          currency: data.currency,
          payer_email: params.email,
          payer_name: params.surname,
          ip_address: data.data.ip_address,
          country_nationality: data.data.nationality,
          intended_date_of_entry: data.data.intended_date_of_entry,
          visa_type: data.data.visa_type,
        };
      }
      let statusResponse = await userService.post(
        "payment/updatePayment",
        param
      );
      if (statusResponse.status === 200) {
        if (typeof callback === "function") {
          callback(param);
        }
      } else {
        // showErrorToast(statusResponse.data.msg);
      }
    },
    modal: {
      ondismiss: function () {
        "";
      },
    },
    prefill: {
      name: params.surname,
      email: params.email,
      phone_number: params.telephone_number,
    },
  };
  const paymentObject = new window.Razorpay(options);

  paymentObject.on("payment.failed", async function (response) {});
  paymentObject.open();
}
