import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import FooterNew from "./FooterNew";

function MainLayout({ children }) {
  const path = window.location.pathname;
  console.log(path);
  return (
    <>
      <Header />
      <main className="main-top-margin">{children}</main>
      {path === "/apply-online" || path === '/preview' ? "" : <FooterNew />}
    </>
  );
}

export default MainLayout;
