import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function NewZealandCitizens() {
  return (
    <MainLayout>
      <ScrollToTop />
      <BannerLayout>Vietnam Visas for Citizens of New Zealand</BannerLayout>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <p>
                New Zealand citizens who wish to travel to Vietnam must have a
                visa to enter the country. Fortunately, getting a Vietnam visa
                is easy for New Zealanders. Since 1966, New Zealand citizens can
                get a visa on arrival at one of Vietnam’s international
                airports. Participating airports for visas on arrival include Ha
                Noi, Da Nang, Ho Chi Minh City, and Nha Trang.{" "}
              </p>
              <p>
                <strong>How can a New Zealand citizen apply for a visa</strong>
              </p>
              <p>
                To get a visa on arrival, New Zealand citizens need to complete
                several online steps before their trip. To apply for a visa on
                arrival, New Zealanders must follow the steps below:
              </p>
              <ol>
                <li>
                  Visit out website at{" "}
                  <a href="javascript:void(0)">
                    www.vietnamvisa.govt.vn
                  </a>
                  .{" "}
                </li>
                <li>Submit a visa application form online.</li>
                <li>Receive an approval letter for a visa on arrival.</li>
              </ol>
              <p>
                After completing the steps above, a New Zealand citizen can
                arrive in Vietnam anytime after their submitted arrival date and
                before their submitted departure date. Also, travelers from New
                Zealand should note that they can only get their visa on arrival
                when traveling to Vietnam by air. Vietnam visa costs for New
                Zealand citizens will vary by visa type.{" "}
              </p>
              <p>
                <strong>
                  Points for New Zealand citizens to consider when applying for
                  a Vietnam Visa
                </strong>
              </p>
              <p>
                To help New Zealand citizens avoid common problems with their
                visa application, we have compiled a checklist below. Travelers
                from New Zealand who follow the checklist should not have
                trouble getting an approval letter for their Vietnam visa on
                arrival. When applying for a Vietnam visa, New Zealand citizens
                should do the following:
              </p>
              <ul>
                <li>
                  Enter their full name exactly as it is in their passport. The
                  order of the traveler’s first name and last name is not
                  important, so long as it is correct.
                </li>
                <li>
                  When entering the traveler’s date of birth, the date format
                  must be DD/MM/YY.
                </li>
                <li>
                  If the traveler has multiple nationalities, they should list
                  the nationality of the passport they are using to enter
                  Vietnam.
                </li>
                <li>
                  Enter the date of arrival associated with their flights.
                  Travelers should know that they can enter Vietnam after their
                  submitted date of arrival but not before.
                </li>
                <li>Bring passport-size photos with them.</li>
              </ul>
              <p>
                Travelers should also make sure that their New Zealand passport
                has unused pages and is valid for at least six months after
                their intended arrival date.
              </p>
              <p>
                <strong>
                  Travel ideas for New Zealand citizens in Vietnam
                </strong>
              </p>
              <p>
                Vietnam is an ideal travel location for New Zealanders. Vietnam
                is relatively close compared with the rest of the world, and the
                country offers a vibrant culture for New Zealand travelers to
                enjoy. Popular attractions in Vietnam include{" "}
                <a href="javascript:void(0)">
                  Phong Nha-Ke Bang National Park
                </a>{" "}
                and{" "}
                <a href="javascript:void(0)">
                  Ha Long Bay
                </a>
                . When planning your trip, take a look at our guide on the{" "}
                <a href="javascript:void(0)">
                  best times to visit Vietnam by region
                </a>
                . Travelers also enjoy{" "}
                <a href="javascript:void(0)">
                  Vietnam’s famous cuisine
                </a>{" "}
                year-round.
              </p>
              <p>
                While there are no direct flights between New Zealand and
                Vietnam, there are several flights between the two countries
                with just one stop. Most routes from New Zealand to Vietnam
                include a stop in Australia, and carriers include Air New
                Zealand, Qantas, and Jetstar. Of course, fares vary, but most
                tickets are around NZD 1000.{" "}
              </p>
              <p>
                Traveling in Vietnam is possible on a budget, and if travelers
                wish, they can also find luxury accommodations in Vietnam. To
                learn more about the cost of traveling to Vietnam, check out our
                guide on{" "}
                <a href="javascript:void(0)">
                  Vietnam vacation costs
                </a>
                . Also, if backpacking may be right for you, take a look at our
                article on the{" "}
                <a href="javascript:void(0)">
                  seven best places to backpack in Vietnam
                </a>
                .{" "}
              </p>
              <p>
                <strong>
                  Get started with your Vietnam visa application for New Zealand
                  citizens
                </strong>
              </p>
              <p>
                Getting started with a Vietnam visa application is easy. Simply
                visit our Vietnam visa online form and follow the steps on the
                page. For further assistance, please email us at
                info@Vietnamevisa.org or call our hotline at +84 832 320
                320. We’re here to help!
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}

export default NewZealandCitizens;
