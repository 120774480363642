import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";

function NotFound() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Page NOT FOUND</BannerLayout>
			<section>
				<Container className="text-center">
					<Row className="justify-content-center">
						<Col md={6}>
							<img
								src={require("../assets/img/404-error.png")}
								className="w-50"
								alt="not found"
							/>
							<br />
							<Link
								to="/"
								className="button_global d-inline-block mt-4
							"
							>
								Go back to home
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default NotFound;
