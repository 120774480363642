import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function PaymentGuidelines() {
	return (
		<MainLayout>
      <ScrollToTop />
			<BannerLayout>Payment Guidelines</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<section class="entry-content">
								<p>We offer you two secured different payment methods:</p>
								<p>
									<strong>1. Credit Card by OnePay Security System</strong>
								</p>
								<p>
									We accept all major credit Cards. After filling in the
									application for <a href="/">Vietnam Visa</a>, you will be
									directed to payment system by Verisign and Verified by Visa
									Inc. Your personal and financial information is safe with us.
									We are dedicated to keep the information you submit secure and
									private by using secure servers and encryption. When you book
									with us, you will be linked from our web pages to customized
									screens on secure server. Your information is encrypted during
									its trips across the internet to prohibit viewing by third
									party
								</p>
								<hr />
								<p>
									<strong>2. Western Union</strong>
								</p>
								<p>
									We can accept payment through any bank worldwide using Western
									Union service. We will send you name of receiver then you need
									to provide us the money transfer control number (MTCN) in
									order to finalize the receipt of payment.
								</p>
								<p>
									<em>– Why was my payment rejected?</em>
								</p>
								<p>There are many reasons for failed transaction:</p>
								<ul>
									<li>
										Not register online services at bank for online payment.
									</li>
									<li>
										Insufficient fund &nbsp;for payment (daily or monthly) or
										incorrect card number.
									</li>
									<li>
										For Visa, Master Card: During the payment flow, you are
										asked to enter password of Verified by Visa or MasterCard
										Secure Code program, but you did not complete this
										authentication step.You should contact Issuer bank (Phone
										number displayed on the back of card) to help you solve
										this.
									</li>
								</ul>
								<p>
									<em>
										– What is Verified by Visa (VbV) or MasterCard Secure Code
										(MSC)?
									</em>
								</p>
								<p>
									VbV or MSC &nbsp;is a security program which ensures that
									payments using certain credit and debit cards are
									authenticated by the card holder with their bank at the time
									of the transaction. . If your card is enrolled with VbV or
									MSC, during the payment flow you will be asked to enter a
									password to verify that the transaction is authorized by the
									card owner before completion.
								</p>
								<p>
									The password is issued by your bank to secure transactions via
									the internet. After submitting your card details at checkout,
									a new window will appear asking you to enter your password.
								</p>
								<p>
									If there is any payment error, please email us:
									info@Vietnamevisa.org or call our hotline: (+84) 832 320
									320.
								</p>
							</section>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default PaymentGuidelines;
