import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function UnitedStatesCitizens() {
	return (
		<MainLayout>
      <ScrollToTop />
			<BannerLayout>Do Americans Need a Visa For Vietnam?</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={12}>
							<p>
								United States citizens need a visa to visit Vietnam. Since 1996,
								US citizens flying into Vietnam can pick up their Vietnam visa
								upon arrival to one of Vietnam’s international airports. To
								streamline the process, Americans traveling to Vietnam should
								complete their Vietnam visa application online before their
								trip.
							</p>
							<p>
								To apply for a Vietnam visa online, American travelers must
								complete the steps below:
							</p>
							<ul>
								<li>
									Submit an
									<a
										href="https://vietnamvisa.govt.vn/"
										rel="noopener noreferrer"
										target="_blank"
									>
										online Vietnam visa application form for US citizens
									</a>
									.{" "}
								</li>
								<li>Receive a Vietnam Visa Approval Letter from our team.</li>
								<li>
									Get a Vietnam visa stamp upon arrival at one of Vietnam’s
									several international airports (Ha Noi/ Da Nang/ Ho Chi Minh
									city/ Nha Trang).
								</li>
							</ul>
							<hr />
							<p>
								<strong>Notes on Vietnam Visas for Americans</strong>
							</p>
							<p>
								To get a Vietnam visa, US citizens must have a passport that is
								valid for six months after their Vietnam arrival date. Also,
								know that American travellers can only get their Vietnam visa on
								arrival when arriving to Vietnam by air.
							</p>
							<p>
								As an American traveler to Vietnam, you will need to provide the
								following information in your Vietnam visa application:
							</p>
							<ul>
								<li>
									Your full name, exactly how it appears on your passport (You
									can place your last name and first name in any order.)
								</li>
								<li>
									Your date of birth (Please provide your date of birth in
									DD/MM/YY format.)
								</li>
								<li>
									Your nationality as listed in your passport (If you are a US
									citizen with multiple passports, be sure to use the
									nationality of the passport that you use in your Vietnam visa
									application.)
								</li>
								<li>
									Your passport number (The passport number that you provide
									must match the number in your passport exactly. Also, make
									sure that your passport is valid for at least six months after
									your date of arrival into Vietnam.)
								</li>
								<li>
									Date of arrival (American travelers to Vietnam can arrive on
									or after their listed date of arrival.)
								</li>
								<li>
									Passport photos (United States citizens traveling to Vietnam
									must provide passport photos if they arrive to Vietnam by air
									travel.){" "}
								</li>
							</ul>
							<p>
								To get started, visit our{" "}
								<a
									href="https://vietnamvisa.govt.vn/"
									rel="noopener noreferrer"
									target="_blank"
								>
									online Vietnam visa application for American citizens
								</a>
								. If you need assistance, please email us at
								info@Vietnamevisa.org or call our hotline at +84 832 320
								320.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default UnitedStatesCitizens;
