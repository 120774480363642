import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";

function TravelGuides() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Travel Guides</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={4}>
							<div className="bg-light p-3 rounded-2">
								<img
									src={require("../assets/img/map.gif")}
									alt=""
									className="w-100"
								/>
								<h4>Vietnam: Overview and History</h4>
								<p>
									Throughout the millennia of Vietnam’s storied past, the
									indomitable spirit of the Vietnamese people has built the
									remarkable culture and traditions that are so enticing to
									today’s visitors. Vietnam’s fast-growing tourism industry
									could survive on its beautiful landscapes and exotic flora and
									fauna alone, but your experience in Vietnam will be made far
									richer and more vibrant as you learn about the people, the
									customs, the food, and the way of life that native Vietnamese
								</p>
								<Link to="/" className="button_global">Read more</Link>
							</div>
						</Col>
						<Col md={4}>
							<div className="bg-light p-3 rounded-2">
								<img
									src={require("../assets/img/map.gif")}
									alt=""
									className="w-100"
								/>
								<h4>Vietnam: Overview and History</h4>
								<p>
									Throughout the millennia of Vietnam’s storied past, the
									indomitable spirit of the Vietnamese people has built the
									remarkable culture and traditions that are so enticing to
									today’s visitors. Vietnam’s fast-growing tourism industry
									could survive on its beautiful landscapes and exotic flora and
									fauna alone, but your experience in Vietnam will be made far
									richer and more vibrant as you learn about the people, the
									customs, the food, and the way of life that native Vietnamese
								</p>
								<Link to="/" className="button_global">Read more</Link>
							</div>
						</Col>
						<Col md={4}>
							<div className="bg-light p-3 rounded-2">
								<img
									src={require("../assets/img/map.gif")}
									alt=""
									className="w-100"
								/>
								<h4>Vietnam: Overview and History</h4>
								<p>
									Throughout the millennia of Vietnam’s storied past, the
									indomitable spirit of the Vietnamese people has built the
									remarkable culture and traditions that are so enticing to
									today’s visitors. Vietnam’s fast-growing tourism industry
									could survive on its beautiful landscapes and exotic flora and
									fauna alone, but your experience in Vietnam will be made far
									richer and more vibrant as you learn about the people, the
									customs, the food, and the way of life that native Vietnamese
								</p>
								<Link to="/" className="button_global">Read more</Link>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default TravelGuides;
