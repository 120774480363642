import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "./MainLayout.module.css";
import { Link } from "react-router-dom";

function FooterNew() {
  const currentDate = new Date();

  return (
    <>
      <div className={classes.footerNew}>
        <Container>
          <Row className="justify-content-center">
            <Col md={12} className="">
              <Row>
                <Col md={6} className="mt-3">
                  <div className={classes.disclaimer}>
                    <strong>Disclaimers:</strong> vietnamvisa-vn.org is
                    e-commercial/non-government website. We provide visa
                    approval letter service which is officially approved by
                    Immigration Department.
                  </div>
                </Col>
                <Col lg={6} md={6} className="mt-3">
                  <div className={classes.card_wrap}>
                    <ul className={classes.cards}>
                      <li>
                        <img
                          src={require("../assets/img/cards/1.jpg")}
                          alt="visa"
                        />
                      </li>
                      <li>
                        <img
                          src={require("../assets/img/cards/2.jpg")}
                          alt="master card"
                        />
                      </li>
                      <li>
                        <img
                          src={require("../assets/img/cards/3.jpg")}
                          alt="american express"
                        />
                      </li>
                      <li>
                        <img
                          src={require("../assets/img/cards/4.jpg")}
                          alt="jcb"
                        />
                      </li>
                      <li>
                        <img
                          src={require("../assets/img/cards/5.jpg")}
                          alt="one pay"
                        />
                      </li>
                      <li>
                        <img
                          src={require("../assets/img/cards/6.jpg")}
                          alt="dmca"
                        />
                      </li>
                      <li>
                        <img
                          src={require("../assets/img/cards/7.jpg")}
                          alt="combo secure"
                        />
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center border-top mt-3 pt-2 pb-3">
                <Col
                  md={8}
                  lg={8}
                  className="mt-2 d-flex flex-column align-items-start"
                >
                  <ul className={classes.Links_list}>
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>

                    <li>
                      <Link to="/history">History</Link>
                    </li>
                    <li>
                      <Link to="/payment-guidelines">Payment Guidelines</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to="/travel-tips">Visa Tips</Link>
                    </li>
                    {/* <li>
                          <Link to="/sitemap">Sitemap</Link>
                        </li>
                        <li>
                          <Link to="/blog">Blog</Link>
                        </li> */}
                  </ul>
                </Col>
                <Col lg={4} md={4} className="text-md-end text-center">
                  © {currentDate.getFullYear()} Vietnam Visa. All Rights
                  Reserved
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default FooterNew;
