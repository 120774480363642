import { Col, Container, Row } from "react-bootstrap";
import MainLayout from "./MainLayout";
import ScrollToTop from "./ScrollToTop";
import { Link } from "react-router-dom";
import InnerLayout from "./InnerLayout";

export default function PagesLayout({ children }) {
  return (
    <MainLayout>
      <div
        className=" p-md-5 border"
        style={{ backgroundColor: "#2e65a8", marginTop: "-10px" }}
      >
        <ScrollToTop />
        <section className="p-0">
          <Container className="bg-white p-4 mb-0 rounded-3 pt-0">
            <Row className="p-2 pt-4">
              <Col md={12}>
                <div className="homebanner mt-2"></div>
              </Col>
              <Col md={4} className="mt-3">
                <Link to="/apply-online" className="labelbuttons first">
                  <h4 className="m-0">
                    Apply Vientam Visa <br />
                    Get Tourist eVisa in One Step{" "}
                  </h4>
                </Link>
              </Col>
              <Col md={4} className="mt-3">
                <Link to="/partial-application" className="labelbuttons second">
                  <h4 className="m-0">
                    Amend or Complete Partialy <br /> Filled Form
                  </h4>
                </Link>
              </Col>
              <Col md={4} className="mt-3">
                <Link to="/make-payment" className="labelbuttons third">
                  <h4 className="m-0">Make payment</h4>
                  <p>Complete the payment of partial application</p>
                </Link>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <InnerLayout>{children}</InnerLayout>
            </Row>
          </Container>
        </section>
      </div>
    </MainLayout>
  );
}
