import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import vietnamvisa from "../assets/img/vietnamvisa.jpg";
import { MdWidthFull } from "react-icons/md";

function VisaOnArrivalApplication() {
  return (
    <MainLayout>
      <ScrollToTop />
      <BannerLayout>Vietnam Visa On Arrival Online Application</BannerLayout>
      <section>
        <Container>
          <p>
            Vietnam Visa is required for most foreigners to enter and exit
            Vietnam at one of 3 international airports: Hanoi/ Ho Chi Minh city
            &amp; Da Nang. Travellers are advised to arrange Vietnam Visa before
            their departure by applying online for the Approval Letter first,
            receive the letter by email, then get Visa Stamped on their
            passports at Vietnam Airports upon arrival.
          </p>

          <p>
            This is called: <strong>VIETNAM VISA ON ARRIVAL.</strong>
          </p>
          <p>
            Following is <strong>Circular No.190/2012/TT-BTC</strong> dated
            November 09, 2012 of the Ministry of Finance (Vietnam Government)
            stipulating the regime on collection, remittance, management and use
            of fees at Vietnam international airports upon arrival:
          </p>
          <p className="text-center">
            <strong>THE MINISTRY OF FINANCE</strong>
            <br />
            ——–
          </p>
          <p className="text-center">No. 190/2012/TT-BTC</p>
          <p className="text-center">
            <strong>SOCIALIST REPUBLIC OF VIET NAM</strong>
            <br />
            Independence – Freedom – Happiness
            <br />
            ———-
          </p>
          <p className="text-center">Hanoi, November 09, 2012</p>
          <hr />
          <p className="text-start">
            <strong>CIRCULAR</strong>
          </p>
          <p>
            AMENDING AND SUPPLEMENTING THE CIRCULAR NO.66/2009/TT-BTC, OF MARCH
            30, 2009 OF THE MINISTRY OF FINANCE STIPULATING THE REGIME ON
            COLLECTION, REMITTANCE, MANAGEMENT AND USE OF FEES ON PASSPORTS,
            VISAS AND PAPERS ON ENTRY, EXIT, TRANSIT AND RESIDENCE IN VIETNAM
          </p>
          <p>
            Pursuant to the Ordinance on Charges and Fees No.38/2001/PL-UBTVQH
            of August 28, 2001, the Government’s Decree No. 57/2002/ND-CP of
            June 3, 2002, and Decree No. 24/2006/ND-CP of March 06, 2006,
            amending and supplementing a number of articles of the Government’s
            Decree No. 57/2002/ND-CP;
          </p>
          <p>
            Pursuant to the Government’s Decree No. 136/2007/ND-CP of August 17,
            2007, on entry and exit of Vietnamese citizens
          </p>
          <p>
            Pursuant to the Government’s Decree No. 118/2008/ND-CP of November
            27, 2008, defining the functions, tasks, powers and organizational
            structure of the Ministry of Finance
          </p>
          <p>At the proposal of Director of the Tax Policy Department</p>
          <p>
            The Minister of Finance promulgates Circular amending and
            supplementing the Circular No.66/2009/TT-BTC, of March 30, 2009 of
            the Ministry of Finance stipulating the regime on collection,
            remittance, management and use of fees on passports, visas and
            papers on entry, exit, transit and residence in Vietnam as follows:
          </p>
          <p>
            <strong>Article 1.</strong>
          </p>
          <p>
            To amend and supplement clause 1, clause 2, clause 4 and clause 6
            section II of Annex on table of fees on passports, visas and papers
            on entry, exit, transit and residence in Vietnam promulgated
            together with the Circular No.66/2009/TT-BTC, March 30, 2009 of the
            Ministry of Finance with clause 1, clause 2, clause 3, clause 4 as
            follows:
          </p>
          <div class="table">
            <div class="arow">
              <div class="cell">
                <strong>No.</strong>
              </div>
              <div class="cell">
                <strong>Type of fees</strong>
              </div>
              <div class="cell">
                <strong>Fee rate</strong>
              </div>
            </div>
            <div class="arow">
              <div class="cell">1</div>
              <div class="cell">Single-entry visas</div>
              <div class="cell">USD 45</div>
            </div>
            <div class="arow">
              <div class="cell">2</div>
              <div class="cell">Multiple-entry visas</div>
            </div>
            <div class="arow">
              <div class="cell">a)</div>
              <div class="cell">Valid for less than 01 month</div>
              <div class="cell">USD 65</div>
            </div>
            <div class="arow">
              <div class="cell">b)</div>
              <div class="cell">Valid for less than 06 months</div>
              <div class="cell">USD 95</div>
            </div>
            <div class="arow">
              <div class="cell">c)</div>
              <div class="cell">Valid for 6 months or more</div>
              <div class="cell">USD 135</div>
            </div>
            <div class="arow">
              <div class="cell">3</div>
              <div class="cell">
                Transfer of validity of visas or temporary residence from
                expired passports to new passports
              </div>
              <div class="cell">USD 15</div>
            </div>
            <div class="arow">
              <div class="cell">4</div>
              <div class="cell">Temporary residence cards</div>
            </div>
            <div class="arow">
              <div class="cell">a)</div>
              <div class="cell">Valid for up to 1 year</div>
              <div class="cell">USD 80</div>
            </div>
            <div class="arow">
              <div class="cell">b)</div>
              <div class="cell">Valid for between over 1 year and 2 years</div>
              <div class="cell">USD 100</div>
            </div>
            <div class="arow">
              <div class="cell">c)</div>
              <div class="cell">Valid for between over 2 year and 3 years</div>
              <div class="cell">USD 120</div>
            </div>
          </div>
          <hr />
          <p>
            <strong>Article 2.</strong>
          </p>
          <p>1. This Circular takes effect on January 01, 2013.</p>
          <p>
            2. The other content relating to fees on passports, visas and papers
            on entry, exit, transit and residence in Vietnam not mentioned in
            this Circular shall be implemented under provisions in the Circular
            No.66/2009/TT-BTC, of March 30, 2009 of the Ministry of Finance
            stipulating the regime on collection, remittance, management and use
            of fees on passports, visas and papers on entry, exit, transit and
            residence in Vietnam.
          </p>
          <p>
            3. Organizations, individuals subject to pay fees and relevant
            agencies shall implement this Circular. In the course of
            implementation, any arising problems should be reported to the
            Ministry of Finance for consideration and settlement.
          </p>
          <hr />
          <p className="text-center">
            <strong>FOR THE MINISTER OF FINANCE</strong>
            <br />
            <strong> VICE MINISTER</strong>
            <br />
            <strong> Vu Thi Mai</strong>
          </p>
          <p className="text-center">(Source: Government’s Websites)</p>
          <p>
            <strong>LEGITIMATE APPROVAL LETTER</strong>
          </p>
          <p>
            Visa Approval Letter is a letter issued and confirmed by Vietnam
            Immigration Department. By showing the approval letter at Vietnam
            Airports upon arrival, travellers can pick up Visa and get visa
            stamped on their passports. At the airport, the corresponding
            authority will verify the details on the approval letter based on
            your passport and travel documents. As long as you make sure you
            input the correct details when applying, you will surely be granted
            entry upon arrival in Vietnam with the approval letter. Without the
            approval letter, travellers CAN NOT check in the international
            flights to Vietnam.
          </p>
          <p>Application Guidelines for Vietnam Visa Approval Letters:</p>
          <ol>
            <li>Steep 1Access our main website: vietnamvisa.govt.vn</li>
            <li>Fill all required information for online application form</li>
            <li>
              Make online secured payment by Credit Card for service fee from
              $17/person
            </li>
            <li>
              Receive the approval letter via email ( after 2 working days for
              normal processing 1 day/ 4 hours/ 1 hour for super urgent
              processing ).
            </li>
            <li>
              Show your approval letter to check in your international flight
              and get Visa Stamped at Vietnam Airports upon arrival.
            </li>
          </ol>
          <p>
            To get more details about the Legitimate Approval Letter, please
            refer to document explanation below:
          </p>
		  <div className="d-flex align-items-center justify-content-center vh-100">
		  <img
            src={vietnamvisa}
            alt="visa"
            className="w-25 rounded-3 border"
	
          />
		  </div>
         
          <p>
            <strong>Number 1:</strong> Your Visa reference number in this list
            of Vietnam Immigration Department. This number and all information
            provided by you will be transmitted to secure system of Customs
            Clearance at Vietnam Airports.
          </p>
          <p>
            <strong>Number 2:</strong> Name of agent doing all paper work for
            you to submit your Visa Application to Vietnam Immigration
            Department for processing your Visa Approval letter.
          </p>
          <p>
            <strong>Number 3:</strong> Type of Visa (Single/Multiple) and time
            that you are permitted to stay in Vietnam (1 month or 3 months).
          </p>
          <p>
            <strong>Number 4:</strong> Your information provided when filling in
            the online application form on the website.
          </p>
          <p>
            <strong>Number 5:</strong> A copy of this document will be sent to
            Immigration Checking – Point for your Customs Clearance when you
            arrive Vietnam Airports.
          </p>
          <p>
            <strong>Number 6:</strong> Official Stamp of Vietnam Government and
            signature of Person in Charge from Vietnam Immigration Department
            for your Visa Confirmation.
          </p>
          <hr />
          <p>
            <strong>VISA PROCEDURE AT VIETNAM AIRPORTS:</strong>
          </p>
          <p>
            At the ARRIVAL HALL inside the airports, there is a Landing Visa
            Counter (cấp visa tai chổ), in font of the check – in point counter,
            you have to prepare required documents to get visa stamp: your
            original passport ( valid for at least 6 months and left pages ), 2
            ( 4×6 cm) passport photos, entry/ exit form ( you can DOWNLOAD HERE
            and cash in USD/VND:
          </p>
          <ul>
            <li>1 month 3 months single: 45 USD</li>
            <li>1 month multiple: 65 USD,</li>
            <li>3 months to less than 6 months: 95 USD</li>
            <li>and 6 months is 135 USD.</li>
          </ul>
          <p>
            Vietnam Customs Clearance Officers will arrange Visa Stamp at the
            arrival airport for clients enter Vietnam by plane. If you do NOT
            enter Vietnam by plane, you should contact the nearest Vietnam
            Embassy to get visa.
          </p>
          <p>
            The most important thing is that you bring up the Vietnam Visa
            Approval Letter when you enter Vietnam. Without this letter you
            cannot aboard the airplane and get visa when you arrive Vietnam.
          </p>
          <p>
            Normally, it takes around 15-30 minutes to get the visa stamped at
            Vietnam airports upon arrival. However, sometimes it may take longer
            than expected due to the large number of arrivals. To shorten your
            Customs Clearance time, you can use our Vietnam Visa Fast Tracking
            Service.
          </p>
          <p>
            The Visa Counter at Vietnam airports are 24/7 open. So if you
            already have the approval letter, you can still pick up your visa at
            Vietnam airports at weekend.
          </p>
          <p>
            Any request you need, please call our hotline 24/7: (+84) 832 320
            320
          </p>
          <p>
            <strong>WELCOME TO VIETNAM !</strong>
          </p>
        </Container>
      </section>
    </MainLayout>
  );
}

export default VisaOnArrivalApplication;
