import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import PagesLayout from "../components/PagesLayout";

function Home() {
  return (
    <PagesLayout>
      <Row>
        <Col md={12}>
          <h2>Welcome to Vietnam eVisa</h2>
          {/* <p className="text-success fw-bold">Visa on Arrival</p> */}
          <p>
            The <strong>Vietnam eVisa</strong> is an{" "}
            <strong>Official Electronic Travel Authorization</strong> that
            allows entry into Vietnam for various purposes, including tourism,
            business, or transit. This visa is electronically linked to your
            passport, eliminating the need for a physical visa label. With an
            eVisa, you can travel throughout Vietnam for up to 30 days.
          </p>
          <h2>List of Eligible Countries for eVisa Vietnam:-</h2>
          <p>
            Afghanistan, Albania, Algeria, Andorra, Angola, Antigua and Barbuda,
            Argentina, Armenia, Australia, Austria, Azerbaijan, Bahamas,
            Bahrain, Bangladesh, Barbados, Belarus, Belgium, Belize, Benin,
            Bermuda, Bhutan, Bolivia, Bosnia and Herzegovina, Botswana, Brazil,
            British India Ocean Territory, Bruney, Bulgaria, Burkina Faso,
            Burundi, Cambodia, Cameroon, Canada, Cape Verde, Central African
            Republic, Chad, Chile, China, China (Taiwan), Colombia, Comoros,
            Congo, Costa Rica, Cote d' Ivoire, Croatia, Cuba, Cyprus, Czech
            Republic, Democratic Republic of the Congo, Denmark, Djibouti,
            Dominica, Dominican Republic, Ecuador, Egypt, El Salvado, Equatorial
            Guinea, Eritrea, Estonia, Ethiopia, Fiji, Finland, France, Gabon,
            Gambia, Georgia, Germany, Ghana, Gibraltar, Greece, Greenland,
            Grenada, Guatemala, Guinea, Guinea-Bissau, Guyana, Haiti, Holy See
            (Vatican City State), Honduras, Hungary, Iceland, India, Indonesia,
            Iran Islamic Republic of, Iraq, Ireland, Israel, Italy, Jamaica,
            Japan, JOR, Kazakhstan, Kenya, Kiribati, Korea (South), Korea
            Democratic Peoples Republic of, Kosovo, Kuwait, Kyrgyzstan, Lao
            Peoples Democratic Republic, Latvia, Lebanon, Lesotho, Liberia,
            Libyan Arab Jamahiriya, Liechtenstein, Lithuania, Luxembourg,
            Macedonia, Madagascar, Malawi, Malaysia, Maldives, Mali, Malta,
            Marshall Islands, Mauritania, Mauritius, Mexico, Micronesia,
            Moldova, Monaco, Mongolia, Montenegro, Montserrat, Morocco,
            Mozambique, Myanmar, Namibia, Nauru, Nepal, Netherland, New Zealand,
            Nicaragua, Niger, Nigeria, Norway, Oman, Pakistan, Palau, Palestine,
            Panama, Papua New Guinea, Paraguay, Peru, Philippines, Poland,
            Portugal, Qatar, Romania, Russia, Rwanda, Saint Kitts and Nevis,
            Saint Lucia, Saint Vincent and the Grenadines, San Marino, Sao Tome
            and Principe, Saudi Arabia, Scotland, Senegal, Serbia, Seychelles,
            Sierra Leone, Singapore, Slovakia, Slovenia, Solomon Islands,
            Somalia, South Africa, South Sudan, Spain, Sri Lanka, Sudan,
            Suriname, Swaziland, Sweden, Switzerland, Syrian Arab Republic,
            Tajikistan, Tanzania United Republic of, Thailand, Timor Leste,
            Togo, Tonga, Trinidad and Tobago, Tunisia, Turkey, Turkmenistan,
            Tuvalu, Uganda, Ukraine, United Arab Emirates, United Kingdom
            British Territories Citizen, United Kingdom of Great Britain and
            Northern Ireland, United Nations Organization, United States of
            America, Uruguay, Uzbekistan, Vanuatu, Venezuela, Western Samoa,
            Yemen, Zambia, Zimbabwe,
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h4>How to Apply for a Vietnam eVisa</h4>
          {/* <h3 className="bg-light p-2 mt-4 mb-4 text-center">VISA</h3> */}
        </Col>
        <Col md={12}>
          <ol>
            <li>
              <strong>Step 1</strong>— Fill out a
              <Link href="/apply-online">quick application</Link> form online
              (you will need to know your arrival date and the name of the
              airport at which you will arrive)
            </li>
            <li>
              <strong>Step 2</strong> — Pay the visa servicing fee online
            </li>
            <li>
              <strong>Step 3</strong> — Within two business days, you will get
              an email with your official Vietnam visa Approval Letter issued by
              the Vietnam Immigration Department
            </li>
            <li>
              <strong>Step 4</strong> — Print the Approval Letter and prepare
              two (4cm x 6cm) photos of yourself according to noted requirements
            </li>
            <li>
              <strong>Step 5</strong> — Bring the printed Approval Letter,
              photos, and the government Stamping Fee (along with your passport
              and any other required documents) with you on your trip to Vietnam
            </li>
            <li>
              <strong>Step 6</strong> — When you arrive at your destination
              airport in Vietnam, show the Approval Letter and photos at the
              Immigration office
            </li>
          </ol>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h5>Urgent Vietnam Visa</h5>
          <p>
            Need to travel on short notice? Our{" "}
            <strong>Urgent Vietnam Visa</strong> service ensures you receive
            your eVisa in as little as 4-8 hours. This expedited service is
            ideal for last-minute travelers who need quick and reliable visa
            processing.
          </p>
          <h5>Vietnam Visa Requirements</h5>
          To apply for a Vietnam eVisa, you will need:
          <ol>
            <li> A valid passport with at least 6 months of validity.</li>
            <li>A recent passport-sized photograph (digital format).</li>
            <li>
              {" "}
              Details of your intended entry and exit dates, along with the
              address of your accommodation in Vietnam.
            </li>
          </ol>
          <h4>Value-Added Services for eVisa Vietnam</h4>
          <p>
            <strong>1. Rechecking of Application Form</strong>:- Before
            submission, we offer a thorough review of your eVisa application
            form to ensure accuracy and completeness. This minimizes errors and
            enhances the likelihood of a successful application.
            <br />
            <br />
            <strong>2. 24/7 Customer Care Support:</strong>
            Access round-the-clock customer support via our dedicated helpline
            and email. Our knowledgeable team is available to assist with any
            questions or issues during your eVisa application process.
            <br />
            <br />
            <strong>3. Recovery Assistance for Lost or Stolen eVisa:</strong>
            In case of loss or theft of your eVisa approval, our support team
            can guide you through the process of obtaining a replacement,
            ensuring minimal disruption to your travel plans.
            <br />
            <br />
            <strong>4. Continuous Application Updates:</strong>
            Stay informed with regular updates on the status of your eVisa
            application. We keep you informed about progress and expected
            timelines for your convenience.
            <br />
            <br />
            <strong>5. Simplified Reapplication Process:</strong>
            If your eVisa application is rejected or needs to be reapplied for
            any reason, we streamline the process to save you time and effort.
            Avoid filling out the application form again with our reapplication
            assistance.
            <br />
            <br />
            <strong>6. Language Support with Interpreters:</strong>
            Overcome language barriers with our team of interpreters who provide
            assistance during the application process. They ensure clarity and
            help address any language-related concerns you may encounter.
            <br />
            <br />
            <strong>7. Live Expert Assistance:</strong>
            Receive real-time guidance from our live expert support team
            throughout the application form filling process. They offer
            personalized assistance, answer queries promptly, and ensure
            accurate completion of your application.
            <br />
            <br />
            <strong>8. Additional Information:</strong>
            Vietnam eVisas are electronically linked to your passport,
            eliminating the need for physical stamps or labels. This streamlined
            process simplifies entry into Vietnam without additional
            documentation.
          </p>
        </Col>
      </Row>
    </PagesLayout>
  );
}

export default Home;
