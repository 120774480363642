import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function FrenchCitizens() {
  return (
    <MainLayout>
      <ScrollToTop />
      <BannerLayout>Vietnamese Visas for French Citizens</BannerLayout>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <p>
                From 15 Aug 2023, the government of Vietnam decided to extend
                the duration of stays in Vietnam&nbsp;
                <strong>from 15 to 45 days</strong>&nbsp;for citizens of 13
                countries who are unilaterally exempted from visas by Vietnam.
              </p>
              <p>
                These countries are&nbsp;
                <strong>
                  Germany, France, Italy, Spain, the U.K., Northern Ireland,
                  Russia, Japan, South Korea, Denmark, Sweden, Norway, Finland
                  and Belarus.&nbsp;
                </strong>
                Citizens of these countries are allowed to stay for 45 days from
                the time of entry, regardless of passport type and entry
                purpose.
              </p>
              <hr />
              <p>
                France and Vietnam share a rich history, and Vietnam is becoming
                an increasingly popular vacation destination for French
                citizens. Vietnam is the only Asian country that is a member of
                the{" "}
                <a href="javascript:void(0)">
                  Organisation Internationale de la Francophonie
                </a>
                , which is a group of countries with a significant number of
                French-speakering people. While Vietnam obtained independence in
                1954, the country still holds significant French influences in
                language, cuisine, and architecture.
              </p>
              <p>
                French tourists traveling to Vietnam need a Vietnam visa to
                enter the country. Know that Vietnam visas on arrival are only
                an option for French citizens who arrive by air at one of
                Vietnam’s participating airports. Airports in Hanoi, Da Nang, Ho
                Chi Minh City, and Nha Trang participate in Vietnam’s
                visa-on-arrival program.
              </p>
              <p>
                French travellers should be mindful of policy changes due to the
                COVID-19 pandemic.&nbsp; Before the recent public health crisis,
                French citizens did not need a visa for single-entry trips under
                15 days. French citizens could re-enter Vietnam on a new
                visa-exempt trip once every 30 days. However, French citizens
                now need a visa for all trips to Vietnam, even trips under 15
                days. Please note that this policy change is only temporary, and
                visa exemptions for French citizens will likely resume later
                this year.
              </p>
              <p>
                <b>How to apply for a Vietnam visa for French citizens</b>
              </p>
              <p>
                To help French citizens obtain a Vietnam visa, we detail the
                process below. While French citizens can get a&nbsp;{" "}
                <a href="/vietnam-visa/visa-on-arrival/">
                  Vietnam visa on arrival
                </a>
                , they can streamline the process by applying for their Visa
                online before their trip. To get a Vietnam visa, French
                travelers must complete the following steps:
              </p>
              <ul>
                <li>
                  Visit our online{" "}
                  <a href="/">Vietnam visa application for French citizens</a>.
                </li>
                <li>
                  Complete our application form online via our secure network.
                </li>
                <li>Receive a visa approval letter.</li>
                <li>
                  Arrive at one of Vietnam’s participating international
                  airports (<a href="/brochures/hanoi-travel-guide/">Hanoi</a>/{" "}
                  <a href="/brochures/da-nang-a-travel-guide/">Da Nang</a>/{" "}
                  <a href="/brochures/ho-chi-minh-city-a-travel-guide/">
                    Ho Chi Minh City
                  </a>
                  / <a href="/brochures/nha-trang-travel-guide/">Nha Trang</a>).
                </li>
                <li>
                  Receive your stamp and official Vietnam visa from Vietnam
                  immigration.
                </li>
              </ul>
              <p>
                The typical processing time for Vietnam visa applications is two
                working days. However, we encourage French citizens to apply for
                their Vietnam visa well before their departure date. It is
                important to leave time to spare, as we may encounter problems
                with your Vietnam visa application.
              </p>
              <p>
                <b>
                  Application information for your Vietnam visa application as a
                  French citizen
                </b>
              </p>
              <p>
                French citizens who travel to Vietnam must have a passport that
                is valid for six months after their intended date of arrival.
                Additionally, each traveler must have a blank page in their
                passport for their Vietnam visa stamp. To help ensure that
                French travelers submit complete Vietnam visa applications, we
                compiled a list of helpful tips for important details in your
                application. Please review the points below, and include each
                piece of information in your Vietnam visa application:
              </p>
              <ul>
                <li>
                  Your name exactly as you find it in your passport. The order
                  of your first and last name is not important.
                </li>
                <li>
                  Your date of birth. In Vietnam, the standard format is
                  DD/MM/YY;
                </li>
                <li>
                  Your nationality. If you apply as a French citizen, you must
                  arrive in Vietnam with your French passport.
                </li>
                <li>
                  Your passport number. Make sure that the passport number you
                  provide matches the number in your passport exactly. Incorrect
                  passport numbers cause delays.
                </li>
                <li>
                  Date of arrival. Make sure that your passport is valid for six
                  months after your date of arrival. Also, know that you can
                  arrive both on or after the date of arrival in your Vietnam
                  visa application.
                </li>
                <li>
                  Passport photos. You will need to provide a passport photo
                  when you arrive in Vietnam before obtaining your Vietnam visa
                  stamp.
                </li>
              </ul>
              <p>
                For further assistance, please email us at{" "}
                <a href="mailto:info@Vietnamevisa.org">
                  info@Vietnamevisa.org
                </a>
                . Alternatively, you can call our hotline at +84 832 320 320. We
                are here to help you with all visa-related questions and
                concerns.
              </p>
              <p>
                <b>Travel tips for French citizens traveling to Vietnam</b>
              </p>
              <p>
                When traveling to a new country, encountering the unknown is
                part of the excitement. However, travelers should do some
                preparation before their trip in order to get the most out of
                their time abroad. Below, we aim to help you do just that with
                several travel tips and links to resources:
              </p>
              <ul>
                <li>
                  French tourists in Vietnam will love Vietnamese cuisine. It is
                  a delicious combination of Asian cuisine with classic French
                  influences. Even though Vietnam is thousands of kilometers
                  away from France, travelers will not have trouble locating a
                  perfectly baked French baguette. Check out our{" "}
                  <a href="/vietnam-travel-tips/eating-in-vietnam-the-must-try-foods-to-eat-during-your-stay/">
                    guide to eating in Vietnam
                  </a>{" "}
                  when planning your trip.
                </li>
                <li>
                  Vietnam has a variety of landscapes, and regions have unique
                  weather patterns. Depending on their itinerary, French
                  tourists in Vietnam may need to pack different sets of
                  clothing for each type of weather they will encounter. Take a
                  look at our{" "}
                  <a href="/vietnam-travel-tips/the-best-time-to-visit-vietnam-by-region/">
                    guide on the best times to visit Vietnam by region
                  </a>{" "}
                  and our guide on{" "}
                  <a href="/vietnam-travel-tips/ultimate-guide-how-to-pack-for-your-trip-to-vietnam/">
                    how to pack for your trip to Vietnam
                  </a>
                  .
                </li>
                <li>
                  Getting acquainted with local customs and etiquette is an
                  essential part of any trip abroad. Do not hold your chopstick
                  straight up, and try to avoid standing with your arms crossed.
                  Learn why in our{" "}
                  <a href="/vietnam-travel-tips/the-ultimate-guide-to-customs-and-etiquette-in-vietnam/">
                    ultimate guide to customs and etiquette in Vietnam
                  </a>
                  .
                </li>
                <li>
                  Lastly, know that French tourists can travel through Vietnam
                  on a budget. Typically, backpackers in Vietnam can cover
                  transportation, food, accommodation, and miscellaneous
                  expenses for around just $40 to $50 per day. Learn more about
                  budgeting for your trip in our{" "}
                  <a href="/vietnam-travel-tips/the-ultimate-guide-to-vietnam-vacation-costs/">
                    ultimate guide to Vietnam vacation costs
                  </a>
                  .
                </li>
              </ul>
              <p>
                To read more travel tips and resources in preparation for your
                trip, check out our page on{" "}
                <a href="/travel-tips/">
                  Vietnam travel tips for French tourists
                </a>
                . You can also check out our location-specific travel guides for{" "}
                <a href="/brochures/">popular travel destinations in Vietnam</a>
                . However, before you start planning your trip, get your Vietnam
                visa! You can apply for your Vietnam visa using our{" "}
                <a href="/">
                  online Vietnam visa application for French citizens
                </a>
                .
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}

export default FrenchCitizens;
