import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import HowToApply from "./pages/HowToApply";
import VisaFees from "./pages/VisaFees";
import TravelGuides from "./pages/TravelGuides";
import FAQs from "./pages/FAQs";
import PoliciesProcedures from "./pages/PoliciesProcedures";
import Embassy from "./pages/Embassy";
import VisasOnArrival from "./pages/VisasOnArrival";
import AustralianCitizens from "./pages/AustralianCitizens";
import GermanCitizens from "./pages/GermanCitizens";
import UnitedStatesCitizens from "./pages/UnitedStatesCitizens";
import FrenchCitizens from "./pages/FrenchCitizens";
import NewZealandCitizens from "./pages/NewZealandCitizens";
import CanadianCitizens from "./pages/CanadianCitizens";
import VietnamVisaTestimonials from "./pages/VietnamVisaTestimonials";
import History from "./pages/History";
import PaymentGuidlines from "./pages/PaymentGuidlines";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import BritishCitizens from "./pages/BritishCitizens";
import Contacts from "./pages/Contacts";
import ApplicationDetails from "./pages/ApplicationDetails";
import TravelTips from "./pages/TravelTips";
import Blog from "./pages/Blog";
import ApplyOnline from "./pages/ApplyOnline";
import VisaOnArrivalApplication from "./pages/VisaOnArrival2";
import PreviewForm from "./pages/PreviewForm";
import Preview from "./pages/Preview";
import ThankYOU from "./pages/thank-you";
import PartialApplication from "./pages/PartialApplication";
import CompletePayment from "./pages/CompletePayment";
import AboutUs from "./pages/AboutUs";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/how-to-apply",
    element: <HowToApply />,
  },
  {
    path: "application-details",
    element: <ApplicationDetails />,
  },
  {
    path: "visa-on-arrival-application",
    element: <VisaOnArrivalApplication />,
  },
  {
    path: "/visa-fees",
    element: <VisaFees />,
  },
  {
    path:"/visa/thank-you",
    element: <ThankYOU />,
  },
  {
    path: "/apply-online",
    element: <ApplyOnline />,
  },
  {
    path: "/partial-application",
    element: <PartialApplication />,
  },
  {
    path: "/make-payment",
    element: <CompletePayment />,
  },
  {
    path: "/travel-guides",
    element: <TravelGuides />,
  },
  {
    path: "/faqs",
    element: <FAQs />,
  },
  {
    path: "/government-policies",
    element: <PoliciesProcedures />,
  },
  {
    path: "/embassy",
    element: <Embassy />,
  },
  {
    path: "/visa-on-arrival",
    element: <VisasOnArrival />,
  },
  {
    path: "/australian-citizens",
    element: <AustralianCitizens />,
  },
  {
    path: "/british-citizens",
    element: <BritishCitizens />,
  },
  {
    path: "/german-citizens",
    element: <GermanCitizens />,
  },
  {
    path: "/united-states-citizens",
    element: <UnitedStatesCitizens />,
  },
  {
    path: "/french-citizens",
    element: <FrenchCitizens />,
  },
  {
    path: "/new-zealand-citizens",
    element: <NewZealandCitizens />,
  },
  {
    path: "/canadian-citizens",
    element: <CanadianCitizens />,
  },
  {
    path: "/vietnam-visa-testimonials",
    element: <VietnamVisaTestimonials />,
  },
  {
    path: "/history",
    element: <History />,
  },
  {
    path: "/payment-guidelines",
    element: <PaymentGuidlines />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/preview-form/:",
    element: <PreviewForm />,
  },
  {
    path: "/preview",
    element: <Preview />,
  },
  {
    path: "/terms-and-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/contact-us",
    element: <Contacts />,
  },
  {
    path: "/travel-tips",
    element: <TravelTips />,
  },
  {
    path: "/blog",
    element: <Blog />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return <RouterProvider router={routes} />;
}

export default App;
