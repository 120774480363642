import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";

function CanadianCitizens() {
  return (
    <MainLayout>
      <ScrollToTop />
      <BannerLayout>
        Canadian Citizens Must Have a Visa To Visit Vietnam
      </BannerLayout>
      <section>
        <Container>
          <Row>
            <Col md={12}>
              <section class="entry-content">
                <p>
                  Canadian travelers can find adventure and excitement with a
                  trip to Vietnam. While admittedly, Vietnam is a good distance
                  from Canada, flight costs are reasonable. Air Canada offers
                  routes from Toronto, Calgary, and Vancouver, and most flights
                  connect via Hong Kong or Seoul. After arriving, travelers of
                  all types will find that Vietnam can cater to their needs, and
                  Canadian travelers who are on a budget will have a variety of
                  options during their stay in Vietnam.
                </p>
                <p>
                  Once you have decided on Vietnam as your next destination, you
                  must figure out your travel logistics. Since 1996, all
                  Canadian citizens who come to Vietnam can pick up a Vietnam
                  visa on arrival at Vietnam’s international airports. However,
                  obtaining a Vietnam visa is easy for Canadian citizens.
                </p>
                <p>
                  <b>How to get a visa to Vietnam from Canada</b>
                </p>
                <p>
                  To obtain a Vietnam tourist visa on arrival, Canadian
                  travelers need to follow the steps below:
                </p>
                <ul>
                  <li>
                    Visit our <a href="/">online Vietnam visa application</a>{" "}
                    for Canadian citizens
                  </li>
                  <li>
                    Submit your visa application form online via our secured
                    network to receive your Vietnam Visa Approval Letter.
                  </li>
                  <li>
                    Get your Vietnam visa stamp on arrival at one of Vietnam’s
                    international airports (
                    <a href="/brochures/hanoi-travel-guide/">Ha Noi</a>/{" "}
                    <a href="/brochures/da-nang-a-travel-guide/">Da Nang</a>/{" "}
                    <a href="/brochures/ho-chi-minh-city-a-travel-guide/">
                      Ho Chi Minh City
                    </a>
                    / <a href="/brochures/nha-trang-travel-guide/">Nha Trang</a>
                    ).
                  </li>
                </ul>
                <p>
                  Typical processing time for Vietnam visa applications for
                  Canadians is approximately two working days. However,
                  depending on application specifics such as single entry or
                  multiple entry requirements, some applications may have a
                  longer processing time. It is best to apply for your Vietnam
                  visa as early as possible to make sure that you have ample
                  time to sort out any potential issues.
                </p>
                <p>
                  <b>
                    Application details for Canadian citizens traveling to
                    Vietnam
                  </b>
                </p>
                <p>
                  Before applying for your Vietnam visa, please make sure that
                  your Canadian passport is valid for at least six months after
                  your date of arrival to Vietnam. Also, make sure that your
                  Canadian passport has blank pages left for a Vietnam visa
                  stamp. Know that the visa on arrival option is only applicable
                  for people who travel to Vietnam by air.
                </p>
                <p>
                  To help ensure that your Vietnam application contains all
                  needed information, be sure to include the information listed
                  below:
                </p>
                <ul>
                  <li>
                    Your name exactly as per passport. (Please provide your full
                    name on your passport. The order of your first, middle, and
                    last names is not important).
                  </li>
                  <li>
                    Your date of birth. (In Vietnam, the date format is
                    DD/MM/YY).
                  </li>
                  <li>
                    Your nationality on the passport that you will use to enter
                    Vietnam. (The passport that you plan to use must be valid
                    for at least six months from your date of arrival into
                    Vietnam).
                  </li>
                  <li>
                    Your passport number. (Be careful! The passport number that
                    you provide must match with the number inside your passport
                    exactly).
                  </li>
                  <li>
                    Your date of arrival. (Applicants must submit their intended
                    date of arrival. Canadian citizens can enter Vietnam on or
                    after the submitted arrival date).
                  </li>
                  <li>
                    Passport photos. (If you plan to arrive at one of Vietnam’s
                    international airports, you must provide passport photos).
                  </li>
                </ul>
                <p>
                  To apply for a visa, visit our online{" "}
                  <a href="/">Vietnam visa application for Canadian citizens</a>
                  . For further assistance, please email us at
                  info@Vietnamevisa.org or call our hotline at +84 832 320
                  320.
                </p>
                <p>
                  <b>Travel tips for Canadians traveling to Vietnam</b>
                </p>
                <p>
                  We have developed a variety of resources to help Canadians
                  plan their trip to Vietnam. After you apply for your Vietnam
                  visa, be sure to take a look! Below are some resource links
                  you may find useful:
                </p>
                <ul>
                  <li>
                    There are several points that Canadian backpackers in
                    Vietnam should know. In our{" "}
                    <a href="/vietnam-travel-tips/backpacking-vietnam/">
                      backpacking Vietnam guide
                    </a>
                    , we recommend several places for backpackers to visit. In
                    particular, we recommend{" "}
                    <a href="/brochures/hanoi-travel-guide/">Hanoi</a>,{" "}
                    <a href="/brochures/halong-bay-cruise-and-tour-travel-guide/">
                      Halong Bay
                    </a>
                    , and{" "}
                    <a href="/brochures/nha-trang-travel-guide/">Nha Trang</a>.
                    We also list several must-pack items and important safety
                    tips. Bring a security belt for your money, and look into
                    backpacker travel insurance!
                  </li>
                  <li>
                    Know that Vietnam has a variety of climates, and regional
                    climates can greatly vary. Please take a look at our article
                    on the{" "}
                    <a href="/vietnam-travel-tips/the-best-time-to-visit-vietnam-by-region/">
                      best times to visit Vietnam by region
                    </a>{" "}
                    so that you know what weather to expect during your stay. Be
                    sure to pack a variety of clothing that is appropriate for
                    the weather you will experience.
                  </li>
                  <li>
                    When in Vietnam, travelers experience new customs and
                    etiquette. To make sure that you fit in well with the
                    locals, review our{" "}
                    <a href="/vietnam-travel-tips/the-ultimate-guide-to-customs-and-etiquette-in-vietnam/">
                      ultimate guide to customs and etiquette in Vietnam
                    </a>
                    . If you plan to visit any of Vietnam’s sacred Buddhist or
                    Hindu sites, wear conservative clothing.
                  </li>
                  <li>
                    Lastly, be sure to research Vietnamese cuisine and learn
                    about the local dishes for each area you visit. Vietnamese
                    cuisine is a delicious fusion of Asian and French culinary
                    influences. Check out our article on{" "}
                    <a href="/vietnam-travel-tips/eating-in-vietnam-the-must-try-foods-to-eat-during-your-stay/">
                      must-try foods to eat during your stay in Vietnam
                    </a>{" "}
                    to learn more.
                  </li>
                </ul>
                <p>
                  We also have a variety of site-specific guides for popular
                  tourist destinations in Vietnam. To get the inside scoop and
                  our recommendations for your trip, check out our Vietnam
                  travel guides. You can find information on popular places such
                  as{" "}
                  <a href="/brochures/phan-thiet-vietnam-a-travel-guide/">
                    Phan Thiet
                  </a>
                  , <a href="/brochures/bien-hoa-travel-guide/">Bien Hoa</a>,
                  and{" "}
                  <a href="/brochures/halong-bay-cruise-and-tour-travel-guide/">
                    Halong Bay
                  </a>
                  .
                </p>
                <p>
                  We hope our{" "}
                  <a href="/travel-tips/">
                    Vietnam travel resources for Canadian citizens
                  </a>{" "}
                  are useful! Remember, your first step when planning your trip
                  to Vietnam is to{" "}
                  <a href="/">apply online for a Vietnam visa</a>.
                </p>
              </section>
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}

export default CanadianCitizens;
