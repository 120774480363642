import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Table, Button } from "react-bootstrap";
import { userService } from "../service";
import { useLocation, useNavigate } from "react-router-dom";

import MainLayout from "../components/MainLayout";
import ScrollToTop from "../components/ScrollToTop";
import BannerLayout from "../components/BannerLayout";
import { nodeUrl } from "../config";
import Loader from "../components/Loader";
import PayPalGateway from "../components/paypal";
import razorPay from "../assets/img/razorpay.png";
import { payment } from "../components/payment";
import moment from "moment";
export default function Preview() {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({});

  const [mainData, setmainData] = useState({});
  const [violationData, setviolationData] = useState([]);
  const [pruposeData, setpruposeData] = useState([]);
  const [relationship, setrelationship] = useState([]);
  const [under14, setunder14] = useState([]);
  const [otherPasswords, setotherPasswords] = useState([]);
  const [showPayment, setShowpayment] = useState(false);
  const [paymentParams, setPaymentParams] = useState(null);
  const [checked, setchecked] = useState(false);
  const location = useLocation();
  const data = location.state;
  const handlePreview = async () => {
    try {
      const result = await userService.post(
        "applicationForm/VN_Application_full_preview",
        { temporary_id: data }
      );
      setmainData(result.data.data);
      setviolationData(result.data.violationDetails);
      setpruposeData(result.data.vietnamVisitDetails);
      setrelationship(result.data.relativeDetails);
      setunder14(result.data.childDetail);
      setotherPasswords(result.data.otherPasswords);
      if (result.status === 200) {
        getVisaFees(result.data.data);
      }
    } catch (err) {
      console.error(err);
    } finally {
      console.log("finally");
    }
  };
  const getVisaFees = async (data) => {
    try {
      const response = await userService.post("visa/fee", {
        country_name: data.nationality,
        visa_type: data.visa_type,
        visa_priority: data.visa_priority,
      });
      if (response.status === 200) {
        data.amount = response.data.data;
        setPaymentParams(data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const makePayment = () => {
    // const obj = {};
    try {
      payment.displayRazorpay(paymentParams, (data) => {
        navigate("/visa/thank-you", {
          state: { data },
        });
      });
    } catch (err) {
      console.error(err);
    }
  };

  // const handleEdit = () => {
  //   // navigate("/apply-online", {
  //   //   state: data,
  //   // });

  //   if (location.state.valid === true) {
  //     console.log("dfg", location.state);
  //     navigate("/apply-online", {
  //       state: location.state.data,
  //     });
  //   } else {
  //     console.log(data, "========================");
  //     navigate("/apply-online", {
  //       state: data,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   console.log(location.state);
  //   if (location.state.valid === true) {
  //     console.log("called");
  //     setmainData(location.state.data);
  //     setviolationData(location.state.violationDetails);
  //     setpruposeData(location.state.vietnamVisitDetails);
  //     setrelationship(location.state.relativeDetails);
  //     setunder14(location.state.childDetail);
  //     setotherPasswords(location.state.otherPasswords);
  //   } else {
  //     console.log("preview")
  //     handlePreview();
  //   }
  // }, []);

  const handleEdit = () => {
    navigate("/apply-online", {
      state: data,
    });
  };
  
  useEffect(() => {
    if (data) {
      handlePreview();
    }
  }, []);

  return (
    <MainLayout>
      <ScrollToTop />
      <Loader visible={showLoader} />
      <section className="applynowformwrapper preview_wrapper details">
        <Container>
          <Row>
            <Col md={12}>
              <div className="bg-white border p-3 rounded-1 mb-3">
                <Row className="mb-3 align-items-center">
                  <Col xs={4}>
                    <strong>Visa Type:</strong>{" "}
                    <span>{mainData?.visa_type}</span>
                  </Col>
                  <Col xs={4}>
                    <strong>Visa Priority:</strong>{" "}
                    <span>{mainData?.visa_priority}</span>
                  </Col>
                  <Col xs={4}>
                    <div className="border rounded-2 bg-light p-2 d-inline float-end">
                      <strong>Application ID:</strong>{" "}
                      <span>{mainData?.temporary_id}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <h5>Applicant Profile Images</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-3">
                    <Row>
                      <Col md={4} className="mb-3">
                        <Form.Label>Profile image</Form.Label>
                        {/* copied code */}
                        <div className="file-upload-container">
                          <label
                            htmlFor="profile-upload"
                            className="profile-upload-box  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative"
                          >
                            {!mainData?.profile_image ? (
                              <img
                                src={require("../assets/img/profile.jpeg")}
                                alt=""
                              />
                            ) : (
                              <img
                                src={nodeUrl + mainData?.profile_image}
                                style={{
                                  width: "100%",
                                  height: 200,
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            )}
                          </label>
                          <Form.Control
                            type="file"
                            disabled
                            id="profile-upload"
                            accept="image/*"
                            // onChange={uploadFiles}
                            name="profile_image"
                          />
                          {errors?.profile_image && (
                            <p className="errorText">{errors?.profile_image}</p>
                          )}
                        </div>
                      </Col>
                      <Col md={4} className="mb-3">
                        <Form.Label>
                          Passport Image <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="file-upload-container">
                          <label
                            htmlFor="passport-upload"
                            className="profile-upload-box rounded-2 d-flex align-items-center position-relative w-100"
                            style={{
                              height: "200px",
                              overflow: "auto",
                            }}
                          >
                            {!mainData?.passport_image ? (
                              <img
                                src={require("../assets/img/passport.jpg")}
                                alt=""
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            ) : (
                              <img
                                src={nodeUrl + mainData?.passport_image}
                                // className="preview-image"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                  objectFit: "contain",
                                }}
                                alt=""
                              />
                            )}
                          </label>
                          <Form.Control
                            type="file"
                            disabled
                            id="passport-upload"
                            accept="image/*"
                            name="passport_image"
                          />
                          {errors?.passport_image && (
                            <p className="errorText">
                              {errors?.passport_image}
                            </p>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* </div>
                <div className="bg-white border p-3 rounded-1  pt-0 mb-3"> */}
                <Row>
                  <Col md={12}>
                    <h5>Personal Information</h5>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Surname</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.surname}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Given name</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.given_name}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Date of birth</Form.Label>
                      </Col>
                      <Col xs={8}>
                        {mainData?.date_of_birth &&
                          moment(mainData?.date_of_birth).format("DD/MM/YYYY")}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      {/* <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="Full date,month and year"
                            name="date_limit"
                            id="FULL_DATE_MONTH_AND_YEAR"
                            checked={
                              formData.date_limit === "Full date,month and year"
                            }
                            // onChange={handleChange}
                            value={"Full date,month and year"}
                          />
                        </Col> */}
                      {/* <Col xs={6}>
                          <Form.Check
                            type="radio"
                            label="Only year is known"
                            name="date_limit"
                            id="ONLY_YEAR_IS_KNOWN"
                            // onChange={handleChange}
                            value={"Only year is known"}
                          />
                        </Col> */}
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Place of birth</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.place_of_birth}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Sex</Form.Label>
                      </Col>
                      <Col xs={8}>
                        <Row>
                          <Col xs={6}>{mainData?.gender}</Col>
                        </Row>
                        {errors?.gender && (
                          <p className="errorText">{errors?.gender}</p>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Current Nationality </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.nationality}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Religion</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.religion}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>ID Card number</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.id_card_number}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Email</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.email}</Col>
                    </Row>
                  </Col>
                </Row>
                {/* </div>
                <div className="bg-white border p-3 rounded-1  pt-0 mb-3"> */}
                <Row>
                  <Col md={12}>
                    <h5>Requested Information</h5>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Label className="fst-italic text-primary">
                      e-Visa request information
                    </Form.Label>
                    <Row>
                      <Col xs={6}>{mainData?.entry_type}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Grant Evisa valid from </Form.Label>
                      </Col>
                      <Col xs={8}>
                        {moment(mainData?.grant_evisa_valid_from).format(
                          "DD/MM/YYYY"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>To</Form.Label>
                      </Col>
                      <Col xs={8}>
                        {moment(mainData?.grant_evisa_valid_to).format(
                          "DD/MM/YYYY"
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* </div>
                <div className="bg-white border p-3 rounded-1  pt-0 mb-3"> */}
                <Row>
                  <Col md={12}>
                    <h5>Contact Information</h5>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4}>
                    <Form.Label>Permanent residential address</Form.Label>
                  </Col>
                  <Col xs={8}>{mainData?.permanent_address}</Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={4}>
                    <Form.Label>Contact address</Form.Label>
                  </Col>
                  <Col xs={8}>{mainData?.contact_address}</Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Form.Label className="fst-italic text-primary">
                      Emergency Contacts
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Full Name</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.emergency_full_name}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Current residential address</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.current_residence_address}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Telephone number</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.telephone_number}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Relationship</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.relationship}</Col>
                    </Row>
                  </Col>
                </Row>
                {/* </div>
              <div className="bg-white border p-3 rounded-1  pt-0 mb-3"> */}
                <Row>
                  <Col md={12}>
                    <h5>Passport Information</h5>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Type</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.passport_type}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Passport number </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.passport_number}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Issuing Authority/Place of issue{" "}
                        </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.passport_issuing_authority}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Date of issue</Form.Label>
                      </Col>
                      <Col xs={8}>
                        {moment(mainData?.passport_issue_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Expiry date</Form.Label>
                      </Col>
                      <Col xs={8}>
                        {moment(mainData?.passport_expiry_date).format(
                          "DD/MM/YYYY"
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Form.Label className="fst-italic text-primary">
                      Do you hold any other valid passports?
                    </Form.Label>
                  </Col>
                </Row>
                {mainData?.other_passport_number?.length > 0 ? (
                  <>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={12} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Type</Form.Label>
                              </Col>
                              <Col xs={8}>{mainData?.other_passport_type}</Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Passport number </Form.Label>
                              </Col>
                              <Col xs={8}>
                                {mainData?.other_passport_number}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>
                                  Issuing Authority/Place of issue{" "}
                                </Form.Label>
                              </Col>
                              <Col xs={8}>
                                {mainData?.other_passport_issuing_authority}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Date of issue</Form.Label>
                              </Col>
                              <Col xs={8}>
                                {moment(
                                  mainData?.other_passport_issue_date
                                ).format("DD/MM/YYYY")}
                              </Col>
                            </Row>
                          </Col>
                          <Col md={12} className="mb-2">
                            <Row>
                              <Col xs={4}>
                                <Form.Label>Expiry date</Form.Label>
                              </Col>
                              <Col xs={8}>
                                {moment(
                                  mainData?.other_passport_expiry_date
                                ).format("DD/MM/YYYY")}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {otherPasswords?.[0]?.full_name?.length > 0 ? (
                  <>
                    <Row>
                      <Col md={12} className="mb-2">
                        <Form.Label className="fst-italic text-primary">
                          Have you ever used any other passports to enter into
                          VietNam?
                        </Form.Label>
                      </Col>
                      <Col md={12}>
                        <Table borderless bordered striped>
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Passport No</th>
                              <th>Full name</th>
                              <th>Date of birth</th>
                              <th>Nationality</th>
                            </tr>
                          </thead>
                          {otherPasswords?.map((item) => (
                            <>
                              <tbody key={item.tran_id}>
                                <tr>
                                  <td>1</td>
                                  <td>
                                    {item.passport_no ? item.passport_no : "--"}
                                  </td>
                                  <td>
                                    {item.full_name ? item.full_name : "--"}
                                  </td>
                                  <td>
                                    {item.dob
                                      ? moment(item.dob).format("DD/MM/YYYY")
                                      : "--"}
                                  </td>
                                  <td>
                                    {item.nationality ? item.nationality : "--"}
                                  </td>
                                </tr>
                              </tbody>
                            </>
                          ))}
                        </Table>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}

                <Row>
                  <Col md={12}>
                    <Form.Label className="fst-italic text-primary">
                      Do you have multiple nationalities?
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col md={12}>{mainData?.multiple_nationality_details}</Col>
                    </Row>
                  </Col>
                </Row>

                {violationData?.[0]?.act_of_violation?.length > 0 ? (
                  <>
                    <Row>
                      <Col md={12}>
                        <Form.Label className="fst-italic text-primary">
                          Violation of the Vietnamese laws/regulations (if any)
                        </Form.Label>
                        <Row>
                          <Col md={12} className="mt-3">
                            <Table borderless bordered striped>
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>Act of violation</th>
                                  <th>Time of violation</th>
                                  <th>Form of sanction</th>
                                  <th>Authority imposed sanction</th>
                                </tr>
                              </thead>

                              {violationData?.map((item) => (
                                <>
                                  <tbody key={item.tran_id}>
                                    <tr>
                                      <td>1</td>
                                      <td>
                                        {item.act_of_violation
                                          ? item.act_of_violation
                                          : "--"}
                                      </td>
                                      <td>
                                        {item?.date_of_violation
                                          ? moment(
                                              item?.date_of_violation
                                            ).format("DD/MM/YYYY")
                                          : "--"}
                                      </td>
                                      <td>
                                        {item.form_of_sanction
                                          ? item.form_of_sanction
                                          : "--"}
                                      </td>
                                      <td>
                                        {item.authority ? item.authority : "--"}
                                      </td>
                                    </tr>
                                  </tbody>
                                </>
                              ))}
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
                {/* </div>
              <div className="bg-white border p-3 rounded-1  pt-0 mb-3"> */}
                <Row>
                  <Col md={12}>
                    <h5>Occupation</h5>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Occupation</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.occupation}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Form.Label className="fst-italic text-primary">
                      Occupation Information
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Name of Company/Agency/School</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.company_name}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Position/Course of study</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.position}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Address</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.company_address}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Telephone number</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.company_telephone}</Col>
                    </Row>
                  </Col>
                </Row>
                {/* </div>
              <div className="bg-white border p-3 rounded-1  pt-0 mb-3"> */}
                <Row>
                  <Col md={12}>
                    <h5>Information about the trip</h5>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Purpose of visit </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.purpose_of_visit}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Form.Label className="fst-italic text-primary">
                      Agency/Organization/Individual that the applicant plans to
                      contact when enter into Vietnam
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Name of hosting organisation</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.hosting_organisation}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Address</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.organisation_address}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Telephone number</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.organisation_telephone}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Purpose</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.hosting_purpose}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Form.Label className="fst-italic text-primary">
                      Information about the trip
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Intended length of stay in Vietnam (number of days){" "}
                        </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.length_of_stay}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Intended date of entry</Form.Label>
                      </Col>
                      <Col xs={8}>
                        {moment(mainData?.date_of_entry).format("DD/MM/YYYY")}
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Allowed to entry through checkpoint
                        </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.entry_through}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Exit through checkpoint</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.exit_through}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Intended temporary residential address in Vietnam
                        </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.temporary_address}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>City/Province</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.temporary_city}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Phone number (in Vietnam)</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.temporary_phone}</Col>
                    </Row>
                  </Col>

                  {pruposeData?.[0]?.purpose?.length > 0 ? (
                    <>
                      <Row>
                        <Col md={12} className="mb-2">
                          <Form.Label className="fst-italic text-primary">
                            Have you been to Vietnam in the last 01 year?
                          </Form.Label>
                        </Col>
                        <Col md={12}>
                          <Table borderless bordered striped>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Purpose</th>
                              </tr>
                            </thead>

                            {pruposeData?.map((item) => (
                              <>
                                <tbody key={item.tran_id}>
                                  <tr>
                                    <td>1</td>
                                    <td>
                                      {moment(item?.from_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>
                                      {moment(mainData?.to_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.purpose}</td>
                                  </tr>
                                </tbody>
                              </>
                            ))}
                          </Table>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}

                  {relationship?.[0]?.full_name?.length > 0 ? (
                    <>
                      <Row>
                        <Col md={12} className="mb-2">
                          <Form.Label className="fst-italic text-primary">
                            Do you have relatives who currently reside in
                            Vietnam?
                          </Form.Label>
                        </Col>
                        <Col md={12}>
                          <Table borderless bordered striped>
                            <thead>
                              <tr>
                                <th>No</th>
                                <th>Full name</th>
                                <th>Date of Birth</th>
                                <th>Nationality</th>
                                <th>Relationship</th>
                                <th>Residential address</th>
                              </tr>
                            </thead>

                            {relationship?.map((item) => (
                              <>
                                <tbody key={item.tran_id}>
                                  <tr>
                                    <td>1</td>
                                    <td>{item.full_name}</td>
                                    <td>
                                      {moment(mainData?.date_of_birth).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </td>
                                    <td>{item.nationality}</td>
                                    <td>{item.relationship}</td>
                                    <td>{item.address}</td>
                                  </tr>
                                </tbody>
                              </>
                            ))}
                          </Table>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
                {under14?.[0]?.full_name?.length > 0 && (
                  <Row>
                    <Col md={12}>
                      <h5>
                        Under 14 years old accompanying child(ren) included in
                        your passport (IF ANY)
                      </h5>
                    </Col>
                    <Col md={12}>
                      <Table borderless bordered striped>
                        <thead>
                          <tr>
                            <th>
                              Full name (First name Middle name Last name)
                            </th>
                            <th>Sex</th>
                            <th>Date of birth (DD/MM/YYYY)</th>
                            <th>Portrait photography</th>
                          </tr>
                        </thead>

                        {under14?.map((item) => (
                          <>
                            <tbody key={item.tran_id}>
                              {item.full_name && (
                                <tr>
                                  <td>{item.full_name}</td>
                                  <td>{item.gender}</td>
                                  <td>
                                    {item.date_of_birth &&
                                      moment(item.date_of_birth).format(
                                        "DD/MM/YYYY"
                                      )}
                                  </td>
                                  <td>
                                    <div className="file-upload-container">
                                      <label
                                        htmlFor="child-upload"
                                        className="profile-upload-box-children-accompany  border rounded-2 d-flex align-items-center justify-content-center overflow-hidden position-relative"
                                      >
                                        {!under14[0].image ? (
                                          <img
                                            src={require("../assets/img/passport.jpg")}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            src={nodeUrl + item.image}
                                            style={{
                                              width: "100%",
                                              height: 200,
                                              objectFit: "contain",
                                            }}
                                            alt=""
                                          />
                                        )}
                                      </label>
                                      <Form.Control
                                        type="file"
                                        id="child-upload"
                                        name="child_upload"
                                        accept="image/*"
                                        disabled
                                        // onChange={uploadFilesChild}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </>
                        ))}
                      </Table>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={12}>
                    <h5>Trip's expense, insurance</h5>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Intended expenses (in USD)e</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.intended_expenses}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Who will cover the trip’s expenses of the applicant
                        </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.trip_expenses}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Type</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.trip_expenses_type}</Col>
                    </Row>
                  </Col>

                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Name of hosting organisation</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.trip_hosting_organisation}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Address</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.trip_hosting_address}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Telephone number</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.trip_hosting_telephone}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>
                          Does the applicant have health insurance arranged for
                          their stay in Viet Nam?
                        </Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.health_insurance}</Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mb-2">
                    <Row>
                      <Col xs={4}>
                        <Form.Label>Detail</Form.Label>
                      </Col>
                      <Col xs={8}>{mainData?.health_insurance_details}</Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mb-2 d-flex justify-content-center">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="i_assure"
                        checked={checked}
                        onChange={() => setchecked(!checked)}
                      />
                      <label
                        className="form-check-label fs-6"
                        htmlFor="i_assure"
                      >
                        I assure that I have truthfully declared all relevant
                        details.
                      </label>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              className="mb-2 d-flex gap-3 justify-content-center align-items-center"
              md={12}
            >
              <Button
                className="submit_btn bg-white border text-dark"
                type="submit"
                variant="none"
                onClick={handleEdit}
              >
                Edit
              </Button>
              {!showPayment && (
                <Button
                  // disabled={loader}
                  variant="success"
                  onClick={() => {
                    if (checked) {
                      setShowpayment(true);
                      // visaPriority();
                    } else {
                      alert("Plese check undertaking ");
                    }
                  }}
                  className="btn-input"
                >
                  Save & Pay
                </Button>
              )}
              {showPayment && (
                <div className="pay-now d-flex align-items-center justify-content-center mt-5">
                  <div className="form-group me-5">
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={makePayment}
                    >
                      {" "}
                      <img
                        src={razorPay}
                        style={{
                          width: 150,
                          height: 70,
                          objectFit: "cover",
                        }}
                        alt="pay_img"
                      />
                    </button>
                  </div>
                  <div className="form-group">
                    <PayPalGateway
                      payment={paymentParams}
                      callback={(data) => {
                        navigate("/visa/thank-you", {
                          state: { data, paymentParams },
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </MainLayout>
  );
}
