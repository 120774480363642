import React from "react";
import MainLayout from "../components/MainLayout";
import BannerLayout from "../components/BannerLayout";
import { Col, Container, Row } from "react-bootstrap";
import ScrollToTop from "../components/ScrollToTop";
import { Link } from "react-router-dom";

function Blog() {
	return (
		<MainLayout>
			<ScrollToTop />
			<BannerLayout>Blog</BannerLayout>
			<section>
				<Container>
					<Row>
						<Col md={8}>
							<div className="bg-light p-3 rounded-2 mb-3 border">
								<img
									src={require("../assets/img/guide.jpg")}
									alt=""
									className="w-100"
								/>
								<h4>
									Why a Hang Va Cave Tour Needs to Make Your Bucket List This
									Year
								</h4>
								<p>
									Nestled deep in the recesses of beautiful Phong Nha-ke Bang
									National Park in Vietnam, the Hang Va cave system promises
									intrepid travelers the adventure of a lifetime. The UNESCO
									World Heritage site of Phong Nha is already home to incredible
									trekking experiences, unforgettable vistas, friendly locals,
									and activities to suit every traveler in your group.
								</p>
								<Link to="/" className="button_global">
									Read more
								</Link>
							</div>
							<div className="bg-light p-3 rounded-2 mb-3 border">
								<img
									src={require("../assets/img/guide.jpg")}
									alt=""
									className="w-100"
								/>
								<h4>
									Why a Hang Va Cave Tour Needs to Make Your Bucket List This
									Year
								</h4>
								<p>
									Nestled deep in the recesses of beautiful Phong Nha-ke Bang
									National Park in Vietnam, the Hang Va cave system promises
									intrepid travelers the adventure of a lifetime. The UNESCO
									World Heritage site of Phong Nha is already home to incredible
									trekking experiences, unforgettable vistas, friendly locals,
									and activities to suit every traveler in your group.
								</p>
								<Link to="/" className="button_global">
									Read more
								</Link>
							</div>
						</Col>
						<Col md={4}>
							<aside className="bg-white rounded-2 text-center border p-4 shadow-sm">
								<h4
									className="p-3 mb-3 m-0 rounded-2 text-white"
									style={{ backgroundColor: "#f37735" }}
								>
									{" "}
									Recent Posts
								</h4>
								<ul className="list-none text-start p-0 d-flex flex-column gap-3">
									<li>
										<Link to="/">4 Must-See National Parks in Vietnam</Link>
									</li>
									<li>
										<Link to="/">Vietnam’s Top 5 Historical Landmarks</Link>
									</li>
									<li>
										<Link to="/">
											Travel Requirements to Vietnam: Tourist Visa vs. Business
											Travel Visa
										</Link>
									</li>
								</ul>
								<h4
									className="p-3 mb-3 m-0 rounded-2 text-white"
									style={{ backgroundColor: "#f37735" }}
								>
									{" "}
									Categories
								</h4>
								<ul className="list-none text-start p-0 d-flex flex-column gap-3">
									<li>
										<Link to="/">Trips to Vietnam</Link>
									</li>
									<li>
										<Link to="/">Uncategorized</Link>
									</li>
									<li>
										<Link to="/">Vietnam Foodie Series</Link>
									</li>
								</ul>
							</aside>
						</Col>
					</Row>
				</Container>
			</section>
		</MainLayout>
	);
}

export default Blog;
