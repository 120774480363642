import React from "react";
import { Col, Row } from "react-bootstrap";
import PagesLayout from "../components/PagesLayout";

function PrivacyPolicy() {
  return (
    <PagesLayout>
      <Row>
        <Col md={12}>
          <h2>Privacy Policy</h2>
          <p>
            Welcome to eVisa Vietnam. We value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, disclose, and safeguard your information when
            you visit our website vietnamvisa-vn.org, and interact with our
            services.
          </p>
          <h3>1. Information We Collect</h3>
          <p>
            We may collect and process the following types of information about
            you:
          </p>
          <ol>
            <li>
              Personal Identification Information: Name, date of birth, gender,
              nationality, passport number, and other identifying information.
            </li>
            <li>
              Contact Information: Email address, phone number, and mailing
              address.
            </li>
            <li>
              Travel Information: Travel itinerary, visa application details,
              and travel history.
            </li>
            <li>
              Technical Data: IP address, browser type, operating system, and
              browsing behavior on our site.
            </li>
          </ol>
          <h2>2. How We Use Your Information</h2>
          <p>
            We use the information we collect for various purposes, including:
          </p>
          <ol>
            <li>To process and manage your visa application.</li>
            <li>
              To communicate with you regarding your application and provide
              customer support.
            </li>
            <li>To improve our website and services.</li>
            <li>
              To comply with legal requirements and protect our legal rights.
            </li>
          </ol>
          <h2>3. Sharing Your Information</h2>
          <p>We may share your information with:</p>
          <ol>
            <li>
              Government Authorities: To process your visa application and
              comply with legal obligations.
            </li>
            <li>
              Service Providers: Third-party service providers that assist us in
              operating our website and services, provided they agree to keep
              your information confidential.
            </li>
            <li>
              Legal Requirements: If required by law or to protect our rights,
              property, or safety.
            </li>
          </ol>
          <h2>4. Data Security</h2>
          <p>
            We implement appropriate technical and organizational measures to
            safeguard your personal information from unauthorized access, use,
            or disclosure. However, no method of transmission over the internet
            or electronic storage is completely secure, and we cannot guarantee
            absolute security.
          </p>
          <h2>5. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to enhance your
            browsing experience, analyze site traffic, and understand user
            behavior. You can manage your cookie preferences through your
            browser settings.
          </p>
          <h2>6. Your Rights</h2>
          <p>
            Depending on your jurisdiction, you may have the following rights
            regarding your personal information:
          </p>
          <ol>
            <li>The right to access and obtain a copy of your information.</li>
            <li>
              The right to rectify any inaccurate or incomplete information.
            </li>
            <li>The right to request the deletion of your information.</li>
            <li>
              The right to restrict or object to the processing of your
              information.
            </li>
            <li>The right to data portability.</li>
            <li>
              To exercise these rights, please contact us at info@vietnamvisa-vn.org.
            </li>
          </ol>
          <h2>7. Third-Party Links</h2>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or content of these websites.
            We encourage you to read their privacy policies.
          </p>
          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated effective date. We
            encourage you to review this Privacy Policy periodically.
          </p>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at: eVisa Vietnam:-
            info@vietnamvisa-vn.org
          </p>
        </Col>
      </Row>
    </PagesLayout>
  );
}

export default PrivacyPolicy;
